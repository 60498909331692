<script>
import YearToDate from '@/bursts/components/YearToDate';
import { mapGetters } from 'vuex';

export default {
  components: { YearToDate },
  data: () => ({ headline: {}, accounts2: [] }),

  computed: {
    ...mapGetters('user', ['isCreate']),
    ...mapGetters('account', ['hasBursts']),
  },
};
</script>

<template>
  <div>
    <YearToDate />
  </div>
</template>

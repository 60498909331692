<script>
import YearToDate from '@/bursts/components/YearToDate';
import { mapGetters } from 'vuex';

export default {
  components: { YearToDate },
  computed: mapGetters('account', ['accountId']),
};
</script>

<template>
  <YearToDate :accountId="accountId" />
</template>

<script>
import $export from '@/scripts/export';
import '@/assets/svgs/icons/export.svg';

export default {
  props: { path: String, params: Object, label: String },
  data: () => ({ ready: true }),

  methods: {
    exportData() {
      if (this.ready) {
        this.ready = false;
        $export(`${this.path}/export`, this.params)
          .catch()
          .then(() => {
            this.ready = true;
          });
      }
    },
  },
};
</script>

<template>
  <button class="btn4" :class="{ busy: !ready }" @click="exportData">
    Export {{ label }}
    <svg v-if="ready" class="btn4-icon"><use xlink:href="#export" /></svg>
    <div class="btn4-icon" v-else>
      <div class="mini-spinner spin08 w-4 h-4"></div>
    </div>
  </button>
</template>

<script>
import EquipmentList from '@/content/components/partials/EquipmentList';
import HaloButton from '@/content/components/buttons/HaloButton';
import Modal from '@/content/components/partials/Modal';
import '@/assets/svgs/buttons/organisation.svg';
import '@/assets/svgs/buttons/equipment.svg';

export default {
  components: { Modal, HaloButton, EquipmentList },
  props: ['entry'],
  data: () => ({ modal: false }),
};
</script>

<template>
  <div>
    <Modal :open="modal" icon="organisation" wide @close="modal = false">
      <div class="flex justify-center xl:justify-end">
        <div class="w-5/6 xl:w-3/4">
          <div class="mb-3 text-5xl font-black">Equipment</div>
          <div class="relative mb-6">
            <EquipmentList :list="entry.equipment" class="rdct rdct-modal" />
            <img
              src="/images/jasmine-equipment.svg"
              class="absolute top-0 hidden jas xl:block right-full"
              style="width: 500px"
            />
          </div>

          <template v-if="entry.organisation">
            <div class="mb-3 text-5xl font-black">Organisation</div>
            <div v-html="entry.organisation" class="rdct rdct-modal"></div>
          </template>
        </div>
      </div>
    </Modal>

    <HaloButton icon="organisation" label="Organisation" @click="modal = true" />
  </div>
</template>

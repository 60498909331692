<script>
import Foot from '@/global/components/Foot';

export default {
  components: { Foot },
  metaInfo: { title: 'Login' },
  data: () => ({ pfw: process.env.VUE_APP_PFW, bursts: process.env.VUE_APP_BURSTS }),

  created() {
    const query = this.$route.query;

    if (query.invite) {
      this.$router.push(`/login/invite/${query.invite}`);
    } else if (query.reset) {
      this.$router.push(`/login/reset/${query.reset}`);
    }
  },
};
</script>

<template>
  <div>
    <div class="bg-white text-sm font-semibold overflow-hidden">
      <div class="flex flex-wrap py-8 justify-center -mx-8 text-center">
        <div class="max-w-sm px-8 flex flex-col items-center">
          <a :href="pfw" target="_blank" class="relative my-3">
            <img class="h-24" src="/brands/pe.svg" />
            <span class="absolute top-0 -right-4 text-pe text-3xl leading-none">®</span>
          </a>
          <p>Supporting primary schools and Early Years settings to make a proven impact for EVERY child</p>
        </div>
        <div class="max-w-sm px-8 flex flex-col items-center">
          <a :href="bursts" target="_blank" class="my-3"><img class="h-24" src="/brands/bursts.svg" /></a>
          <p>Your essential PE companion. Supporting children’s readiness for and progress in PE</p>
        </div>
      </div>
    </div>
    <div class="items-center flex justify-center">
      <div class="bg-white p-6 curves-sm my-10 mx-4 shadow" style="width: 430px">
        <transition name="fade" mode="out-in">
          <RouterView />
        </transition>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="max-w-md bg-white curves-sm shadow p-6 py-5 mx-4">
        <div class="flex flex-wrap -mx-3 -my-2 items-center justify-center">
          <div class="py-2 mx-3">Not a real PE member?</div>
          <a :href="`${pfw}samples`" target="_blank" class="btn4 mx-3 my-2">Request FREE Trial</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['total', 'limit', 'page'],

  computed: {
    first() {
      return this.page === 1;
    },
    last() {
      return this.page === this.totalPages;
    },
    totalPages() {
      return Math.ceil(this.total / this.limit) || 1;
    },
  },

  methods: {
    change(link) {
      this.$emit('change', link);
    },
  },
};
</script>

<template>
  <div class="flex items-center justify-between btns4">
    <div>
      <button :disabled="first" class="btn4" @click="change(1)">First</button>
      <button :disabled="first" class="btn4 bg-theme" @click="change(page - 1)">Prev</button>
    </div>
    <div>{{ page }} / {{ totalPages }}</div>
    <div>
      <button :disabled="last" class="btn4 bg-theme" @click="change(page + 1)">Next</button>
      <button :disabled="last" class="btn4" @click="change(totalPages)">Last</button>
    </div>
  </div>
</template>

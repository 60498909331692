<script>
import TrialModal from '@/global/components/TrialModal';
import Link2 from '@/global/components/Link2';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: { TrialModal, Link2 },

  computed: {
    ...mapGetters('user', ['isAdmin']),
    ...mapGetters('account', ['trialExpRealPe', 'trialDaysRealPe']),
    ...mapGetters(['hasRealPeTrial', 'hasRealPeFull']),
  },

  methods: {
    ...mapMutations(['setTrialModal']),
  },
};
</script>

<template>
  <div v-if="!hasRealPeFull && trialExpRealPe">
    <slot />
    <template v-if="hasRealPeTrial">
      <strong>{{ trialDaysRealPe }} days remaining </strong> - {{ trialExpRealPe }}
    </template>
    <div v-else class="font-bold">Your trial has ended</div>
    <div v-if="isAdmin && hasRealPeTrial" class="flex flex-wrap -m-1 mt-4">
      <button class="db1" @click="setTrialModal(1)">Purchase</button>
      <button class="db1" @click="setTrialModal(2)">End trial</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AudioPlayer from '@/content/components/partials/AudioPlayer';
import '@/assets/svgs/icons/chevron_vertical.svg';

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
let offsetX = 0;
let offsetY = 0;

export default {
  props: { playlist: { type: Array, default: () => [] } },
  components: { AudioPlayer },
  data: () => ({ dragging: false, expanded: false, autoplay: false, selected: 0, position: { x: 32, y: 140 } }),

  computed: {
    tracks() {
      return this.playlist || [];
    },
    current() {
      return this.tracks.find(track => track.id === this.selected) ?? this.tracks[0];
    },
    stylePos() {
      return { left: this.position.x + 'px', top: this.position.y + 'px' };
    },
    longest() {
      return this.tracks.reduce((carry, { title }) => (title.length > carry.length ? title : carry), '');
    },
    height() {
      return `${this.expanded ? (48 + 4) * this.tracks.length + 4 : 56}px`;
    },
    ...mapState('user', { visible: 'showJukebox' }),
  },

  methods: {
    select(track) {
      this.autoplay = true;
      this.toggle();
      this.selected = track.id;
    },

    mouseDown(event) {
      offsetX = event.clientX - this.$el.offsetLeft;
      offsetY = event.clientY - this.$el.offsetTop;
      this.dragging = true;
    },

    mouseMoved(event) {
      if (!this.dragging) return;
      event.preventDefault();
      this.setPos({ x: event.clientX, y: event.clientY });
    },

    mouseUp(event) {
      if (!this.dragging) return;
      event.preventDefault();
      event.stopPropagation();
      localStorage.setItem('musicPlayerPosition', JSON.stringify(this.position));
      this.dragging = false;
    },

    setPos(pos, force = false) {
      const posX = force ? pos.x : pos.x - offsetX;
      const posY = force ? pos.y : pos.y - offsetY;
      this.position.x = clamp(posX, 10, window.innerWidth - this.$el.offsetWidth - 10);
      this.position.y = clamp(posY, 130, window.innerHeight - this.$el.offsetHeight - 10);
    },

    toggle() {
      this.expanded = !this.expanded;
      setTimeout(() => {
        this.setPos(this.position, true);
      }, 310);
    },
  },

  mounted() {
    const storedPos = localStorage.getItem('musicPlayerPosition');
    if (storedPos) this.setPos(JSON.parse(storedPos));
    window.addEventListener('mousemove', this.mouseMoved);
  },
};
</script>

<template>
  <aside
    :style="stylePos"
    :class="visible ? '' : 'opacity-0 pointer-events-none'"
    class="draggable-player fixed z-10 flex items-start h-auto cursor-move"
    @mousedown="mouseDown"
    @mouseup="mouseUp"
  >
    <div class="bg-white rounded-full p-1 relative shrink-0 z-10">
      <AudioPlayer :audio="current" :autoplay="autoplay" progress loop />
    </div>
    <div :style="{ height }" class="tracklist mt-4 -ml-5 flex bg-white overflow-hidden rounded-2xl">
      <div :class="{ expanded }" class="pl-0 shrink-0 text-lg ml-1 mr-1">
        <button
          v-for="track in tracks"
          :key="track.id"
          :class="{ active: current.id === track.id }"
          class="track hidden border-clear h-12 my-1 pl-6 pr-3 items-center rounded-xl w-full border-2 relative"
          @click="select(track)"
        >
          <div class="absolute">{{ track.title }}</div>
          <span class="opacity-0">{{ longest }}</span>
        </button>
      </div>
      <template v-if="tracks.length > 1">
        <button class="w-10 h-12 p-1 m-1 ml-0 rounded-xl hover:bg-fun-pink-shade" @click="toggle">
          <svg class="flip3d w-8 h-8"><use xlink:href="#chevron_vertical" /></svg>
        </button>
      </template>
      <!-- <button
        class="w-8 h-12 p-4 px-2 m-1 ml-0 rounded-xl hover:bg-fun-pink-shade"
        @click="$store.commit('user/toggleJukebox')"
      >
        <svg class="w-4 h-4 fill-blank"><use xlink:href="#cross" /></svg>
      </button> -->
    </div>
  </aside>
</template>

<style lang="postcss">
.draggable-player {
  filter: drop-shadow(0px 3px 3px rgba(51, 51, 51, 0.5));
}

.tracklist {
  transition: height 0.3s ease;
}

.track {
  min-width: 200px;

  &:focus {
    @apply outline-none bg-fun-pink-shade;
  }

  &:hover,
  .expanded &.active {
    @apply bg-track;
  }

  .expanded &:hover {
    @apply bg-fun-pink-shade;
  }

  &.active,
  .expanded & {
    @apply flex;
  }
}

.flip3d {
  transform: rotate3d(1, 1, 1, 0deg);
  transition: transform 0.3s ease-out;

  .expanded & {
    transform: rotate3d(1, 0, 0, 180deg);
  }
}
</style>

const themes = [
  { title: 'Pirate', slug: 'pirate' },
  { title: 'Jungle', slug: 'jungle' },
  { title: 'Cat', slug: 'cat' },
  { title: 'Tightrope', slug: 'tightrope' },
  { title: 'Train', slug: 'train' },
  { title: 'Space', slug: 'space' },
  { title: 'Seaside', slug: 'seaside' },
  { title: 'Juggling', slug: 'juggling' },
  { title: 'Clown', slug: 'clown' },
  { title: 'Bike', slug: 'bike' },
  { title: 'Squirrel', slug: 'squirrel' },
  { title: 'Fairytale', slug: 'fairytale' },
];

const funs = [
  { title: 'One Leg Balance', slug: 'one-leg' },
  { title: 'Seated Balance', slug: 'seated' },
  { title: 'Floor Work', slug: 'floor-work' },
  { title: 'Stance', slug: 'stance' },
  { title: 'Dynamic Balance', slug: 'dynamic-balance' },
  { title: 'Jumping and Landing', slug: 'jumping-and-landing' },
  { title: 'Counter Balance', slug: 'with-a-partner' },
  { title: 'Sending and Receiving', slug: 'sending-and-receiving' },
  { title: 'Ball Skills', slug: 'ball-skills' },
  { title: 'Footwork', slug: 'footwork' },
  { title: 'Ball Chasing', slug: 'ball-chasing' },
  { title: 'Reaction / Response', slug: 'reaction-response' },
];

// const wrap = (label, slug) => ({ title: `${label} ${slug}`, slug });
const repeat = (label, slugs) => slugs.map(slug => ({ title: `${label} ${slug}`, slug }));

const lesson = repeat('Lesson', [1, 2, 3, 4, 5, 6]);
const year = [{ title: 'Foundation', slug: 0 }, ...repeat('Year', [1, 2, 3, 4]), { title: 'Year 5/6', slug: 5 }];

const config = {
  pe: {
    slug: 'pe',
    colour: 'pe',
    title: 'core real PE',
    lesson,
    unit: repeat('Unit', [1, 2, 3, 4, 5, 6]),
    year: [{ title: 'Foundation', slug: 0 }, ...repeat('Year', [1, 2, 3, 4, 5, 6])],
    yearLabels: { 0: 'Foundation' },
    unitSplit: 2,
    ability: ['personal', 'social', 'cognitive', 'creative', 'physical', 'fitness'],
    tree: ['year', 'unit', 'lesson'],
    floatingMusicPlayer: true,
    partGql: true,
    intro: 'The foundation of your PE curriculum, providing a full year of interactive lessons.',
    // video: '876040533?h=8edf1496ed',
  },

  gym: {
    abilitySelect: true,
    slug: 'gym',
    colour: 'gym',
    title: 'real gym',
    lesson,
    unit: repeat('Unit', [1, 2]),
    year,
    yearLabels: { 0: 'Foundation', 5: 'Year 5/6' },
    unitSplit: 2,
    tree: ['year', 'unit', 'lesson'],
    floatingMusicPlayer: true,
    partGql: true,
    intro: 'Two terms of interactive gymnastics lessons, incorporating floor work, apparatus and sequence development.',
  },

  dance: {
    abilitySelect: true,
    floatingMusicPlayer: true,
    slug: 'dance',
    colour: 'dance',
    title: 'real dance',
    lesson,
    unit: repeat('Unit', [1]),
    year,
    yearLabels: { 0: 'Foundation', 5: 'Year 5/6' },
    unitSplit: 6,
    index: true,
    partGql: true,
    lessonGql: true,
    tree: ['year', 'unit', 'lesson'],
    intro: 'A term of interactive dance lessons, putting children at the heart of their own dance development.',
  },

  foundations: {
    slug: 'foundations',
    colour: 'foundations',
    title: 'real PE foundations',
    stage: [{ title: 'Themes', slug: 'themes' }],
    tree: ['stage', 'item'],
    themes,
    intro: 'Interactive EYFS programme that uses physical activity as the driver for learning.',
  },

  play: {
    slug: 'play',
    colour: 'play',
    title: 'real play',
    lesson: repeat('Session', [1, 2, 3, 4, 5, 6]),
    unit: repeat('Unit', [1, 2]),
    stage: [{ title: 'Club', slug: 'club' }],
    stageLabels: { club: 'Club' },
    unitSplit: 3,
    partGql: true,
    tree: ['stage', 'unit', 'lesson'],
    ability: ['personal', 'social'],
    intro:
      'Interactive nurture programme to build positive relationships with physical activity for children and families.',
  },

  leaders: {
    slug: 'leaders',
    colour: 'leaders',
    title: 'real leaders',
    intro: 'Key Stage 2 leadership programme, teaching essential leadership skills using physical activity.',
  },

  home: {
    slug: 'home',
    colour: 'pe',
    title: 'real PE daily',
    title2: 'real PE at home',
    stage: [
      { title: 'EYFS/KS1', slug: 'themes' },
      { title: 'KS2', slug: 'funs' },
    ],
    stageLabels: { themes: 'EYFS/KS1', funs: 'KS2' },
    index: true,
    tree: ['stage', 'item'],
    themes,
    funs,
    intro: 'Bitesize physical activities to use throughout the school day, linking directly to core real PE skills.',
  },

  bursts: {
    slug: 'bursts',
    colour: 'bursts',
    intro: "Home learning app for 4-7 year olds to support children's readiness for and progress in PE.",
  },

  coaching: {
    slug: 'coaching',
    colour: 'coaching',
    title: 'real coaching',
  },
  legacy: {
    slug: 'legacy',
    colour: 'pe',
    title: 'real legacy',
  },

  core: {
    slug: 'core',
    colour: 'pe',
  },
};

export default slug => {
  return typeof slug !== 'undefined' ? config[slug] : config;
};

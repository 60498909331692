<script>
import Dots from '@/admin/components/Dots';
import '@/assets/svgs/icons/renew.svg';

const yearLabels = ['Fs', '1', '2', '3', '4', '5', '6'];

export default {
  components: { Dots },
  props: ['licence', 'admin'],

  computed: {
    chip() {
      return `text-${this.licence.current ? this.productColour : `${this.productColour}-shade`}`;
    },
    titleText() {
      return `text-${this.productColour}`;
    },
    products() {
      return [...this.licence.licenceType.products, ...this.licence.products];
    },
    bursts() {
      return this.licence.licenceType.hasBursts;
    },
    years() {
      return this.licence.licenceType.customYears ? this.licence.years : this.licence.licenceType.years;
    },
    dateString() {
      const start = this.$djMed(this.licence.startDate);
      const end = this.$djMed(this.licence.endDate);
      return `${start} - ${end}`;
    },

    yearsString() {
      if (this.years.length === yearLabels.length) return 'All';
      return this.years.map(slug => yearLabels[slug]).join(', ');
    },
    productColour() {
      return this.licence.licenceType.title === 'BURSTS' ? 'bursts' : 'pe';
    },
  },
};
</script>

<template>
  <component :is="admin ? 'button' : 'div'" :class="chip" class="card outlne" @click="$emit('click', licence)">
    <div class="flex text-black">
      <span :class="titleText" class="font-bold">{{ licence.licenceType.title }}</span>
      <span v-if="licence.licenceType.subtitle" class="ml-2">{{ licence.licenceType.subtitle }}</span>

      <div :class="titleText" class="ml-auto card-subtitle">
        <span title="Renew">
          <svg v-if="licence.renew" class="inline"><use xlink:href="#renew" /></svg>
        </span>
      </div>
    </div>

    <div :class="{ italic: !licence.current }" class="text-sm leading-relaxed text-blank">
      {{ dateString }}
    </div>

    <div class="mt-1 text-sm leading-none">
      <div class="flex items-center justify-between text-black">
        <div>{{ yearsString }}</div>
        <Dots :access="products" :bursts="bursts" class="mr-0" />
      </div>
    </div>
  </component>
</template>

<script>
import HaloButton from '@/content/components/buttons/HaloButton';
import '@/assets/svgs/buttons/video.svg';

export default {
  components: { HaloButton },
  props: { colour: {} },
};
</script>

<template>
  <HaloButton :colour="colour" icon="video" label="Video" @click="$emit('click')" />
</template>

<script>
import Link from '@/global/components/Link';
import routeInfo from '@/mixins/routeInfo';
import { mapGetters } from 'vuex';

export default {
  components: { Link },
  mixins: [routeInfo],

  computed: {
    ability() {
      const unitCrumb = this.crumbs.find(crumb => crumb.type === 'unit') || {};
      return this.chosenAbilitySlug(unitCrumb.cmsSlug);
    },

    ...mapGetters('menu', ['crumbs']),
    ...mapGetters('user', ['chosenAbilitySlug']),
  },
};
</script>

<template>
  <div class="flex items-center select-none" :class="productSlug">
    <div v-for="crumb in crumbs" :key="crumb.title" class="flex items-center">
      <div class="mx-1 text-grey">/</div>
      <Link
        :uri="crumb.uri"
        :link-class="`hover:bg-${productSlug}-shade hover:text-${productSlug}`"
        :class="{ capitalize: crumb.type !== 'product' }"
        class="nm2 flex items-center"
      >
        <div
          v-if="crumb.type === 'unit'"
          :class="`bg-${crumb.ability || ability || 'blank'}`"
          class="w-3 h-3 rounded-full colour-3s border-white border box-content mr-2 mb-0.5"
        ></div>
        {{ crumb.title }}
      </Link>
    </div>
  </div>
</template>

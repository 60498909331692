<script>
export default {
  props: ['url', 'element', 'type', 'text', 'target'],
};
</script>

<template>
  <RouterLink v-if="element?.uri" :to="element.uri">
    <slot>{{ text }}</slot>
  </RouterLink>
  <a v-else-if="url" :href="url" :target="target">
    <slot>{{ text }}</slot>
  </a>
</template>

<script>
import ExportButton from '@/admin/components/ExportButton';

export default {
  components: { ExportButton },
  props: ['users'],

  computed: {
    params() {
      return { id: this.users.filter(user => user.id).map(user => user.id) };
    },
  },
};
</script>

<template>
  <div v-if="users.length" class="bg-white rounded-lg p-5 my-6">
    <h3 class="font-semibold text-pe mb-2 text-xl -mt-1">Imported Children</h3>

    <table class="-mx-2">
      <tr>
        <th>Name</th>
        <th>Username</th>
        <th>Group</th>
        <th>Password</th>
      </tr>
      <tr v-for="user in users" :key="user.id" :class="Object.keys(user.errors).length ? 'error' : 'success'">
        <td>{{ user.firstName }} {{ user.lastName }}</td>
        <td>{{ user.username }}</td>
        <td>{{ user.group }}</td>
        <td>{{ user.password }}</td>
        <td colspan="4">
          <div v-for="(errors, key) in user.errors" :key="key">
            <div v-for="error in errors" :key="error">{{ error }}</div>
          </div>
        </td>
      </tr>
    </table>

    <div class="btns4">
      <button class="btn4-clear" @click="$emit('close')">Close</button>
      <ExportButton label="New Children" path="users/home/new" :params="params" primary />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.error {
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.5);
}
</style>

import Dashboard from '@/global/views/Dashboard';
import Accounts from '@/admin/views/Accounts';
import UsersCreate from '@/admin/views/UsersCreate';
import Account from '@/admin/account/Account';
import Membership from '@/admin/account/Membership';
import Training from '@/admin/account/Training';
import UsersAccount from '@/admin/account/UsersAccount';
import UsersHome from '@/admin/account/UsersHome';
import UserSearch from '@/admin/views/UserSearch';
import ProductIntro from '@/admin/views/ProductIntro';
import UserProfile from '@/admin/views/UserProfile';
import Support from '@/admin/views/Support';
import Groups from '@/bursts/views/Groups';
import Bursts from '@/bursts/views/Bursts';
import BurstsDash from '@/bursts/views/Dash';
import DashYear from '@/bursts/views/DashYear';
import DashAccounts from '@/bursts/views/DashAccounts';
import YearStats from '@/bursts/views/YearStats';
import Student from '@/bursts/views/Student';
import Group from '@/bursts/views/Group';
import Learning from '@/global/views/Learning';
import MicroLearns from '@/global/views/MicroLearns';
import Programmes from '@/global/views/Programmes';
import Real from '@/global/views/Real';

export default [
  // shared
  { path: '', component: Dashboard },
  { path: '/profile', component: UserProfile },
  { path: '/user-search', component: UserSearch },
  { path: '/create', component: UsersCreate },
  { path: '/support/:slug1?/:slug2?', component: Support },

  { path: '/accounts', component: Accounts },
  {
    path: '/:plural(accounts|account)/:accountId(\\d+)?',
    component: Account,
    children: [
      { path: '', component: Membership },
      { path: 'users', component: UsersAccount },
      { path: 'home', component: UsersHome },
      { path: 'training', component: Training },

      { path: 'bursts', redirect: 'bursts/groups' },
      { path: 'bursts/groups', component: Groups, meta: { theme: 'bursts' } },
      { path: 'bursts/groups/:groupId', component: Group, meta: { theme: 'bursts' } },
      { path: 'bursts/groups/:groupId/students/:studentId', component: Student, meta: { theme: 'bursts' } },
      { path: 'bursts/year', component: YearStats, meta: { theme: 'bursts' } },
    ],
  },

  {
    path: '/bursts/stats',
    component: BurstsDash,
    meta: { theme: 'bursts' },
    children: [
      { path: '/', redirect: 'year' },
      { path: 'year', component: DashYear, meta: { theme: 'bursts' } },
      { path: 'accounts', component: DashAccounts, meta: { theme: 'bursts' } },
    ],
  },

  {
    path: '/bursts',
    component: Bursts,
    meta: { theme: 'bursts' },
    children: [
      { path: 'groups', component: Groups, meta: { theme: 'bursts' } },
      { path: 'groups/:groupId', component: Group, meta: { theme: 'bursts' } },
      { path: 'groups/:groupId/students/:studentId', component: Student, meta: { theme: 'bursts' } },
      { path: 'year', component: YearStats, meta: { theme: 'bursts' } },
    ],
  },

  {
    path: '/real',
    component: Real,
    children: [{ path: '/', component: Programmes }],
  },

  { path: '/learning', component: Learning },
  { path: '/learning/micro', component: MicroLearns },
];

<script>
import '@/assets/svgs/icons/bolt.svg';

export default {
  props: ['level'],
};
</script>

<template>
  <div class="flex fill-blank-shade">
    <svg :class="{ 'fill-bolt': level > 0 }" class="w-3 h-4"><use xlink:href="#bolt" /></svg>
    <svg :class="{ 'fill-bolt': level > 1 }" class="w-3 h-4"><use xlink:href="#bolt" /></svg>
    <svg :class="{ 'fill-bolt': level > 2 }" class="w-3 h-4"><use xlink:href="#bolt" /></svg>
  </div>
</template>

<script>
import '@/assets/svgs/buttons/dice-roll.svg';

const rand = sides => Math.floor(Math.random() * sides);

export default {
  props: ['die'],
  data: () => ({ rolling: false, rolled: false, current: 0 }),

  computed: {
    side() {
      if (!this.rolled && this.die.previewImage && this.die.previewImage.length) {
        return { image: this.die.previewImage };
      }
      return this.die.sides[this.current] || { image: {} };
    },
  },

  methods: {
    roll() {
      this.rolling = true;

      setTimeout(() => {
        this.rolling = false;
        this.rolled = true;
        this.current = rand(this.die.sides.length);
        this.$emit('roll', this.side);
      }, 750);
    },
  },

  created() {
    this.current = rand(this.die.sides.length);
    // this.roll();
  },
};
</script>

<template>
  <div @click="roll">
    <div class="dice-ratio">
      <div class="dice-inner">
        <transition name="dice-fade">
          <div v-if="!rolling" :style="{ backgroundColor: die.colour }" class="dice-cover">
            <img v-for="img in side.image" :key="img.id" :src="img.url" alt="" draggable="false" />
          </div>
        </transition>

        <svg class="dice-roll" :fill="die.colour"><use xlink:href="#dice-roll" /></svg>
      </div>
    </div>

    <div class="dice-title">{{ (rolled ? side.sideTitle : false) || `${die.title} Dice` }}</div>
  </div>
</template>

<style lang="postcss" scoped>
.dice-ratio {
  width: 100%;
  padding-top: 100%;
  position: relative;
  cursor: pointer;
}

.dice-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;
}

.dice-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  border-radius: 25px;
  transition: opacity 0.3s ease-out, transform 0.2s ease-out;
  transform: translateZ(0) scale(1);

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.dice-roll {
  width: 100%;
  height: 100%;
}

.dice-title {
  font-size: 26px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
  cursor: pointer;
}

.dice-fade-enter,
.dice-fade-leave-to {
  opacity: 0;
  transform: scale(0.75);
}
</style>

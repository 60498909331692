/* eslint-disable no-param-reassign */
const custom = { 422: 'Validation failed' };

const normalise = ({ toasts }, input, level = '') => {
  const res = input.response ? input.response : input;
  const data = res.data ? res.data : res;

  return {
    id: toasts.length ? Math.max(...toasts.map(t => t.id)) + 1 : 1,
    message: data.message || custom[res.status] || input,
    level: data.level || level,
    status: data.status || res.status || '',
    list: Array.isArray(data) ? data : [],
  };
};

export default {
  namespaced: true,

  state: {
    toasts: [],
  },

  mutations: {
    setToast(state, input) {
      state.toasts.push(normalise(state, input));
    },

    info(state, input) {
      state.toasts.push(normalise(state, input, 'info'));
    },

    warn(state, input) {
      state.toasts.push(normalise(state, input, 'warn'));
    },

    error(state, input) {
      state.toasts.push(normalise(state, input, 'error'));
    },

    binToast(state, id) {
      const index = state.toasts.findIndex(t => t.id === id);
      if (index !== -1) state.toasts.splice(index, 1);
    },
  },

  getters: {},
  actions: {},
};

<script>
import LicenceCard from '@/admin/components/licences/LicenceCard';

export default {
  components: { LicenceCard },
  props: { licences: Array, isCdAdmin: Boolean, title: String },
};
</script>

<template>
  <div v-if="licences.length" class="mb-3">
    <h3 class="head-2xl mb-2">{{ title }}</h3>

    <div class="flex flex-wrap -mx-3 relative">
      <div v-for="licence in licences" :key="licence.id" class="w-1/3 px-3 my-2 lg:w-1/4">
        <LicenceCard :licence="licence" :admin="isCdAdmin" @click="$emit('edit', licence)" />
      </div>
    </div>
  </div>
</template>

<script>
import HomeDisplay from '@/admin/components/users/HomeDisplay';
import HomeImport from '@/admin/components/users/HomeImport';
import ExportButton from '@/admin/components/ExportButton';
import UserList from '@/admin/components/users/UserList';
import HomeAdd from '@/admin/components/users/HomeAdd';
import AdminModal from '@/admin/components/AdminModal';
import MetaTitle from '@/admin/components/MetaTitle';
import { mapState, mapGetters } from 'vuex';

export default {
  components: { UserList, AdminModal, HomeImport, HomeDisplay, HomeAdd, ExportButton, MetaTitle },
  data: () => ({ showAdd: false, showAdd2: false, newHome: [] }),
  metaInfo: { title: 'Home Learning' },

  computed: {
    params() {
      return { accountId: this.accountId };
    },

    stats() {
      return [
        { label: 'Total Logins', value: this.account.totalHomeLogins },
        { label: 'Latest Login', value: this.account.lastHomeLogin },
      ];
    },

    ...mapState(['account']),
    ...mapGetters('account', ['accountId']),
    ...mapGetters('user', ['isAdmin']),
  },

  methods: {
    fetchPending() {
      this.$refs.pending.fetch();
    },
    addHome(users) {
      this.newHome = users;
      this.fetchPending();
    },
  },
};
</script>

<template>
  <div class="container">
    <MetaTitle title="Home Learning" :stats="stats" :btns="isAdmin">
      <ExportButton label="Children" path="users/home" :params="{ accountId: this.accountId }" />
      <button class="btn4" @click="showAdd2 = true">
        Add children
        <svg class="btn4-icon"><use xlink:href="#ring-add" /></svg>
      </button>
      <button class="btn4 bg-pe" @click="showAdd = true">
        Import children
        <svg class="btn4-icon"><use xlink:href="#ring-add" /></svg>
      </button>
    </MetaTitle>

    <HomeDisplay :users="newHome" @close="newHome = []" />
    <HomeAdd :active="showAdd2" @update="addHome" @close="showAdd2 = false" />

    <UserList kind="home" :params="params" filter="active" title="Active" class="my-8" />
    <UserList kind="home" :params="params" filter="pending" title="Inactive" class="my-8" ref="pending" hide />

    <AdminModal :active="showAdd" title="Import children" @close="showAdd = false">
      <HomeImport @update="addHome" @close="showAdd = false" />
    </AdminModal>
  </div>
</template>

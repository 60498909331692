<script>
import '@/assets/svgs/icons/ring-close.svg';

export default {
  props: { active: Boolean, title: String, inline: Boolean, force: Boolean, wide: Boolean },

  metaInfo() {
    return { ...(this.active && { bodyAttrs: { class: 'no-scroll' } }) };
  },
};
</script>

<template>
  <transition name="fade">
    <div
      v-if="active"
      :class="inline ? 'absolute inset-0' : 'fixed z-30 overflow-auto inset-x-0 bottom-0 shade-top'"
      class="amodal-shade rounded-br-xl"
    >
      <!-- dont put mousedown on scrolling div or event will tigger on scrollbar click -->
      <div
        :class="inline ? 'px-8 flex flex-col justify-center h-full' : 'py-32 min-h-full'"
        @mousedown.self="$emit('close')"
      >
        <div
          :class="[inline ? 'w-full' : '', wide ? 'max-w-4xl' : 'max-w-xl ']"
          class="mx-auto form amodal bg-white curves-sm"
        >
          <div class="flex items-center justify-between px-6 py-3 bg-grey-l3 rounded-tl-xl">
            <div class="text-theme text-3xl font-bold">{{ title }}</div>
            <button type="button" class="p-2 -mr-3 cursor-pointer" @click="$emit('close')">
              <svg class="w-6 h-6 fill-blank hover:fill-black trns-color"><use xlink:href="#ring-close" /></svg>
            </button>
          </div>
          <div class="relative p-6">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss">
.amodal-shade {
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);

  &.shade-top {
    top: 60px;
  }
}

.amodal {
  box-shadow: 1px 4px 6px 0px rgba(0, 0, 0, 0.3);
}
</style>

<script>
import LessonPreview from '@/content/components/previews/LessonPreview';
import KeywordsModal from '@/content/components/modal/KeywordsModal';
import SkillLevels from '@/content/components/partials/SkillLevels';
import ReviewModal from '@/content/components/modal/ReviewModal';
import DiceModal from '@/content/components/modal/DiceModal';
import Disc from '@/content/components/partials/Disc';
import routeInfo from '@/mixins/routeInfo';
import '@/assets/svgs/play-island.svg';
import '@/assets/svgs/cog-bg2.svg';

export default {
  components: { LessonPreview, ReviewModal, DiceModal, SkillLevels, Disc, KeywordsModal },
  mixins: [routeInfo],
  props: ['lesson', 'ability', 'skill'],

  computed: {
    colour() {
      return this.isGym ? 'gymcard' : 'physical';
    },
    skillText() {
      return this.lesson.funsText || this.lesson.goalText;
    },
    cogLabel() {
      return this.ability.cogLabel || this.lesson.unit.cogLabel;
    },
    skillImage() {
      return this.lesson.funsStation?.[0].parent.secondaryImage?.[0].url;
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="relative bg-white curves parts-offset">
      <div class="box-contain">
        <h2 class="box-title text-4xl font-bold">Learning Goals</h2>

        <div class="box-buttons">
          <KeywordsModal :keywords="lesson.keywords" />
          <DiceModal :dice="lesson.dice" />
          <ReviewModal :ability="ability" :number="1" class="print:hidden" />
        </div>
      </div>

      <div
        :class="isPlay ? '-ml-24' : '-ml-32'"
        class="flex flex-wrap items-center mb-5 -mr-8 lg:justify-center lg:mx-auto"
      >
        <div class="relative text-white cog flex items-center justify-center print:text-black">
          <svg :class="`fill-${ability.slug}`" class="absolute inset-0 w-full h-full back print:hidden">
            <use :xlink:href="isPlay ? '#play-island' : '#cog-bg2'" />
          </svg>

          <div class="cogtainer relative" ref="cog">
            <div v-if="isPlay" class="mb-4 text-3xl font-bold text-center">{{ lesson.unit.island }} Island</div>
            <div v-else class="text-center">
              <div class="text-2xl font-extrabold mb-3">{{ cogLabel }}</div>
            </div>

            <div :class="{ 'text-center': !isPlay }" class="text-xl">
              <div v-if="isPlay" class="font-bold mb-3">{{ lesson.unit.strapline }}</div>
              <div v-for="row in ability.abilityWhat" class="mb-4">{{ row.what }}</div>
            </div>
          </div>
        </div>

        <div
          :class="[`bg-${colour}-shade`, isPlay ? '-mt-64' : '-mt-48']"
          class="flex flex-col items-center justify-center ml-auto text-lg font-medium rounded-full circle lg:mt-0 lg:-ml-24"
        >
          <div class="pl-20 pr-12 ml-3 lg:pl-24">
            <h2 class="font-bold text-2xl">
              <span v-if="skill.category" class="text-physical">{{ skill.category }}</span>
              {{ skill.title }}
            </h2>

            <div v-if="skillText" v-html="skillText" class="rdct rdct-teacher"></div>
            <SkillLevels :unit="lesson.unit" simple class="mt-4" />
            <img v-if="skillImage" :src="skillImage" class="w-64 mt-4 ml-auto -mb-20" />
          </div>
        </div>
      </div>
    </div>

    <LessonPreview :lesson="lesson" pupil class="mx-2 lg:mx-4 xl:mx-20 border-6 border-grey-l3" />
  </div>
</template>

<style lang="postcss" scoped>
.cog {
  width: 570px;
  height: 570px;
}
.circle {
  width: 500px;
  height: 500px;
}
.cogtainer {
  width: 320px;
}
</style>

<script>
export default {
  props: ['uri', 'linkClass'],
};
</script>

<template>
  <RouterLink :class="linkClass" v-if="uri" :to="uri">
    <slot />
  </RouterLink>
  <div v-else>
    <slot />
  </div>
</template>

<script>
import '@/assets/svgs/icons/tick.svg';
import '@/assets/svgs/icons/ring-add.svg';

export default {
  props: ['group'],

  computed: {
    enabled() {
      return !this.synced;
    },
    synced() {
      return this.group.synced;
    },
    colour() {
      if (this.synced) return 'text-bursts';
      return 'text-bursts-shade';
    },
    icon() {
      if (this.synced) return 'tick';
      return 'ring-add';
    },
  },

  methods: {
    add() {
      if (this.enabled) this.$emit('add', this.group);
    },
  },
};
</script>

<template>
  <component :is="enabled ? 'button' : 'div'" :class="colour" class="flex items-center card" @click="add">
    <div class="text-black flex-grow">
      {{ group.name }}
      <div class="text-xs">
        {{ group.description }}
      </div>
    </div>

    <div>
      <!-- <div v-if="group.spin" class="mini-spinner spin08 bursts"></div> -->
      <svg v-if="icon" class="w-5 h-5 fill-bursts"><use :xlink:href="`#${icon}`" /></svg>
    </div>
  </component>
</template>

<script>
import DoubleCard from '@/admin/components/DoubleCard';
import { emailReg } from '@/scripts/filters';
import { mapState } from 'vuex';

export default {
  components: { DoubleCard },
  props: ['user'],
  data: () => ({ ready: true, synced: false }),

  computed: {
    enabled() {
      return !this.user.synced && !!this.user.email && !this.personal;
    },
    synced$() {
      return this.synced || this.user.synced;
    },
    colour() {
      if (this.synced$) return 'text-pe';
      if (this.emailError) return 'text-fun-red-shade';
      return 'text-pe-shade';
    },
    icon() {
      if (!this.ready) return 'spin';
      if (this.synced$) return 'tick';
      if (this.emailError) return 'warn';
      return 'ring-add';
    },
    personal() {
      return this.user.email && emailReg.test(this.user.email);
    },
    emailError() {
      return !this.synced$ && (!this.user.email || this.user.taken || this.personal);
    },

    on() {
      return this.enabled && this.ready ? { click: () => this.add() } : {};
    },

    ...mapState('account', ['wondeId']),
  },

  methods: {
    add() {
      if (!this.enabled || !this.ready) return;
      this.ready = false;
      this.$api.post(`wonde/${this.wondeId}/employees/${this.user.wondeId}`).then(res => {
        this.$emit('update');
        this.ready = true;
        this.synced = true;
      });
    },
  },
};
</script>

<template>
  <DoubleCard :icon="icon" :chip="colour" v-on="on">
    <div class="text-black">
      {{ user.name }}
      <div class="text-xs truncate">
        <span :title="user.email" :class="{ 'text-fun-red': emailError }">{{ user.email || '-' }}</span>
      </div>
    </div>

    <template v-if="ready" #info>
      <template v-if="synced$"> This user has already been invited </template>
      <template v-else-if="!user.email">
        This user has no email, please ask your MIS administrator to update this user's primary email.
      </template>
      <template v-else-if="personal">
        Personal emails are not permitted, please ask your MIS administrator to update this user's primary email field
        to their school email address.
      </template>
      <template v-else-if="user.taken"> This email has already been invited to a different account. </template>
      <template v-else-if="!ready">Syncing</template>
      <template v-else> Click to invite this user to the the real PE platform. </template>
    </template>
  </DoubleCard>
</template>

<script>
import WarmupPupil from '@/content/components/activities/WarmupPupil';
import PhysicalModal from '@/content/components/modal/PhysicalModal';
import TrickyPupil from '@/content/components/partials/TrickyPupil';
import AudioPlayer from '@/content/components/partials/AudioPlayer';
import VideoButton from '@/content/components/buttons/VideoButton';
import PartSteps from '@/content/components/activities/PartSteps';
import ReviewModal from '@/content/components/modal/ReviewModal';
import VimeoText from '@/content/components/partials/VimeoText';
import DiceModal from '@/content/components/modal/DiceModal';
import CogModal from '@/content/components/modal/CogModal';
import Song from '@/content/components/activities/Song';
import Hero from '@/content/components/partials/Hero';
import routeInfo from '@/mixins/routeInfo';

export default {
  // prettier-ignore
  components: {
    CogModal, ReviewModal, PhysicalModal, VideoButton, AudioPlayer, DiceModal, 
    Hero, Song, WarmupPupil, VimeoText, PartSteps, TrickyPupil,
  },

  props: ['lesson', 'ability', 'skill', 'part'],
  data: () => ({ seconds: 0, showVideo: false, step: 0 }),
  mixins: [routeInfo],

  computed: {
    title() {
      return this.part.displayTitle || this.part.title.replace(/ - [0-9]+$/, '');
    },
    showPhysical() {
      return this.part.type !== 'review' && this.part.type !== 'warmup';
    },
    isSong() {
      return this.part.type === 'song';
    },
    showCog() {
      return this.part.type !== 'movements';
    },
    cogText() {
      if (this.isGym && this.ability) {
        const text = this.part.abilityText;
        return text ? `<h2 class="text-4xl">${this.ability.title}</h2>${text}` : '';
      }
      return this.part.cogOverwrite || '';
    },
  },

  methods: {
    stepUpdate(step) {
      this.$emit('step', step);
      this.step = step;
    },
  },
};
</script>

<template>
  <div class="bg-white curves parts-offset">
    <div class="box-contain">
      <div class="box-title">
        <h1>{{ title }}</h1>
        <div class="text-2xl font-medium" v-if="part.tune">To the tune of: {{ part.tune }}</div>
      </div>

      <div class="box-buttons">
        <VideoButton v-if="part.video && !isSong" @click="showVideo = !showVideo" />
        <DiceModal :dice="part.dice" :part="part" :ability="ability" />
        <ReviewModal :ability="ability" :number="part.reviewNumber" />
        <AudioPlayer :audio="part.audioMusic" @tick="seconds = $event" progress loop />
        <PhysicalModal v-if="showPhysical" :skill="skill" :entry="part" :text="part.physicalOverwrite" />
        <CogModal v-if="showCog" :lesson="lesson" :ability="ability" :text="cogText" />
      </div>
    </div>

    <template v-if="showVideo">
      <VimeoText :vid="part.video" :key="part.video" :lines="part.textLines" colour="pe" autoplay class="mb-5" />
    </template>
    <template v-else>
      <Hero :asset="part.heroImage" class="mb-5 hero-max" />
      <PartSteps :part="part" :key="part.id" @change="stepUpdate" />
    </template>

    <div v-if="part.pupilText" class="mx-8 my-5">
      <div v-html="part.pupilText" class="rdct rdct-pupil"></div>
    </div>

    <WarmupPupil v-if="part.warmUpSteps" :lesson="lesson" :partDice="part.dice" :steps="part.warmUpSteps" />
    <Song v-else-if="isSong" :part="part" :seconds="seconds" />

    <TrickyPupil v-if="part.tricky" :lesson="lesson" :part="part" class="mx-8" />
  </div>
</template>

<style lang="postcss">
.hero-max {
  max-height: 560px;
}
</style>

<script>
export default {
  props: ['active'],
};
</script>

<template>
  <button v-if="active" @click="$emit('click')">
    <slot />
  </button>
  <div v-else>
    <slot />
  </div>
</template>

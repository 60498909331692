<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import AdminModal from '@/admin/components/AdminModal';

export default {
  components: { AdminModal, AdminSpinner },
  props: ['active', 'group'],
  data: () => ({ results: [], ready: true }),

  computed: {
    canInvite() {
      return this.group.dateInvited ? this.$dayjs(this.group.dateInvited).isBefore(this.$dayjs(), 'day') : true;
    },
    succesful() {
      return this.results.filter(([name, result]) => result === true);
    },
    noContacts() {
      return this.results.filter(([name, result]) => result === 'no contact').map(([name]) => name);
    },
    title() {
      return this.group?.totalInvitedStudents ? 'Re-invite Accounts' : 'Invite Accounts';
    },
    buttonLabel() {
      return this.totalReinvite ? 'Re-invite' : 'Invite';
    },

    totalInvite() {
      return this.group.totalInactiveStudents - this.group.totalInvitedStudents;
    },
    totalReinvite() {
      return this.group.totalInactiveStudents - this.totalInvite;
    },
  },

  methods: {
    submit() {
      this.ready = false;
      this.$api.post(`groups/${this.group.id}/reinvite`).then(res => {
        if (res.data && res.data.length) this.results = res.data;
        this.$emit('change');
        this.ready = true;
      });
    },
  },
};
</script>

<template>
  <AdminModal :active="active" :title="title" @close="$emit('close')">
    <AdminSpinner :ready="ready" class="-m-6 p-6">
      <div v-if="results.length">
        <p class="mb-3">
          <strong>{{ succesful.length }}</strong> children have been sent new invites.
        </p>

        <ul v-if="noContacts.length" class="err-list max-h-64 overflow-auto">
          <li>
            The following <strong>{{ noContacts.length }}</strong> children do not have any contact details.
          </li>
          <div class="flex flex-wrap -mx-1 mt-2">
            <span v-for="student in noContacts" :key="student" class="rounded px-1 m-1 bg-white-5">
              {{ student }}
            </span>
          </div>
        </ul>
      </div>

      <template v-else-if="canInvite">
        <p>Are you sure you want to invite or re-invite the inactive children in this class?</p>
        <p v-if="totalInvite" class="mt-2">
          - <strong>{{ totalInvite }}</strong> will be sent invites.
        </p>

        <p v-if="totalReinvite">
          - <strong>{{ totalReinvite }}</strong> will be re-sent invites.
        </p>
      </template>

      <p class="err-list" v-else>You can only re-invite children once a day.</p>

      <div class="btns4 mt-6">
        <button class="btn4-clear" type="button" @click="$emit('close')">Cancel</button>
        <button v-if="results.length" type="button" class="btn4 bg-bursts" @click="$emit('close')">Done</button>
        <button v-else-if="canInvite" class="btn4 bg-bursts" @click="submit">{{ buttonLabel }}</button>
      </div>
    </AdminSpinner>
  </AdminModal>
</template>

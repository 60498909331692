<script>
import FormControl from '@/admin/components/FormControl';
import FormSelect from '@/admin/components/FormSelect';
import AdminModal from '@/admin/components/AdminModal';
import AdminSpinner from '@/admin/components/AdminSpinner';
import { validationMixin } from 'vuelidate';
import { $student } from '@/scripts/models';
import { required, email } from 'vuelidate/lib/validators';

export default {
  components: { AdminSpinner, AdminModal, FormControl, FormSelect },
  mixins: [validationMixin],
  props: ['value'],
  data: () => ({ student: $student.one(), groups: [], showDelete: false }),

  validations: {
    student: {
      firstName: { required },
      lastName: { required },
      contactEmail: { email },
      group: {},
    },
  },

  computed: {
    studentId() {
      return this.$route.params.studentId;
    },
    isNew() {
      return !this.studentId;
    },
  },

  methods: {
    fetch() {
      this.$api.get('groups', { params: { accountId: this.$route.params.accountId } }).then(res => {
        this.groups = res.data;
      });
    },

    submit() {
      this.$v.$touch();
      if (this.student.$ready && !this.$v.$invalid) {
        if (this.student.id) {
          this.student.$update().then(() => {
            this.$emit('input', this.student);
            this.$emit('close');
          });
        } else {
          this.student.account = { id: this.$route.params.accountId };
          this.student.$create().then(() => {
            if (this.student.id) this.$router.replace(`${this.uri}bursts/students/${this.student.id}`);
          });
        }
      }
    },

    remove() {
      if (this.student.$ready) {
        this.showDelete = false;
        this.student.$delete().then(() => {
          this.$router.replace(`${this.uri}bursts`);
        });
      }
    },
  },

  mounted() {
    this.student.$load(this.value);

    if (!this.student.group && this.$route.params.groupId) {
      this.student.group = { id: this.$route.params.groupId };
    }

    this.fetch();
  },

  watch: {
    value(val) {
      this.student = val;
    },
  },
};
</script>

<template>
  <div>
    <form @submit.prevent="submit" class="">
      <div class="flex -mx-2">
        <FormControl :v="$v.student" slug="firstName" label="First Name" class="w-1/2 px-2">
          <input type="text" id="firstName" v-model.trim="$v.student.firstName.$model" />
        </FormControl>
        <FormControl :v="$v.student" slug="lastName" label="Last Name" class="w-1/2 px-2">
          <input type="text" id="lastName" v-model.trim="$v.student.lastName.$model" />
        </FormControl>
      </div>

      <FormControl :v="$v.student" slug="contactEmail" label="Contact Email">
        <input type="email" id="contactEmail" v-model.trim="$v.student.contactEmail.$model" />
      </FormControl>

      <FormControl :v="$v.student" slug="group" label="Class">
        <FormSelect v-model="$v.student.group.$model" :options="groups" id="group">
          <option value="">- None -</option>
          <option v-for="group in groups" :key="group.id" :value="group.id">
            {{ group.name }}
          </option>
        </FormSelect>
      </FormControl>

      <template v-if="!isNew">
        <div>
          <label class="block mb-1 font-bold">Unique Code</label>
          <div class="p-3 font-mono border-2 border-dashed curves-sm border-grey-l2">
            {{ student.code }}
          </div>
        </div>

        <hr />

        <div class="flex flex-wrap items-center justify-between">
          <div>
            <h4 class="font-bold">Remove Student</h4>
            <p class="text-sm">This student will be deleted (and maybe their data?)</p>
          </div>
          <div>
            <button v-if="!isNew" type="button" class="btn4" @click="showDelete = true">Delete</button>
          </div>
        </div>

        <hr />
      </template>

      <div class="flex justify-between mt-8">
        <div></div>
        <div class="btns4">
          <button type="button" class="btn4" @click="$emit('close')">Cancel</button>
          <button class="btn4 bg-pe">Save</button>
        </div>
      </div>

      <AdminModal :active="showDelete" inline title="Confirm Delete?" @close="showDelete = false">
        <div class="btns4">
          <button type="button" class="btn4" @click="showDelete = false">Cancel</button>
          <button type="button" class="btn4 bg-fun-red" @click="remove()">Delete Student</button>
        </div>
      </AdminModal>
    </form>
  </div>
</template>

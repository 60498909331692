<script>
import AdminModal from '@/admin/components/AdminModal';
import { mapState, mapMutations } from 'vuex';

export default {
  components: { AdminModal },

  computed: {
    tempDays() {
      return this.$djDays(this.dateEndTemp);
    },
    tempDate() {
      return this.$djMed(this.dateEndTemp);
    },

    ...mapState('user', ['showTempModal', 'dateEndTemp', 'email']),
  },

  methods: {
    close() {
      this.setTempModal(false);
    },
    edit() {
      this.setTempModal(false);
      this.$router.push('/profile');
    },

    ...mapMutations('user', ['setTempModal']),
  },
};
</script>

<template>
  <AdminModal :active="showTempModal" title="User Deletion Warning" @close="close">
    <div class="prosex">
      <p class="mb-4">
        This user is not connected to a record in your school's Management Information System (MIS). You have
        <strong>{{ tempDays }} days</strong> to resolve this issue before your access is removed.
      </p>
      <p class="mb-4">
        Please speak to the MIS administrator in your school to ensure your record has your work email address listed
        and that you are using your work email to log into the real PE platform.
      </p>

      <p class="mb-4"><strong>Current email:</strong> {{ email }}</p>

      <p class="mb-4 note note-error">
        Without a connection, your user will be removed on <strong>{{ tempDate }}</strong>
        <svg class="note-icon"><use xlink:href="#warn" /></svg>
      </p>

      <div class="btns4 mt-6">
        <div class="flex-grow"></div>
        <button class="btn4-clear" @click="edit">Edit My Email</button>
        <button class="btn4 bg-theme" @click="close">I Understand</button>
      </div>
    </div>
  </AdminModal>
</template>

<script>
export default {
  props: { record: {}, full: Boolean },

  computed: {
    change() {
      return this.record.totalActive - this.record.previousActive;
    },
  },
};
</script>

<template>
  <div class="flex items-center text-bursts leading-none">
    <img src="/images/bursts/user-icon.svg" alt="" class="widthr-1 mr-2" />
    <div class="font-semibold">
      {{ record.totalActive }}
      <span v-if="full" class="textr-05">Active children</span>
    </div>
    <div v-if="change" class="text text-fun-green pl-2 textr-08">
      <span class="textr-08">↑</span>
      {{ change }}
    </div>
  </div>
</template>

export default (by, $fetch) => ({
  data: () => ({ srt: { by, dir: true } }),

  computed: {
    $sortParam() {
      return `${this.srt.dir ? '' : '-'}${this.srt.by}`;
    },
  },

  methods: {
    // $sort(key) {
    //   if (this.sortBy === key) {
    //     console.log(key, this.sortBy);
    //     this.sortDir = !this.sortDir;
    //   } else {
    //     this.sortBy = key;
    //     this.sortDir = true;
    //   }
    //   this[$fetch]();
    // },
  },

  watch: {
    $sortParam() {
      if ($fetch) this[$fetch]();
    },
  },
});

<script>
import Loader from '@/global/components/Loader';

export default {
  components: { Loader },

  methods: {
    connect() {
      this.$api
        .post('oauth/code', this.$route.query)
        .then(({ data }) => {
          if (data.url) window.location.href = data.url;
        })
        .catch(error => {
          console.log(error);
          // this.$store.commit('toast/error', Failed to sign you in');
          // this.$router.push('/');
        });
    },
  },

  mounted() {
    if (this.$route.query.client_id === 'riseup') {
      this.connect();
    }
  },
};
</script>

<template>
  <Loader class="container" />
</template>

<script>
// formSelect v1.5

export default {
  props: {
    options: { type: Array, default: () => [] },
    value: { default: () => ({}) },
  },

  computed: {
    val() {
      return this.value && this.value.id ? this.value.id : '';
    },
  },

  methods: {
    change($event) {
      this.$emit('input', this.options.find(option => option.id == $event.target.value) || null);
    },
  },

  watch: {
    options() {
      this.$nextTick(() => this.$forceUpdate());
    },
  },
};
</script>

<template>
  <select type="text" id="sectorId" :value="val" @change="change">
    <slot></slot>
  </select>
</template>

<script>
export default {
  props: ['asset'],

  computed: {
    img() {
      return (!!this.asset && this.asset[0]) || {};
    },
  },
};
</script>

<template>
  <img v-if="img.url" :src="img.url" class="mx-auto" />
</template>

<script>
import FormControl from '@/admin/components/FormControl';
import AdminSpinner from '@/admin/components/AdminSpinner';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import '@/assets/svgs/icons/user-disc.svg';

export default {
  components: { FormControl, AdminSpinner },
  data: () => ({ email: '', sent: false, ready: true, result: false, noreg: false }),
  metaInfo: { title: 'Password Reset' },
  validations: { email: { required, email } },
  mixins: [validationMixin],

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.ready || this.$v.$invalid) return;

      this.ready = false;

      this.$api
        .post('auth/reset', { email: this.email })
        .then(res => {
          console.log('yes');
          this.ready = true;
          this.sent = true;
          this.result = res.data;
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.label === 'noreg') {
            this.noreg = true;
          }
          this.ready = true;
        });
    },

    reinvite() {
      this.ready = false;
      this.$api
        .post('auth/reinvite', { email: this.email })
        .then(res => {
          this.ready = true;
          this.result = true;
        })
        .catch(error => {
          this.ready = true;
        });
    },
  },
};
</script>

<template>
  <AdminSpinner :ready="ready" class="">
    <h2 class="head-2xl mb-5 text-center">Request Password</h2>

    <div v-if="sent" class="note note-success">
      <p>
        <strong v-if="result">An email has been sent to you containing instructions to reset your password.</strong>
        <strong v-else>Please contact your school admin, they can give you a new password.</strong>
      </p>
      <p>
        You can also try to
        <RouterLink to="/login">sign in</RouterLink>
        again.
      </p>
    </div>
    <div v-else-if="noreg">
      <div v-if="result" class="note note-success">
        A new invite email has been sent to you. Please click the link in the email to complete registration.
      </div>
      <template v-else>
        <div class="note note-error">
          You have not yet completed registration with this email address. Do you want a new invite email?
        </div>
        <div class="btns4 mt-6">
          <RouterLink to="/login" class="btn4-clear">Cancel</RouterLink>
          <button class="btn4 bg-pe" @click="reinvite">Re-send Invite</button>
        </div>
      </template>
    </div>

    <form v-else @submit.prevent="submit" novalidate>
      <FormControl :v="$v" slug="email" label="Email">
        <input type="text" v-model="$v.email.$model" id="email" />
      </FormControl>
      <div class="btns4 mt-6">
        <RouterLink to="/login" class="btn4-clear">Cancel</RouterLink>
        <button class="btn4 bg-pe">Reset</button>
      </div>
    </form>
  </AdminSpinner>
</template>

<script>
import DifficultySelect from '@/content/components/partials/DifficultySelect';
import TextBlock from '@/content/components/partials/TextBlock';

export default {
  components: { DifficultySelect, TextBlock },
  props: ['lesson', 'part'],
  data: () => ({ active: '' }),

  computed: {
    level() {
      const isApplication = this.lesson.parts[2].slug === this.$route.params.part;
      // pe game - gym game - dance application

      if (this.active) return this.active;
      if (isApplication && this.lesson.applicationTricky) return '';
      return 'tricky';
    },
    title() {
      return this.level || 'Tricky / Trickier / Trickiest';
    },
    icon() {
      return this.level || 'tricky0';
    },
    text() {
      return this.part[this.level] || this.lesson.applicationTricky;
    },
  },

  watch: {
    $route() {
      this.active = '';
    },
  },
};
</script>

<template>
  <div>
    <TextBlock :icon="icon" colour="tricky" highlight class="px-16x mb-8">
      <h2 class="teacher-title capitalize text-tricky">{{ title }}</h2>
      <div v-html="text" class="rdct rdct-pupil" />
    </TextBlock>
    <DifficultySelect :active="level" :dice="part.dice" @change="active = $event" />
  </div>
</template>

import $api from '@/scripts/api';
import { status } from '@/scripts/strings';
import { djMed, djMedT, $dayjs } from '@/scripts/day';

const zoho = process.env.VUE_APP_ZOHO;

// prettier-ignore
const baseAccount = {
  id: '', name: '', urn: '',
  status: 0, frozen: 0,
  access: {},
  accountType: '',
  address: {}, region: {}, county: {},
  size: '', wheelLink: '',
  hasRealPe: 0, hasRealPeFull: 0, hasRealPeTrial: 0, hasBursts: 0,
  manager: '', authority: '', localPartner: '', partnerType: '',
  totalUsers: 0, totalAdmins: 0,  totalAccountLogins: 0, totalHomeLogins: 0, totalTraining: 0, totalHome: 0,
  lastAccountLogin: '', lastHomeLogin: '',
  riskAssessment: false,
  zohoAccountId: '', zohoRealId: '', zohoBurstsId:'', wondeId: '', wondeRealPe: 0, wondeBursts: 0, dateWonde: '',
  realLicence: {}, burstsLicence: {}, renewalRealPe: '', renewalBursts: '', trialExpRealPe: '',
  levels: [], totalMinutes: 0, previousMinutes: 0, totalActive: 0, previousActive: 0, totalStudents: 0,
  realLeads: [],
};

export default {
  namespaced: true,

  state: {
    ...baseAccount,
    $fetched: false,
    $ready: false,
    $self: false,
  },

  mutations: {
    set(state, account) {
      const base = { ...baseAccount };

      Object.keys(base).forEach(field => {
        state[field] = account[field] || base[field];
      });

      state.$self = account.self;
      state.$fetched = true;
      state.$ready = true;
    },

    patch(state, account) {
      Object.keys(account).forEach(field => {
        state[field] = account[field];
      });
    },

    clear(state) {
      const base = { ...baseAccount };

      Object.keys(base).forEach(field => {
        state[field] = base[field];
      });

      state.$fetched = false;
      state.$ready = false;
    },
  },

  getters: {
    accountId: state => state.id,
    status: state => status[state.status],

    isPartner: state => state.accountType === 'partner',
    isEdu: state => state.accountType === 'education',
    accountHasWonde: state => !!(state.wondeId && (state.wondeRealPe || state.wondeBursts)),

    // hasRealPe: state => !!state.hasRealPe,
    hasRealPeFull: state => !!state.hasRealPeFull,
    hasRealPeTrial: state => !!state.hasRealPeTrial,

    hasHome: state => state.access.home && state.access.home.length,
    hasTraining: state => !!state.totalTraining,
    hasBursts: state => !!state.hasBursts,

    renewalRealPe: state => djMed(state.renewalRealPe),
    renewalBursts: state => djMed(state.renewalBursts),
    trialExpRealPe: state => djMed(state.trialExpRealPe),
    trialDaysRealPe: state => $dayjs(state.trialExpRealPe).diff($dayjs(), 'day'),

    wheelUrl: state => state.wheelLink,
    zohoUrl: state => (state.zohoAccountId ? `${zoho}tab/Accounts/${state.zohoAccountId}` : ''),
    zohoRealPeUrl: state => (state.zohoRealId ? `${zoho}tab/CustomModule3/${state.zohoRealId}` : ''),
    zohoBurstsUrl: state => (state.zohoBurstsId ? `${zoho}tab/CustomModule11/${state.zohoBurstsId}` : ''),

    uri: state => (state.$self ? `/account/` : `/accounts/${state.id}/`),
    burstsUri: state => (state.$self ? `/bursts/` : `/accounts/${state.id}/bursts/`),

    wondeRealPe: state => !!state.wondeRealPe,
    wondeBursts: state => !!state.wondeBursts,
    wondeDate: state => djMed(state.dateWonde, d => d.add(3, 'month')),

    mailto: state => (contact, intro) => ({
      ...contact,
      mailto: `mailto:${contact.email}?subject=${intro} from ${state.name}`,
    }),
  },

  actions: {
    self({ commit }) {
      return $api.get('auth/account').then(response => {
        commit('set', { self: true, ...response.data });
      });
    },

    fetch({ commit, rootState }, clear) {
      if (clear) commit('clear');
      const id = rootState.route.params.accountId;
      return $api.get(`accounts/${id}`).then(response => {
        commit('set', response.data);
      });
    },
  },
};

<script>
import Vimeo from '@/content/components/partials/Vimeo';
import NavLink from '@/global/components/NavLink';
import Hyper from '@/global/components/Hyper';
import '@/assets/svgs/buttons/file.svg';

export default {
  components: { Vimeo, NavLink, Hyper },
  props: ['blocks', 'colour'],
  name: 'SupportBlocks',

  data: () => ({ open: {} }),

  computed: {
    blocks$() {
      return (this.blocks || []).map(block => {
        return { ...block, hasWell: block.videos?.length || block.documents?.length };
      });
    },
  },

  methods: {
    toggle(block) {
      this.$set(this.open, block.id, !this.open[block.id]);
    },
  },
};
</script>

<template>
  <div>
    <div v-for="(block, i) in blocks$" :key="block.id" :id="block.id" class="my-4">
      <!-- v-if="block.type === 'heading'" -->

      <button
        v-if="block.type === 'faq'"
        :class="{ 'border-t': true }"
        class="w-full text-left flex justify-between items-end border-grey-l2"
        @click="toggle(block)"
      >
        <h2 class="text-xl font-bold px-5 pt-6">
          {{ block.label }}
        </h2>
        <svg class="w-6 h-6 inline fill-grey mr-5 mb-1">
          <use xlink:href="#ring-add" />
        </svg>
      </button>
      <h2 v-else-if="block.label" :class="{ 'border-t': true }" class="text-3xl px-5 pt-6 border-grey-l2">
        {{ block.label }}
      </h2>

      <div v-else-if="block.type === 'text2'" v-html="block.text" class="prose px-5 max-w-none"></div>

      <div v-else-if="block.type === 'links'" class="mt-3 px-3 flex">
        <!-- <pre>{{ link }}</pre> -->
        <Hyper v-for="link in block.hyperLinks" :key="link.id" v-bind="link" class="nm1" />
      </div>

      <div v-if="block.hasWell" class="lg:flex flex-wrap bg-grey-l3 p-1 rounded-lg rounded-bl-none m-3">
        <div v-for="file in block.documents" :key="file.id" class="lg:w-1/2 p-2">
          <a
            :href="file.url"
            target="_blank"
            class="flex items-center h-full bg-white outlne rounded shadow-sm p-1 pr-3"
          >
            <div class="relative shrink-0 mr-2 scale-75">
              <svg :class="`fill-${colour}`" class="w-8 h-10"><use xlink:href="#file" /></svg>
              <div class="absolute inset-x-0 bottom-0 mb-1 text-xs text-center">{{ file.extension }}</div>
            </div>
            <div class="leading-snug">{{ file.title }}</div>
          </a>
        </div>
        <div v-for="video in block.videos" :key="video.id" class="lg:w-1/2 p-2">
          <Vimeo :vid="video.id" class="rounded overflow-hidden bg-grey-l2" />
        </div>
      </div>

      <SupportBlocks v-if="block.type !== 'faq' || open[block.id]" :blocks="block.children" :colour="colour" />
    </div>
  </div>
</template>

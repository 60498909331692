<script>
import UserForm from '@/admin/components/users/UserForm';
import { mapState } from 'vuex';

export default {
  components: { UserForm },
  metaInfo: { title: 'My Profile' },
  computed: mapState(['user']),

  methods: {
    update() {
      this.$store.dispatch('user/fetch');
    },
  },
};
</script>

<template>
  <div class="mx-auto max-w-xl bg-white p-6 curves-sm mt-8 shadow">
    <div class="head-4xl mb-5">My Profile</div>

    <div v-if="user.wondeId" class="note note-warn mb-4">
      <svg class="note-icon"><use xlink:href="#info" /></svg>
      This user information is generated by your school's Management Information System (MIS). Any ammendments should be
      made by your MIS administrator.
    </div>

    <UserForm :kind="user.kind" :value="user" isProfile @update="update" />
  </div>
</template>

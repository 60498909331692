<script>
import HaloButton from '@/content/components/buttons/HaloButton';
import Modal from '@/content/components/partials/Modal';
import { undupe } from '@/scripts/filters';
import '@/assets/svgs/buttons/key-vocabulary.svg';

export default {
  components: { Modal, HaloButton },
  props: { keywords: { default: () => [] } },
  data: () => ({ modal: false }),

  computed: {
    list() {
      return undupe(this.keywords, 'title') || [];
    },
  },
};
</script>

<template>
  <div v-if="keywords && keywords.length">
    <Modal :open="modal" icon="key-vocabulary" title="Key Vocabulary" @close="modal = false">
      <h3 v-for="keyword in list" :key="keyword" class="mb-2 text-4xl font-bold lowercase">
        {{ keyword }}
      </h3>
    </Modal>

    <HaloButton icon="key-vocabulary" label="Key Vocab" @click="modal = true" />
  </div>
</template>

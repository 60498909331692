<script>
export default {
  props: ['text'],
  data: () => ({ show: false }),

  watch: {
    text() {
      this.show = false;
    },
  },
};
</script>

<template>
  <div class="relative select-none">
    <button class="rounded-full block" @click="show = !show">
      <img src="/images/jasmine-icon.svg" class="w-16 h-16 lg:h-20 lg:w-20" />
    </button>

    <div class="absolute right-0 px-6 py-4 mt-4 opacity-0 bubble bg-pe z-10" :class="{ show }">
      <div v-html="text" class="text-2xl text-white font-cocon"></div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.bubble {
  @apply rounded-tl-2xl rounded-br-2xl pointer-events-none;
  width: 500px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s, transform 0.3s;
  transform: scale(0.5);
  transform-origin: top right;

  &:before {
    @apply absolute top-0 right-0 border-pe;
    content: '';
    transform: translateY(-100%);
    border-width: 12px;
    border-left-color: transparent;
    border-top-color: transparent;
  }

  &.show {
    opacity: 1;
    transform: scale(1);
  }
}
</style>

<script>
import HaloButton from '@/content/components/buttons/HaloButton';
import '@/assets/svgs/buttons/musicplayer.svg';
import { mapState } from 'vuex';

export default {
  components: { HaloButton },
  computed: mapState('user', ['showJukebox']),
};
</script>

<template>
  <HaloButton
    icon="musicplayer"
    label="Music Player"
    :active="showJukebox"
    @click="$store.commit('user/toggleJukebox')"
  />
</template>

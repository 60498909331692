<script>
import { mapState } from 'vuex';

export default {
  // prettier-ignore
  props: [
    'statusLabel', 'modeLabel', 'title', 'subtitle', 'info',
    'tutor', 'bookingContact', 'participant', 'timetableReceived', 'link', 'bookUrl',
    'startDate', 'endDate', 'completionDate', 'expired', 'soon', 'ruActive', 'ruComplete',
  ],

  computed: {
    zone() {
      return this.$dayjs.tz.guess();
    },

    tutorMail() {
      return this.mailTo(this.tutor);
    },
    contactMail() {
      return this.mailTo(this.bookingContact);
    },

    completion() {
      return this.$djMed(this.completionDate);
    },
    start() {
      return this.$djMedT(this.startDate, d => d.tz(this.zone));
    },
    end() {
      return this.$djFormat(this.endDate, 'HH:mm', d => d.tz(this.zone));
    },

    showUsers() {
      return this.ruActive !== null || this.ruComplete;
    },

    ...mapState(['account']),
  },

  methods: {
    mailTo(model) {
      const subject = encodeURIComponent(`Training Query from ${this.account.name}`);
      return model ? `mailto:${model.email}?subject=${subject}` : '';
    },
  },
};
</script>

<template>
  <div class="card text-pe-shade h-full">
    <div class="flex items-start mb-1">
      <div class="flex-grow">
        <div class="uppercase tracking-wider text-sm font-medium flex justify-between">
          <div class="text-black">{{ modeLabel }}</div>
          <div class="text-blank">{{ statusLabel }}</div>
        </div>
        <div class="text-lg font-bold text-pe">{{ title || '-' }}</div>
        <div v-if="subtitle" class="text-black">{{ subtitle }}</div>
      </div>
      <div v-if="info" class="shrink-0 border-l-2 border-grey-l3 border-b-2 rounded-bl-lg -mt-2 -mr-3 ml-3">
        <div class="outlne relative info-target p-3 rounded-bl-lg rounded-tr-lg">
          <svg class="w-5 h-5 fill-blank"><use xlink:href="#info" /></svg>
          <div class="info-popup w-64 px-3 py-2">
            <div v-html="info" class="text-black"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="start" class="text-black">{{ start }} {{ end ? 'to' : '' }} {{ end }}</div>

    <div class="flex flex-wrap -mx-2 text-black mt-2">
      <div v-if="showUsers" class="px-2">
        <div class="">
          <strong class="mr-3">Users:</strong>
          <strong class="mr-0.5">{{ ruActive }}</strong>
          Started
          <strong class="ml-2 mr-0.5">{{ ruComplete }}</strong>
          Completed
        </div>
      </div>

      <div v-if="tutor" class="px-2 py-1">
        <div class="sml-label">Tutor</div>
        <a :href="tutorMail" target="_blank" class="undr">{{ tutor.name }}</a>
      </div>
      <div v-if="participant" class="px-2 py-1">
        <div class="sml-label">Participant</div>
        <div>{{ participant }}</div>
      </div>
      <div v-if="modeLabel === 'Model Lesson'" class="px-2 py-1">
        <div class="sml-label">Timetable</div>
        <div>{{ timetableReceived ? 'Received' : 'Not received' }}</div>
      </div>
      <div v-if="completion" class="px-2 py-1">
        <div class="sml-label">Complete By</div>
        <div>{{ completion }}</div>
      </div>

      <div class="flex-grow"></div>

      <div v-if="link" class="self-end px-2 py-1 undr">
        <a :href="link" target="_blank">Joining Link</a>
      </div>
      <div v-else-if="bookUrl" class="self-end px-2 py-1 undr">
        <a :href="bookUrl" target="_blank">Book Now</a>
      </div>
      <div v-else-if="bookingContact" class="px-2 py-1">
        <div class="sml-label">Booking Contact</div>
        <a :href="contactMail" target="_blank" class="undr">
          {{ bookingContact.email }}
        </a>
      </div>
    </div>

    <div v-if="expired || soon" class="mt-2 mb-1">
      <span class="text-fun-red italic bg-fun-red-shade text-sm rounded p-1 px-2">
        <template v-if="expired">This training has expired</template>
        <template v-else-if="soon">Please complete this training before it expires in {{ soon }}</template>
      </span>
    </div>
  </div>
</template>

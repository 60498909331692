<script>
export default {
  props: ['uri', 'linkClass', 'activeClass', 'exact', 'newTab'],

  computed: {
    internal() {
      return this.uri && this.uri.startsWith('/');
    },
    target() {
      return this.newTab ? '_blank' : '';
    },
  },
};
</script>

<template>
  <button v-if="$listeners.click" :class="linkClass" @click="$emit('click')"><slot /></button>
  <RouterLink v-else-if="internal" :class="linkClass" :to="uri" :active-class="activeClass" :exact="exact">
    <slot />
  </RouterLink>
  <a v-else-if="uri" :class="linkClass" :href="uri" :target="target"><slot /></a>
  <div v-else><slot /></div>
</template>

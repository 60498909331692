import { render, staticRenderFns } from "./DifficultySelect.vue?vue&type=template&id=3b9ea1bc&scoped=true&"
import script from "./DifficultySelect.vue?vue&type=script&lang=js&"
export * from "./DifficultySelect.vue?vue&type=script&lang=js&"
import style0 from "./DifficultySelect.vue?vue&type=style&index=0&id=3b9ea1bc&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b9ea1bc",
  null
  
)

export default component.exports
<script>
import UserCard from '@/admin/components/users/UserCard';
import UserForm from '@/admin/components/users/UserForm';
import AdminSpinner from '@/admin/components/AdminSpinner';
import AdminModal from '@/admin/components/AdminModal';
import Pagination from '@/admin/components/Pagination';
import { $user } from '@/scripts/models';
import { mapGetters } from 'vuex';
import '@/assets/svgs/icons/info.svg';

export default {
  components: { UserCard, UserForm, AdminSpinner, AdminModal, Pagination },

  // prettier-ignore
  props: {
    title: String, kind: { default: 'account' }, 
    filter: String, params: {}, 
    headless: Boolean, simple: Boolean, hide: Boolean, 
  },

  // prettier-ignore
  data: () => ({
    users$: $user.all(), users: [], current: {},
    showEdit: false, showBulk: false, showDelete: false,
    all: false, userIds:[], // Mass delete data
  }),

  computed: {
    mapped() {
      return this.users.map(user => {
        const included = this.userIds.includes(user.id);
        return { ...user, selected: this.all ? !included : included };
      });
    },

    pageSelected() {
      const included = user => this.userIds.includes(user.id);
      return this.all ? !this.users.some(included) : this.users.every(included);
    },

    min() {
      return this.users$.totalPages > 1;
    },

    ...mapGetters('user', ['userId', 'isAdmin']),
    ...mapGetters('account', ['wondeDate']),
  },

  methods: {
    edit(user) {
      this.current = user;
      this.showEdit = true;
    },

    update() {
      this.fetch();
      this.showEdit = false;
    },

    nav(link) {
      this.fetch(link.page, false);
    },

    async fetch(page, reset = true) {
      if (reset) this.resetBulk();
      this.users = await this.users$.$get({ page, filter: this.filter, kind: this.kind, ...this.params });
    },

    resetBulk(all = false) {
      this.all = all;
      this.userIds = [];
    },
    toggleBulk() {
      this.showBulk = !this.showBulk;
      this.resetBulk();
    },
    toggleUser({ id, selected }) {
      const set = new Set(this.userIds);
      (this.all && !selected) || (!this.all && selected) ? set.delete(id) : set.add(id);
      this.userIds = [...set];
    },
    togglePage() {
      const set = new Set(this.userIds);
      (this.all && !this.pageSelected) || (!this.all && this.pageSelected)
        ? this.users.forEach(user => set.delete(user.id))
        : this.users.forEach(user => set.add(user.id));
      this.userIds = [...set];
    },
    toggleAll() {
      this.userIds = [];
      this.all = !this.all;
    },

    deleteSelectedUsers() {
      this.$api
        .delete(`users/${this.kind}/bulk`, {
          data: { all: this.all, userIds: this.userIds },
          params: { filter: this.filter, ...this.params },
        })
        .then(res => {
          this.fetch();
        })
        .catch(error => console.error(error))
        .then(() => {
          this.showDelete = false;
        });
    },
  },

  watch: {
    params() {
      this.fetch();
    },
  },

  created() {
    this.fetch();
  },
};
</script>

<template>
  <div v-if="!(users$.empty && hide)">
    <AdminSpinner :res="users$" hide>
      <div v-if="!headless" class="flex items-center mb-2">
        <h2 class="head-2xl capitalize text-theme">{{ title }}</h2>
        <span class="title-meta">{{ users$.totalItems }}</span>
        <div class="flex-grow"></div>
        <div v-if="!simple && isAdmin && !users$.empty" class="btns4">
          <button v-if="!showBulk" key="bulk1" class="btn4-clear" @click="showBulk = true">Select Many</button>
          <template v-else>
            <button class="btn4-clear" key="bulk2" @click="toggleBulk">Cancel Select</button>
            <template v-if="kind === 'home'">
              <button class="btn4" @click="toggleAll">{{ all ? 'Deselect' : 'Select' }} All</button>
              <button class="btn4" @click="togglePage">{{ pageSelected ? 'Deselect' : 'Select' }} Page</button>
            </template>
            <button :disabled="!all && !userIds.length" class="btn4 bg-fun-red" @click="showDelete = true">
              Delete Selected
            </button>
          </template>
        </div>
      </div>

      <div v-if="filter === 'temp'" class="mb-2">
        These users were not found on Wonde, without a connection they will be <strong>removed after 30 days.</strong>
      </div>

      <div :class="{ min }" class="flex flex-wrap -mx-3 user-list">
        <div v-for="user in mapped" :key="user.id" class="w-1/2 px-3 my-2 lg:w-1/3">
          <UserCard :user="user" :showBulk="showBulk" :disabled="simple" @click="edit" @check="toggleUser" />
        </div>
      </div>

      <div v-if="users$.empty" class="empty my-2">
        <slot>There are no {{ filter || kind }} users</slot>
      </div>
      <Pagination :collection="users$" @change="nav" class="mt-3 mb-8" />
    </AdminSpinner>

    <AdminModal :active="showDelete" title="Delete selected users?" @close="showDelete = false">
      <p>
        Warning. This action will delete {{ all ? users$.totalItems - userIds.length : userIds.length }} of
        {{ users$.totalItems }} {{ title }} users.
      </p>

      <div class="btns4">
        <button class="btn4-clear" @click="showDelete = false">Cancel</button>
        <button class="btn4 bg-fun-red" @click="deleteSelectedUsers">Delete</button>
      </div>
    </AdminModal>

    <AdminModal :active="showEdit" title="Edit User" @close="showEdit = false">
      <UserForm :kind="kind" :value="current" @update="update" @close="showEdit = false" />
    </AdminModal>
  </div>
</template>

<style lang="postcss">
.user-list.min {
  /* min-height: 608px; */
}
</style>

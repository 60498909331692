const getYear = (year, unit) => {
  if (year === '0') {
    return unit ? 'Fs' : 'Foundation ';
  }
  return (unit ? 'Y' : 'Year ') + year;
};

export default p => {
  const conf = [
    { name: 'product', val: p.product === 'pe' ? 'PE' : p.product },
    { name: 'year', val: getYear(p.year, p.unit) },
    { name: 'unit', val: (p.lesson ? 'U' : 'Unit ') + p.unit },
    { name: 'lesson', val: `Lesson ${p.lesson}` },
  ];

  return conf.reduce((carry, level) => {
    if (p[level.name]) {
      return `${carry}${level.val} `;
    }
    return carry;
  }, '');
};

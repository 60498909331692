<script>
const focuses = ['balance', 'shape', 'travel', 'flight', 'rotation'];

export default {
  props: ['lesson'],

  computed: {
    links() {
      return focuses.reduce((carry, slug) => {
        const entries = this.lesson.skills.filter(card => card.focus.slug === slug);
        if (entries.length) {
          const cards = Array.from({ length: 7 });
          entries.forEach(card => {
            cards[card.number - 1] = card.slug;
          });
          carry.push({ slug, cards });
        }
        return carry;
      }, []);
    },

    large() {
      return this.links.length === 1;
    },
  },
};
</script>

<template>
  <div class="p-5 my-2 bg-white curves print:hidden" :class="{ large }">
    <div class="flex flex-wrap items-center -m-2 font-bold">
      <div v-for="focus in links" :key="focus.slug" :class="large ? 'flex' : 'w-1/2 lg:w-1/3 mb-3'" class="px-2">
        <span class="mr-5 text-2xl capitalize text-gymcard">
          {{ focus.slug }}
          <img :src="`/images/gym-cards/${focus.slug}.svg`" class="inline h-8 mb-1 ml-2 align-bottom" />
        </span>
        <div class="flex items-center">
          <component
            v-for="(card, i) in focus.cards"
            :key="i"
            :is="card ? 'router-link' : 'div'"
            :to="card"
            :class="card ? 'bg-gymcard-shade text-gymcard' : 'bg-grey-l3 text-white'"
            class="gym-card"
          >
            {{ i + 1 }}
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.large {
  & .gym-card {
    @apply text-2xl;
  }
  & img {
    @apply h-12;
  }
}

.gym-card {
  @apply rounded-full flex items-center justify-center mr-1 text-xl select-none;
  width: 2em;
  height: 2em;
  transition: background 0.2s, color 0.2s;
}

a.gym-card {
  &:hover,
  &.router-link-active {
    @apply text-white bg-gymcard;
  }
}
</style>

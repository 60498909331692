export const address = account => {
  const address = { ...account.address, county: account.county ? account.county.title : '' };

  return ['line1', 'line2', 'city', 'county', 'postcode']
    .map(line => address[line])
    .filter(l => l)
    .join(', ');
};

export const num = val => ((val || 0) + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const uriSnip = (val, length) => val.split('/').slice(0, length).join('/');

export const zip = (a, b) => {
  if (!a || !b) return [];
  if (a.length < b.length) return zip(b, a);
  return a.map((k, i) => ({ ...k, ...(b[i] || {}) }));
};

export const undupe = (array, key = 'title') => [...new Set((array || []).map(item => item[key]?.trim() || item))];

export const emailReg = new RegExp(
  '@(aol|beinternet|blueskyit|blueyonder|btconnect|btinernet|btinternet|btinternt|btopenworld|example|excite|freenet|g.mail|gmail|gmt|gmx|google.mail|googlemail|googlemaill|hotmail|icloud|lightyear|lineone|live|lwe|lycos|mac|mail|me|msn|mypostoffice|ntl.world|ntlworld|o2|onetel|orange|orangehome|outlook|rocketmail|sky|talk21|talktalk|tesco|test|thisone|tinyworld|tiscali|virgin|virginmedia|yahoo|ymail).(cloud|co.uk|com|com.au|net)$'
);

export const emailGen = new RegExp(
  '^(admin|adminmanager|adminoffice|bursar|business|businessmanager|contact|contactus|deputy|enquiries|enquiries.general|enquiry|exec-head|executivehead|finance|financemanager|financeofficer|general|general.enquiries|generalenquiries|head|head.teacher|headmaster|headoffice|headofschool|headteacher|hello|inbox|info|information|mail|mailbox|mainoffice|manager|office|office.admin|officeinf|office-inf|officeinfants|officejun|office-jun|officemanager|officestaff|orders|primary|PrimaryInfo|primaryoffice|principal|sbm|School|school.office|schooladmin|schoolmanager|schooloffice|schools|schoolsecretary|senco|staff|theoffice|welcome)@'
);

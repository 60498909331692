const funsLevels = [
  ['yellow', 'green'],
  ['yellow', 'green'],
  ['green', 'red'],
  ['green', 'red'],
  ['red', 'blue'],
  ['red', 'blue'],
  ['blue', 'pink'],
];

export default {
  computed: {
    productSlug() {
      return this.$route.params.product || 'real';
    },
    productInfo() {
      return this.$products(this.productSlug) || {};
    },

    isDance() {
      return this.productSlug === 'dance';
    },
    isGym() {
      return this.productSlug === 'gym';
    },
    isHome() {
      return this.productSlug === 'home';
    },
    isPe() {
      return this.productSlug === 'pe';
    },
    isPlay() {
      return this.productSlug === 'play';
    },
    isFoundations() {
      return this.productSlug === 'foundations';
    },

    yearNum() {
      return parseInt(this.$route.params.year);
    },
    unitNum() {
      return parseInt(this.$route.params.unit);
    },
    lessonNum() {
      return parseInt(this.$route.params.lesson);
    },

    hasMusicPlayer() {
      if (!this.productInfo.floatingMusicPlayer) return false;
      return this.lesson.playlist && this.lesson.playlist.length > 0;
    },

    // product specific

    funsLevel() {
      return funsLevels[this.yearNum];
    },
  },
};

<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import FormControl from '@/admin/components/FormControl';
import { required, sameAs } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import axios from 'axios';

const api = axios.create({ baseURL: `${process.env.VUE_APP_API}parents/` });

export default {
  components: { AdminSpinner, FormControl },
  mixins: [validationMixin],
  data: () => ({ ready: true, state: '', password: '', password2: '' }),

  validations: {
    password: { required },
    password2: { required, sameAs: sameAs('password') },
  },

  computed: {
    resetToken() {
      return this.$route.query.reset;
    },
    verifyToken() {
      return this.$route.query.verify;
    },

    mode() {
      if (this.resetToken) return 'reset';
      if (this.verifyToken) return 'verify';
    },
  },

  methods: {
    verifyEmail() {
      this.ready = false;

      api
        .post('verify-email', { token: this.verifyToken })
        .then(res => {
          this.ready = true;
          this.state = 'success';
        })
        .catch(error => {
          this.ready = true;
          this.state = 'fail';
        });
    },

    resetCheck() {
      this.ready = false;

      api
        .post('reset-check', { token: this.resetToken })
        .then(() => {
          this.ready = true;
        })
        .catch(() => {
          this.ready = true;
          this.state = 'fail';
        });
    },

    resetChange() {
      this.$v.$touch();

      if (this.ready && !this.$v.$invalid) {
        this.ready = false;

        api
          .post('reset-change', { token: this.resetToken, password: this.password })
          .then(res => {
            this.ready = true;
            this.state = 'success';
          })
          .catch(error => {
            this.ready = true;
            this.state = 'fail';
          });
      }
    },
  },

  mounted() {
    if (this.verifyToken) {
      this.verifyEmail();
    } else if (this.resetToken) {
      this.resetCheck();
    }
  },
};
</script>

<template>
  <div class="max-w-lg pt-20 mx-auto">
    <img src="/brands/bursts.svg" alt="bursts logo" class="h-32 mx-auto" />
    <AdminSpinner :ready="ready">
      <div class="my-20">
        <template v-if="mode === 'verify'">
          <div v-if="state === 'success'" class="note note-success">
            <h2 class="mb-2 text-xl">Your email has been verified</h2>
            <p>You can now use this email to access the BURSTS App.</p>
            <p>This window can be safely closed.</p>
          </div>

          <div v-else-if="state === 'fail'" class="note note-error">
            <h2 class="mb-2 text-xl">This link has expired</h2>
            <p>Make sure you have used the link from the most recent email.</p>
            <p>You can request another verification email in the BURSTS App.</p>
          </div>
        </template>

        <template v-else-if="mode === 'reset'">
          <div v-if="state === 'success'" class="note note-success">
            <h2 class="mb-2 text-xl">Your password has been updated</h2>
            <p>You can now use this password to access the BURSTS App.</p>
            <p>This window can be safely closed.</p>
          </div>

          <div v-else-if="state === 'fail'" class="note note-error">
            <h2 class="mb-2 text-xl">This link has expired</h2>
            <p>Make sure you have used the link from the most recent email.</p>
            <p>You can request another reset email in the BURSTS App.</p>
          </div>

          <div v-else class="p-6 bg-white curves-sm">
            <h2 class="mb-1 text-4xl font-bold text-pe">Password Reset</h2>
            <p class="mb-8">Choose a new password for your BURSTS account</p>
            <form @submit.prevent="resetChange">
              <FormControl :v="$v" slug="password" label="New Password">
                <input type="password" id="password" v-model.trim="$v.password.$model" />
              </FormControl>
              <FormControl :v="$v" slug="password2" label="Confirm Password">
                <input type="password" id="password2" v-model.trim="$v.password2.$model" />
              </FormControl>
              <div class="flex justify-end mt-10"><button class="btn4 bg-pe">Update Password</button></div>
            </form>
          </div>
        </template>
      </div>
    </AdminSpinner>
  </div>
</template>

<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import FormControl from '@/admin/components/FormControl';
import AdminModal from '@/admin/components/AdminModal';
import Brand from '@/global/components/Brand';
import Link from '@/global/components/Link2';
import '@/assets/svgs/icons/eh-arrow.svg';
import { mapState, mapMutations } from 'vuex';

const licences = [
  { id: 1, title: '1 Year', price: '£695' },
  { id: 3, title: '3 Years', price: '£1,695', save: '£390' },
  { id: 5, title: '5 Years', price: '£2,495', save: '£980' },
];

const reasons = [
  { title: 'real PE is too ambitious for our teaching team right now' },
  { title: "We've found something else that better suits our needs" },
  { title: 'We found the real PE platform tricky to use' },
  { title: 'I am looking for something more sports specific' },
  { title: "It's too expensive for me right now" },
  { title: 'Something else', showOther: true },
];

export default {
  components: { AdminSpinner, AdminModal, Brand, Link, FormControl },

  data: () => ({
    licences,
    reasons,
    membership: 1,
    poNum: '',
    reason: reasons[0],
    reason2: '',
    ready: true,
    success: 0,
    failure: false,
  }),

  computed: {
    title() {
      return this.showTrialModal === 1 ? 'Purchase real PE membership' : 'End real PE trial';
    },
    ...mapState(['showTrialModal']),
  },

  methods: {
    submit() {
      if (!this.ready) return;
      this.ready = false;

      (this.showTrialModal === 1 ? this.puchase() : this.end())
        .then(() => {
          this.$store.dispatch('account/self');
          this.ready = true;
        })
        .catch(() => {
          this.failure = true;
          this.ready = true;
        });
    },

    puchase() {
      return this.$api.post('trial/purchase', { length: this.membership, poNum: this.poNum }).then(() => {
        this.success = 1;
      });
    },

    end() {
      return this.$api.post('trial/cancel', { reason: this.reason.title, reason2: this.reason2 }).then(() => {
        this.success = 2;
      });
    },

    close() {
      this.setTrialModal(0);
      this.failure = false;
      this.ready = true;
      this.success = 0;
    },

    ...mapMutations(['setTrialModal']),
  },
};
</script>

<template>
  <AdminModal :active="!!showTrialModal" :title="title" @close="close">
    <AdminSpinner :ready="ready">
      <div v-if="success === 1" class="prose">
        <h3>Thank you for your order.</h3>
        <p>
          Our team will process your order as quickly as possible, and you will be emailed confirmation and instructions
          when this has been completed. Please note that our core hours are Monday-Friday 9am-5pm; orders received
          outside of these hours will be processed on the next working day.
        </p>
        <p>
          If you have any queries in the meantime, please use our Live Chat, email
          <a href="mailto:info@realPE.co.uk">info@realPE.co.uk</a> or call
          <a href="tel:0208 863 0304">0208 863 0304</a>.
        </p>
        <p>We're looking forward to supporting you to make a proven impact for EVERY child.</p>
      </div>

      <template v-else-if="success === 2">
        <p class="text-xl mb-4">Your <strong>real PE</strong> trial has now ended.</p>
        <p>
          We appreciate you taking the time to experience what
          <strong>real PE</strong> has to offer. Keep checking our website for FREE professional learning and if you'd
          like to restart your trial, please contact us.
        </p>
      </template>

      <template v-else-if="failure"> There was a problem processing your request. </template>

      <form v-else-if="showTrialModal === 1" @submit.prevent="submit">
        <FormControl slug="membership" label="Membership length">
          <div v-for="licence in licences" :key="licence.title" class="">
            <label
              :class="{ 'bg-pe-shade': membership === licence.id }"
              class="flex items-center mb-3 outlne rounded px-6 py-4 border border-grey-l1 cursor-pointer"
            >
              <div class="font-medium text-lg w-18">{{ licence.title }}</div>
              <div class="text-pe text-xl font-medium w-18">{{ licence.price }}</div>
              <div v-if="licence.save">saving {{ licence.save }}</div>
              <input type="radio" v-model="membership" :value="licence.id" class="ml-auto" />
            </label>
          </div>
        </FormControl>

        <FormControl slug="poNum" label="PO Number (optional)" class="mt-5">
          <input type="text" id="poNum" v-model.trim="poNum" />
        </FormControl>

        <div class="note note-info mt-6">
          <svg class="note-icon"><use xlink:href="#info" /></svg>
          <p class="">
            Clicking 'Purchase now' will complete the purchase of your membership and trigger an invoice which will be
            emailed to your finance contact.
          </p>
        </div>

        <div class="btns4 mt-6">
          <div class="flex-grow"></div>
          <button class="btn4-clear" type="button" @click="close">Cancel</button>
          <button class="btn4 bg-theme" type="submit">Purchase Now</button>
        </div>
      </form>

      <form v-else @submit.prevent="submit">
        <FormControl slug="reason" label="Reason">
          <select id="current1" v-model="reason">
            <option v-for="type1 in reasons" :key="type1.title" :value="type1">
              {{ type1.title }}
            </option>
          </select>
        </FormControl>

        <FormControl v-if="reason.showOther" slug="reason2" label="Other Reason">
          <input v-model="reason2" type="text" />
        </FormControl>

        <!-- TODO: other text Lost_Reason_Other_Provide_details -->

        <div class="btns4 mt-6">
          <!-- <button v-if="licence.id" class="btn4" type="button" @click="showDelete = true">Delete</button> -->
          <div class="flex-grow"></div>
          <button class="btn4-clear" type="button" @click="close">Cancel</button>
          <button class="btn4 bg-theme" type="submit">End Now</button>
        </div>
      </form>
    </AdminSpinner>
  </AdminModal>
</template>

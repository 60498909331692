<script>
export default {
  props: { vid: String },

  computed: {
    src() {
      const [match, vid, hash] = (this.vid || '').match(/(\d+)(?:\/|\?h=)?([0-9a-z]+)?/) || [];
      const params = new URLSearchParams({}); // TODO: add default params?
      if (hash) params.append('h', hash);

      return `https://player.vimeo.com/video/${vid}?${params.toString()}`;
    },
  },
};
</script>

<template>
  <div v-if="vid" class="vimeo">
    <iframe :src="src" allow="autoplay; fullscreen; picture-in-picture" frameborder="0"></iframe>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: ['id', 'level', 'message', 'status', 'list', 'label'],

  methods: {
    time(mili) {
      this.timer = setTimeout(() => {
        this.binToast(this.id);
      }, mili);
    },

    over() {
      clearTimeout(this.timer);
    },
    out() {
      this.time(2000);
    },

    ...mapMutations('toast', ['binToast']),
  },

  created() {
    this.time(5000);
  },
};
</script>

<template>
  <div
    class="toast card shadow-lg my-1 flex justify-between"
    :class="`toast-${level}`"
    :id="`toast${id}`"
    @mouseenter="over()"
    @mouseleave="out()"
  >
    <div class="text-black self-center">
      <div v-html="message"></div>

      <ul v-if="list && list.length" class="mb-0 italic">
        <li v-for="item in list" :key="item" v-html="item.message" class="list-none"></li>
      </ul>
    </div>
    <button class="close-btn p-3 -mt-1 -mr-2 -mb-1 rounded self-start" @click="binToast(id)">
      <svg class="w-2 h-2 fill-blank"><use xlink:href="#cross" /></svg>
    </button>
  </div>
</template>

<style lang="postcss">
.toast.card {
  @apply text-pe;
  min-height: 50px;
  pointer-events: initial;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.5);

  &.toast-error {
    @apply text-fun-red bg-fun-red-shade;
  }

  &.toast-warn {
    @apply text-tricky;
  }
}
</style>

<script>
import routeInfo from '@/mixins/routeInfo';
import Loader from '@/global/components/Loader';
import Disc from '@/content/components/partials/Disc';
import DiceModal from '@/content/components/modal/DiceModal';
import AudioPlayer from '@/content/components/partials/AudioPlayer';
import query from '@/queries/home/stage.gql';

export default {
  components: { Disc, Loader, DiceModal, AudioPlayer },
  mixins: [routeInfo],
  data: () => ({ menu: {}, selected: {} }),

  metaInfo() {
    return { title: `${this.productInfo.title} ${this.menu.title || ''}` };
  },

  computed: {
    stage() {
      return this.$route.params.stage;
    },

    items() {
      return (this.menu.items || []).map(item => ({
        ...(item.homeItem[0] || {}),
        uri: this.stage === 'funs' ? `${item.uri}/skill/${item.slug}` : item.uri,
        audioFile: item.audioFile[0],
      }));
    },

    groups() {
      return [
        { items: this.items.slice(0, 6), alt: 'even:translate-y-1/2', styles: 'order-1' },
        { items: this.items.slice(6), alt: 'odd:translate-y-1/2', styles: 'order-3 justify-end' },
      ];
    },
  },

  methods: {
    diceGo({ to }) {
      const item = this.items.find(item => item.slug === to);
      if (item && item.uri) setTimeout(() => this.$router.push(item.uri), 1000);
    },

    select(link) {
      this.selected = link;
      const url = `${this.$store.state.s3}animation/${link.slug}`;
      if (this.stage === 'funs' && this.$refs.anim_player) this.$refs.anim_player.src = `${url}.mp4`;
      else if (this.$refs.iframe) this.$refs.iframe.contentWindow.location.replace(`${url}/index.html`);
    },

    fetch() {
      this.$gql(query, { product: this.productSlug, uri: this.$route.path }).then(res => {
        this.menu = res.menu;
        this.$store.dispatch('user/last', { title: this.menu.title, type: 'stage' });
      });
    },
  },

  watch: { $route: 'fetch' },

  created() {
    this.fetch();
  },
};
</script>

<template>
  <Loader :res="items.length">
    <div class="container">
      <div class="bg-white curves">
        <div class="box-contain">
          <h1 class="box-title capitalize" :class="`text-${productSlug}`">{{ menu.title }}</h1>
          <div class="box-buttons">
            <AudioPlayer v-if="selected.audioFile" :audio="selected.audioFile" autoplay />
            <DiceModal v-if="stage === 'themes'" :dice="[{ slug: 'theme' }]" @roll="diceGo" />
          </div>
        </div>

        <div class="flex px-4 pb-32 pt-2 justify-between">
          <div v-for="(group, i) in groups" :key="i" :class="group.styles" class="flex flex-wrap w-1/3">
            <RouterLink v-for="item in group.items" :key="item.slug" :to="item.uri" :class="group.alt" class="mb-3">
              <Disc
                :asset="item.previewImage"
                :colour="productSlug"
                class="w-24 lg:w-36 xl:w-40 hover:border-pe"
                @over="select(item)"
              />
            </RouterLink>
          </div>
          <div class="relative w-1/3 text-center order-2">
            <video v-if="stage === 'funs'" ref="anim_player" class="theme-anim" preload="auto" autoplay muted loop />
            <iframe v-else scrolling="no" ref="iframe" class="theme-anim"></iframe>
            <h1 :class="`text-${productSlug}`" class="absolute -bottom-10 inset-x-0">{{ selected.title }}</h1>
          </div>
        </div>
      </div>
    </div>
  </Loader>
</template>

<style lang="postcss" scoped>
.theme-anim {
  @apply absolute left-1/2 top-1/2 border-none -translate-x-1/2 -translate-y-1/2;
  @apply scale-75 lg:scale-90 xl:scale-100;
  width: 360px;
  height: 400px;
}
</style>

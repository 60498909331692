<script>
import '@/assets/svgs/icons/mail.svg';

export default {
  props: ['id', 'kind', 'email', 'firstName', 'lastName', 'name', 'invited', 'priority', 'verified', 'uid'],

  computed: {
    icon() {
      if (this.kind === 'parent') return 'tick';
      if (!this.email) return 'warn';
      if (this.invited) return 'mail';
    },
    iconColour() {
      if (!this.email) return 'text-fun-red';
      return 'text-bursts';
    },
    border() {
      if (this.kind === 'parent') return 'text-bursts';
      if (!this.email) return 'text-fun-red-shade';
      return 'text-bursts-shade';
    },
  },
};
</script>

<template>
  <button :class="border" class="card flex justify-between items-center outlne" @click="$emit('click', $props)">
    <div>
      <div class="font-semibold text-black">{{ name || '-' }}</div>
      <div v-if="email" class="text-black text-sm">{{ email }}</div>
      <div v-else class="italic text-fun-red text-sm">Missing Email</div>
    </div>
    <div :class="iconColour">
      <svg class="w-5 h-5 fill-current"><use :xlink:href="`#${icon}`" /></svg>
    </div>
  </button>
</template>

<script>
import SkillLevels from '@/content/components/partials/SkillLevels';
import TextBlock from '@/content/components/partials/TextBlock';
import Disc from '@/content/components/partials/Disc';
import Link from '@/global/components/Link';
import routeInfo from '@/mixins/routeInfo';

export default {
  components: { Disc, TextBlock, Link, SkillLevels },
  mixins: [routeInfo],
  props: ['unit', 'focus'],

  computed: {
    colour() {
      return this.isDance || this.isGym ? 'dance' : 'physical';
    },
    funs() {
      return this.unit.funsStations || [];
    },
    themes() {
      if (this.isGym) return [...this.unit.gymFocus, ...this.unit.gymTheme];
      if (this.isDance) return this.unit.danceFocus || this.focus || [];
      return this.unit.themes || [];
    },
  },
};
</script>

<template>
  <div class="pb-2 t-col">
    <Link
      :uri="unit.uri"
      :class="`border-${colour}`"
      class="txt-block2 bg-white flex flex-col justify-center print:block"
    >
      <div class="md:-mx-2 lg:-mx-5 print:flex justify-center">
        <div v-if="funs.length" class="w-full md:flex text-center mb-3 text-sm">
          <div v-for="item in funs" :key="item.id" class="md:w-1/2 px-1">
            <Disc :asset="item.previewImage" :colour="colour" class="w-18 mb-1 print:hidden" />
            <div v-if="item.category" class="font-bold text-physical">{{ item.category }}</div>
            <div class="font-medium">{{ item.title }}</div>
          </div>
        </div>
        <div v-if="themes.length" class="w-full md:flex text-center mb-2 text-sm print:flex-wrap">
          <div v-for="item in themes" :key="item.title" class="md:w-1/2 px-1">
            <Disc :asset="item.previewImage" :colour="colour" class="w-18 mb-1 print:hidden" />
            <div class="font-medium">{{ item.title }}</div>
          </div>
        </div>
      </div>

      <div v-if="unit.funsText" v-html="unit.funsText" class="text-lg text-center my-2"></div>

      <SkillLevels :unit="unit" />
    </Link>
  </div>
</template>

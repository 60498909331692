<script>
import '@/assets/svgs/icons/info.svg';
import '@/assets/svgs/icons/tick.svg';
import '@/assets/svgs/icons/warn.svg';
import '@/assets/svgs/icons/ring-add.svg';

export default {
  props: ['icon', 'chip'],

  computed: {
    element0() {
      return this.$listeners.click ? 'button' : 'div';
    },
    element1() {
      return this.$listeners.click1 ? 'button' : 'div';
    },
    element2() {
      return this.$listeners.click2 ? 'button' : 'div';
    },
    outline1() {
      return this.$listeners.click1 || this.$listeners.click;
    },
    outline2() {
      return this.$listeners.click2 || this.$listeners.click;
    },
  },
};
</script>

<template>
  <component :is="element0" :class="chip" class="flex w-full items-stretch" @click="$emit('click')">
    <component
      :is="element1"
      :class="{ outlne: outline1 }"
      class="p-2 px-3 text-left bg-white rounded-l overflow-hidden shrink grow border-l-6 shadow-sm"
      @click="$emit('click1')"
    >
      <slot></slot>
    </component>
    <component
      :is="element2"
      :class="{ outlne: outline2 }"
      class="bg-white rounded-r-lg ml-0.5 shadow-sm"
      @click="$emit('click2')"
    >
      <div class="relative flex items-center h-full px-2 info-target">
        <div v-if="icon === 'spin'" class="mini-spinner spin08 pe"></div>
        <svg v-else-if="icon" class="w-5 h-5 fill-pe"><use :xlink:href="`#${icon}`" /></svg>
        <div v-if="$slots.info" class="info-popup p-2 px-3 text-black text-left"><slot name="info"></slot></div>
      </div>
    </component>
  </component>
</template>

<script>
const rand = sides => Math.floor(Math.random() * sides);

export default {
  props: ['die'],
  data: () => ({ rolling: false, rolled: false, current: 0 }),

  computed: {
    overlay() {
      return this.die.slug;
    },
    side() {
      return this.die.sides[this.current] || { image: {} };
    },
    sideTitle() {
      if (this.rolling) return '???';
      return (this.rolled ? this.side.sideTitle : false) || `${this.die.title} Spinner`;
    },
  },

  methods: {
    roll() {
      if (this.rolling) return false;
      this.$refs.sfx.currentTime = 0;
      this.$refs.sfx.play();
      this.rolling = true;

      setTimeout(() => {
        this.rolling = false;
        this.rolled = true;
        this.current = rand(this.die.sides.length);
        // this.$emit('roll', this.side);
      }, 3500);
    },
  },

  created() {
    this.current = rand(this.die.sides.length);
    // this.roll();
  },

  mounted() {
    this.$refs.sfx.volume = 0.5;
  },
};
</script>

<template>
  <div @click="roll">
    <div class="roulette-ratio">
      <div class="roulette-inner">
        <transition name="roulette-spin">
          <div v-if="rolling" class="border-8 spinner" :class="{ lucky: die.slug === 'lucky-dip' }" key="spinner"></div>
          <div v-else class="border-8 roulette-cover" key="cover">
            <img v-for="img in side.image" :key="img.id" :src="img.url" alt="" draggable="false" />
          </div>
        </transition>
      </div>
      <div class="roulette-outer">
        <img :src="`/images/roulettes/${overlay}.svg`" />
      </div>
    </div>

    <div class="roulette-title">{{ sideTitle }}</div>
    <audio ref="sfx" src="/sounds/spinner_fx.mp3" preload="auto" />
  </div>
</template>

<style lang="postcss" scoped>
.roulette-ratio {
  width: 100%;
  padding-top: 100%;
  position: relative;
  cursor: pointer;
  overflow: visible;
}

.roulette-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;
}

.roulette-outer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 115%;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.spinner,
.roulette-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  border-radius: 9999px;
}

.spinner {
  transition: transform 3s cubic-bezier(0, 0.55, 0.45, 1);
  transform: rotate(360deg);
  border-color: rgb(235, 235, 235);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(@/assets/images/roulette_spin.svg);
}

.lucky {
  background-image: url(@/assets/images/lucky_spin.svg) !important;
}

.roulette-cover {
  border-color: rgb(235, 235, 235);

  img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 9999px;
    transition: opacity 0.4s ease-out;
    background-color: white;
  }
}

.roulette-roll {
  width: 100%;
  height: 100%;
}

.roulette-title {
  font-size: 26px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
  cursor: pointer;
}

.roulette-spin-leave-to {
  img {
    opacity: 0;
  }
}

.roulette-spin-leave-active {
  img {
    opacity: 0;
  }
}

.roulette-spin-enter {
  transform: rotate(-360deg);

  img {
    opacity: 0;
  }
}
</style>

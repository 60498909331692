<script>
import '@/assets/svgs/dashboard/online-learning.svg';
import '@/assets/svgs/dashboard/community.svg';
import '@/assets/svgs/dashboard/in-school-support.svg';
import '@/assets/svgs/dashboard/member-stories.svg';
import '@/assets/svgs/dashboard/micro-learns.svg';
import '@/assets/svgs/dashboard/resources.svg';
import '@/assets/svgs/dashboard/podcasts.svg';
import '@/assets/svgs/dashboard/news-events.svg';
import { mapGetters } from 'vuex';

export default {
  metaInfo: { title: 'Learning Hub' },
  computed: mapGetters(['forumUrl', 'lmsUrl', 'pfwUrl']),
};
</script>

<template>
  <main>
    <div class="container my-10">
      <div class="mb-10 flex justify-between">
        <div>
          <h1 class="head-4xl leading-none font-bliss mb-2 text-learning">Learning Hub</h1>
          <p>Our hub provides the professional learning and resources you need to develop confidence and expertise.</p>
        </div>
        <img
          src="https://realpe.co.uk/assets/public/_400xAUTO_crop_center-center_none/275973/teach-primary-2023-winner.webp"
          alt=""
          class="w-32 -my-6"
        />
      </div>
      <div class="flex flex-wrap -mx-2">
        <div id="learning-menu-online" class="w-1/3 lg:w-1/4 px-2 pb-4">
          <a :href="lmsUrl" target="_blank" class="bg-white rounded-xl p-5 h-full shadow block outlne">
            <div class="flex justify-between">
              <div class="text-lg font-bold mb-2 text-learning leading-tight mt-1">
                Online Courses
                <!-- & Classrooms -->
                <div class="italic text-blank">(My Learning)</div>
              </div>

              <svg class="w-6 h-6 ml-2 fill-learning shrink-0"><use xlink:href="#online-learning" /></svg>
            </div>

            <p>Access on demand and real time CPD opportunities</p>
          </a>
        </div>
        <!--  -->
        <div id="learning-menu-community" class="w-1/3 lg:w-1/4 px-2 pb-4">
          <a :href="forumUrl" target="_blank" class="bg-white rounded-xl p-5 h-full shadow block outlne">
            <div class="flex justify-between">
              <div class="text-lg font-bold mb-2 text-learning leading-tight mt-1">Community</div>
              <svg class="w-6 h-6 ml-2 fill-learning shrink-0"><use xlink:href="#community" /></svg>
            </div>

            <p>Interact with and learn from other real PE members</p>
          </a>
        </div>
        <!--  -->
        <div id="learning-menu-support" class="w-1/3 lg:w-1/4 px-2 pb-4">
          <a
            :href="`${pfwUrl}in-person-training`"
            target="_blank"
            class="bg-white rounded-xl p-5 h-full shadow block outlne"
          >
            <div class="flex justify-between">
              <div class="text-lg font-bold mb-2 text-learning leading-tight mt-1">In School Support</div>
              <svg class="w-6 h-6 ml-2 fill-learning shrink-0"><use xlink:href="#in-school-support" /></svg>
            </div>

            <p>Book training and support from Delivery Specialists</p>
          </a>
        </div>
        <!--  -->
        <div id="learning-menu-stories" class="w-1/3 lg:w-1/4 px-2 pb-4">
          <a :href="`${pfwUrl}impact`" target="_blank" class="bg-white rounded-xl p-5 h-full shadow block outlne">
            <div class="flex justify-between">
              <div class="text-lg font-bold mb-2 text-learning leading-tight mt-1">Member Stories</div>
              <svg class="w-6 h-6 ml-2 fill-learning shrink-0"><use xlink:href="#member-stories" /></svg>
            </div>

            <p>Enjoy impact stories from real PE members</p>
          </a>
        </div>
        <!--  -->
        <div id="learning-menu-micro" class="w-1/3 lg:w-1/4 px-2 pb-4">
          <RouterLink to="/learning/micro" class="bg-white rounded-xl p-5 h-full shadow block outlne">
            <div class="flex justify-between">
              <div class="text-lg font-bold mb-2 text-learning leading-tight mt-1">
                Micro Learns
                <!-- <div class="italic text-blank">Coming Soon</div> -->
              </div>
              <svg class="w-6 h-6 ml-2 fill-learning shrink-0"><use xlink:href="#micro-learns" /></svg>
            </div>

            <p>Delve into bitesize learning opportunities</p>
          </RouterLink>
        </div>
        <!--  -->
        <div id="learning-menu-resources" class="w-1/3 lg:w-1/4 px-2 pb-4">
          <div class="bg-white rounded-xl p-5 h-full shadow">
            <div class="flex justify-between">
              <div class="text-lg font-bold mb-2 text-learning leading-tight mt-1">
                Resources
                <div class="italic text-blank">Coming Soon</div>
              </div>
              <svg class="w-6 h-6 ml-2 fill-learning shrink-0"><use xlink:href="#resources" /></svg>
            </div>

            <p>Discover key documents, articles and webinar recordings</p>
          </div>
        </div>
        <!--  -->
        <div id="learning-menu-podcasts" class="w-1/3 lg:w-1/4 px-2 pb-4">
          <div class="bg-white rounded-xl p-5 h-full shadow">
            <div class="flex justify-between">
              <div class="text-lg font-bold mb-2 text-learning leading-tight mt-1">
                Podcasts & Blogs
                <div class="italic text-blank">Coming Soon</div>
              </div>
              <svg class="w-6 h-6 ml-2 fill-learning shrink-0"><use xlink:href="#podcasts" /></svg>
            </div>

            <p>Hear from PE experts</p>
          </div>
        </div>
        <!--  -->
        <div id="learning-menu-news" class="w-1/3 lg:w-1/4 px-2 pb-4">
          <div class="bg-white rounded-xl p-5 h-full shadow">
            <div class="flex justify-between">
              <div class="text-lg font-bold mb-2 text-learning leading-tight mt-1">
                News & Events
                <div class="italic text-blank">Coming Soon</div>
              </div>
              <svg class="w-6 h-6 ml-2 fill-learning shrink-0"><use xlink:href="#news-events" /></svg>
            </div>

            <p>Explore hot topics, webinars, conferences and more</p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

import yearPe from '@/queries/pe/year.gql';
import yearGym from '@/queries/gym/year.gql';
import yearPlay from '@/queries/play/year.gql';
import yearDance from '@/queries/dance/year.gql';

import unitPe from '@/queries/pe/unit.gql';
import unitGym from '@/queries/gym/unit.gql';
import unitPlay from '@/queries/play/unit.gql';
import unitDance from '@/queries/dance/unit.gql';

import lessonPe from '@/queries/pe/lesson.gql';
import lessonGym from '@/queries/gym/lesson.gql';
import lessonPlay from '@/queries/play/session.gql';
import lessonDance from '@/queries/dance/lesson.gql';

import foundations from '@/queries/home/_foundations.gql';

const yearQueries = { pe: yearPe, gym: yearGym, play: yearPlay, dance: yearDance };
const unitQueries = { pe: unitPe, gym: unitGym, play: unitPlay, dance: unitDance };
const lessonQueries = { pe: lessonPe, gym: lessonGym, play: lessonPlay, dance: lessonDance };
const partials = { foundations };

export { yearQueries, unitQueries, lessonQueries, partials };

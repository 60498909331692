<script>
export default {
  props: { collection: { default: () => ({}) } },

  computed: {
    links() {
      return this.collection.link || {};
    },

    first() {
      return this.links.first && this.links.self && this.links.first.page !== this.links.self.page;
    },
    last() {
      return this.links.last && this.links.self && this.links.last.page !== this.links.self.page;
    },
    ends() {
      return this.links.first && this.links.last;
    },

    hide() {
      return this.collection.totalPages < 2;
    },
  },

  methods: {
    change(link) {
      this.$emit('change', link);
    },
  },
};
</script>

<template>
  <div class="flex items-center justify-between btns4" v-if="!hide">
    <div>
      <button v-if="ends" :disabled="!first" class="btn4" @click="change(links.first)">First</button>
      <button :disabled="!links.prev" class="btn4 bg-theme" @click="change(links.prev)">Prev</button>
    </div>
    <div>
      {{ collection.currentPage }}
      <span v-if="collection.totalPages">/ {{ collection.totalPages }}</span>
    </div>
    <div>
      <button :disabled="!links.next" class="btn4 bg-theme" @click="change(links.next)">Next</button>
      <button v-if="ends" :disabled="!last" class="btn4" @click="change(links.last)">Last</button>
    </div>
  </div>
</template>

<script>
import HaloButton from '@/content/components/buttons/HaloButton';
import '@/assets/svgs/buttons/assessment.svg';

export default {
  components: { HaloButton },
  props: ['lesson', 'button', 'mode'],

  computed: {
    years() {
      const y = parseInt(this.$route.params.year, 10);

      if (y > 4) {
        return 'y5-y6';
      } else if (y > 2) {
        return 'y3-y4';
      }

      return 'y0-y1-y2';
    },

    url() {
      const s3 = `${this.$store.state.s3}static/`;

      if (this.$route.params.product === 'play') {
        return `${s3}real-play-Impact-doc-2019.pdf`;
      } else if (this.mode === 'funs') {
        return `${s3}funs-assessment-${this.years}.pdf`;
      } else if (this.mode === 'overview') {
        return `${s3}assessment-${this.years}.pdf`;
      } else if (this.mode === 'game') {
        return `${s3}personal-best-unit-${this.$route.params.unit}-${this.years}.pdf`;
      }

      return '';
    },

    product() {
      return this.$route.params.product;
    },
  },
};
</script>

<template>
  <HaloButton icon="assessment" label="Assessment" :uri="url" />
</template>

<script>
import FormControl from '@/admin/components/FormControl';
import AdminSpinner from '@/admin/components/AdminSpinner';
import AdminModal from '@/admin/components/AdminModal';
import DebugLine from '@/admin/components/DebugLine';
import { $user } from '@/scripts/models';
import { validationMixin } from 'vuelidate';
import { required, email, sameAs, helpers } from 'vuelidate/lib/validators';
import { mapState, mapGetters } from 'vuex';
import jwt from '@/scripts/jwt';

const capital = helpers.regex('alpha', /[A-Z]+/);
const number = helpers.regex('alpha', /[0-9]+/);
const character = helpers.regex('alpha', /[!?*)(&%@£]+/);
const doVal = (test, extra) => (test ? { required, ...extra } : {});

export default {
  mixins: [validationMixin],
  components: { FormControl, AdminSpinner, AdminModal, DebugLine },
  props: { value: Object, kind: String, isProfile: Boolean, register: Boolean, reset: Boolean },
  data: () => ({ user: $user.one(), deleteModal: false, password: '', password2: '' }),

  computed: {
    showName() {
      return !this.userIsWonde && !this.reset && (this.isActive || this.isHome || this.register);
    },
    showEmail() {
      return !this.userIsWonde && !this.reset && !this.isHome;
    },
    showPassword() {
      // return (this.isActive || this.register || this.reset) && !this.isHome;
      return !this.isHome;
    },
    showAdmin() {
      return !this.isSelf && !this.register && !this.reset && !this.isHome;
    },
    showInvite() {
      return this.isAdmin && !this.isActive && !this.isSelf && !this.isHome;
    },
    showCancel() {
      return !this.isProfile && !this.register && !this.reset;
    },

    isSelf() {
      return this.user.id === this.authId;
    },
    isActive() {
      return this.user && this.user.status > 1;
    },
    isNarrow() {
      return this.register || this.reset;
    },
    isHome() {
      return this.user.kind === 'home';
    },

    userInAccount() {
      return this.user.kind === 'account';
    },
    userIsWonde() {
      return !!this.user.wondeId;
    },
    userIsTemp() {
      return this.userInAccount && this.accountHasWonde && !this.userIsWonde;
    },

    forceAdmin() {
      return !!(this.user.leadPe || this.user.leadBursts) || this.user.admin === 2;
    },

    ...mapState('user', { authId: 'id' }),
    ...mapGetters('user', ['isAdmin', 'isCdAdmin']),
    ...mapGetters('account', ['isPartner', 'accountHasWonde']),
  },

  validations() {
    return {
      user: {
        ...(this.showName && { firstName: { required }, lastName: { required } }),
        ...(this.showEmail && { email: { required, email } }),
        ...(this.isHome && { username: {} }),
      },
      password: { ...((this.register || this.reset) && { required }), capital, number, character },
      password2: { sameAs: sameAs('password') },
    };
  },

  methods: {
    updateUser() {
      this.$v.$touch();

      if (this.user.$ready && !this.$v.$invalid) {
        if (this.password) this.user.password = this.password;

        this.user.$update().then(res => {
          this.$emit('update', true);
        });
      }
    },

    deleteUser() {
      if (this.user.$ready)
        this.user.$delete().then(res => {
          this.$emit('update', true);
        });
    },

    reinvite() {
      if (this.user.$ready)
        this.user.$invite().then(res => {
          this.$store.commit('toast/info', 'User invite resent');
        });
    },

    newPass() {
      if (this.user.$ready)
        this.$api.get('users/home/new-password').then(res => {
          this.user.password = res.data;
        });
    },

    leadCheck() {
      if (this.user.leadPe || this.user.leadBursts) {
        this.user.admin = 1;
      }
    },

    faux() {
      this.$api.get('auth/faux', { params: { uid: this.user.uid } }).then(res => {
        jwt.set(res.data);
        location.reload();
      });
    },
  },

  mounted() {
    if (this.value && this.value.id) this.user.$load(this.value);
  },
};
</script>

<template>
  <div>
    <AdminSpinner :res="user">
      <form @submit.prevent="updateUser()">
        <template v-if="userIsWonde">
          <div class="text-3xl">{{ user.firstName }} {{ user.lastName }}</div>
          <div class="italic mb-3">{{ user.email }}</div>

          <div v-for="role in user.roles" :key="role">{{ role }}</div>
          <hr />
        </template>

        <div v-if="showName" class="flex -mx-3">
          <div class="w-1/2 px-3">
            <FormControl :v="$v.user" slug="firstName" label="First Name">
              <input type="text" id="firstName" v-model.trim="$v.user.firstName.$model" />
            </FormControl>
          </div>
          <div class="w-1/2 px-3">
            <FormControl :v="$v.user" slug="lastName" label="Last Name">
              <input type="text" id="lastName" v-model.trim="$v.user.lastName.$model" />
            </FormControl>
          </div>
        </div>

        <template v-if="showEmail">
          <FormControl :v="$v.user" slug="email" label="Email">
            <input type="email" id="email" v-model.trim="$v.user.email.$model" />
          </FormControl>
          <hr />
        </template>

        <template v-if="userIsTemp && isCdAdmin">
          <label class="option-desc">
            <input type="checkbox" :true-value="1" :false-value="0" v-model="user.allowTemp" @change="leadCheck" />
            <div>Approved Non-MIS User</div>
          </label>
          <hr />
        </template>

        <template v-if="isHome">
          <FormControl :model="$v.user.username" id="username" label="Username">
            <input type="text" id="username" v-model.trim="$v.user.username.$model" />
          </FormControl>

          <FormControl v-if="!isProfile" id="group" label="Group">
            <input type="text" id="group" v-model.trim="user.group" />
          </FormControl>

          <label class="font-bold">Password</label>
          <div class="flex items-center p-3 border-2 border-dashed pp-border curves-sm border-grey-l2">
            {{ user.password }}
            <button type="button" class="ml-auto btn4 -m-1" @click="newPass">Request New</button>
          </div>
        </template>

        <template v-if="userInAccount && isAdmin">
          <div class="flex -mx-3">
            <div class="w-1/2 px-3">
              <label class="option-desc">
                <input type="checkbox" :true-value="1" :false-value="0" v-model="user.leadPe" @change="leadCheck" />
                <div>PE Lead</div>
              </label>
            </div>
            <div class="w-1/2 px-3">
              <label class="option-desc">
                <input type="checkbox" :true-value="1" :false-value="0" v-model="user.leadBursts" @change="leadCheck" />
                <div>BURSTS Lead</div>
              </label>
            </div>
          </div>
          <hr />
        </template>

        <template v-if="showAdmin">
          <div class="flex -mx-3">
            <div class="w-1/2 px-3">
              <label :class="{ disabled: forceAdmin }" class="option-desc">
                <input type="radio" name="admin" :value="1" v-model="user.admin" :disabled="forceAdmin" />
                <div>
                  Admin
                  <div v-if="kind === 'create'" class="desc">Can manage accounts</div>
                  <div v-else class="desc">Can manage account users</div>
                </div>
              </label>
            </div>
            <div class="w-1/2 px-3">
              <label class="option-desc" :class="{ disabled: forceAdmin }">
                <input type="radio" name="admin" :value="0" v-model="user.admin" :disabled="forceAdmin" />
                <div>
                  User
                  <div v-if="kind === 'create'" class="desc">Can view accounts</div>
                  <div v-else class="desc">Can only update own information</div>
                </div>
              </label>
            </div>
          </div>
          <hr />
        </template>

        <template v-if="showInvite">
          <div class="flex justify-between items-center my-4">
            <div>Send another invite email</div>
            <button class="btn4" type="button" @click="reinvite">Re-Invite</button>
          </div>
          <hr />
        </template>

        <div v-if="register || reset" class="my-6">
          <!-- <hr /> -->
          <p class="mb-2">Please choose a password. It should contain at least:</p>
          <ul class="pl-5">
            <li>One capital letter</li>
            <li>One number</li>
            <li>
              One character from
              <strong>!?*)(&%@£</strong>
            </li>
          </ul>
        </div>

        <div v-if="showPassword" :class="{ flex: !isNarrow }" class="-mx-3">
          <div class="w-full px-3">
            <FormControl :v="$v" slug="password" label="New Password">
              <input type="password" id="password" v-model.trim="$v.password.$model" />
              <template slot="errors">
                <div v-if="!$v.password.capital">Add a capital letter</div>
                <div v-else-if="!$v.password.number">Add a number</div>
                <div v-else-if="!$v.password.character">
                  Add a character from
                  <strong>!?*)(&%@£</strong>
                </div>
              </template>
            </FormControl>
          </div>

          <div class="w-full px-3">
            <FormControl :v="$v" slug="password2" label="Confirm Password">
              <input type="password" id="password2" v-model.trim.lazy="$v.password2.$model" />
            </FormControl>
          </div>
        </div>

        <div class="btns4 mt-6">
          <button v-if="isAdmin && !isSelf" @click="deleteModal = true" class="btn4" type="button">Delete</button>
          <div class="flex-grow"></div>
          <button v-if="showCancel" type="button" class="btn4-clear" @click="$emit('close')">Cancel</button>
          <button class="btn4 bg-theme" type="submit">Save</button>
        </div>
      </form>
    </AdminSpinner>

    <AdminModal :active="deleteModal" title="Delete User?" inline @close="deleteModal = false">
      <div class="btns4">
        <button class="btn4-clear" @click="deleteModal = false">Cancel</button>
        <button class="btn4 bg-fun-red" @click="deleteUser()">Delete User</button>
      </div>
    </AdminModal>

    <DebugLine type="user" :object="value">
      <button v-if="user.uid" class="btn4-clear" @click="faux">Faux</button>
    </DebugLine>
  </div>
</template>

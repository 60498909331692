<script>
import '@/assets/svgs/icons/cross.svg';

export default {
  props: ['items', 'value', 'id'],
  data: () => ({ textVal: '', index: 0 }),

  computed: {
    string() {
      return this.text.toLowerCase();
    },
    wrapped() {
      return (this.items || []).map((item, i) => ({ ...item, $val: item.name.toLowerCase(), $id: i }));
    },
    filtered() {
      return this.wrapped.filter(item => item.$val.includes(this.string)).slice(0, 10);
    },

    text: {
      get() {
        return this.value ? this.value.name : this.textVal;
      },
      set(newValue) {
        this.textVal = newValue;
        this.index = 0;
        if (this.value) this.$emit('input', '');
      },
    },
  },

  methods: {
    select(index = null) {
      this.$emit('input', this.filtered[index ?? this.index]);
    },
    clear() {
      if (this.value) this.$emit('input', '');
      this.text = '';
    },
    up() {
      if (this.index > 0) this.index = this.index - 1;
    },
    down() {
      if (this.index < this.filtered.length - 1) this.index = this.index + 1;
    },
    focus(event) {
      if (this.value) event.target.select();
    },
    blur() {
      setTimeout(() => {
        if (!this.value && this.textVal) this.text = '';
      }, 200);
    },
  },

  watch: {
    value(val) {
      if (!val) this.textVal = '';
    },
  },
};
</script>

<template>
  <div @keydown.esc="text = ''" @keydown.tab="select()" @keydown.enter.prevent="select()">
    <div class="relative" @keydown.up.prevent="up" @keydown.down.prevent="down">
      <div class="h-full absolute top-0 right-0 p-1">
        <button v-if="value" class="w-8 h-full" type="button" @click="clear">
          <svg class="w-2 h-2 mx-auto fill-blank"><use xlink:href="#cross" /></svg>
        </button>
      </div>
      <input type="text" v-model="text" :id="id" @focus="focus" @blur="blur" />
    </div>
    <div class="relative">
      <ul
        v-if="text && !value && filtered.length"
        class="absolute w-full top-0 mt-1 z-20 bg-white shadow py-3 rounded-br-xl"
      >
        <li
          v-for="(item, i) in filtered"
          :key="item.$id"
          :class="{ 'bg-pe-shade': index === i }"
          class="w-full text-left hover:bg-pe-shade cursor-pointer px-3 leading-relaxed list-none"
          @click="select(i)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="postcss" scoped></style>

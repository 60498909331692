<script>
export default {
  props: ['res'],
};
</script>

<template>
  <transition name="fade" mode="out-in">
    <div v-if="res" key="content"><slot></slot></div>
    <div v-else key="spinner" class="w-full pointer-events-none">
      <transition name="fade-slow" appear>
        <div class="relative m-auto my-32 w-64 h-64">
          <svg class="w-64 h-64 fill-grey-l2 spin3"><use xlink:href="#cog" /></svg>
          <div class="absolute inset-0 flex items-center justify-center">
            <div class="text-4xl font-black leading-none tracking-tight text-white">Loading</div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

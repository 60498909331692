<script>
import Disc from '@/content/components/partials/Disc';

export default {
  components: { Disc },
  props: { menu: Array, pupil: Boolean },
};
</script>

<template>
  <div :class="`text-${$route.params.product}`" class="flex text-lg font-bold text-center curves bg-white py-5">
    <div v-for="item in menu" :key="item.slug" :class="{ 'preview-border': !pupil }" class="w-1/6">
      <RouterLink :to="item.uri" class="hvr">
        <Disc :asset="item.previewImage" :class="pupil ? 'w-24 lg:w-36' : ''" class="mb-2 w-20 outlne" />
        <div class="hvr0">{{ item.title }}</div>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import Player from '@vimeo/player';
import fscreen from 'fscreen';
import '@/assets/svgs/icons/fullscreen.svg';

export default {
  props: {
    vid: String,
    size: String,
    contain: Boolean,
    autoplay: Boolean,
    lines: { type: Array, default: () => [] },
    colour: { type: String, default: 'white' },
  },

  mounted() {
    this.player = new Player(document.getElementById(this.playerId));

    this.player.on('seeked', data => {
      this.time = data.seconds;
    });

    this.player.on('cuepoint', cue => {
      this.time = cue.time;
    });

    this.player.ready().then(() => {
      this.addCuePoints();

      if (this.autoplay) {
        this.player.play();
      }
    });
  },

  beforeDestroy() {
    if (this.player) {
      this.player.unload();
    }
  },

  data: () => ({ player: null, time: 0 }),

  computed: {
    playerId() {
      return `vimeo-${this.vid}`;
    },
    lines$() {
      return this.lines || [];
    },
    line() {
      return [...this.lines$].reverse().find(line => this.time >= line.time) || {};
    },
    canFullscreen() {
      return fscreen.fullscreenEnabled && this.size !== 'small';
    },
    fg() {
      return this.colour === 'fun-yellow' ? 'black' : this.colour;
    },
    fsParam() {
      return this.lines$.length ? 'fullscreen=0&' : '';
    },
  },

  methods: {
    addCuePoints() {
      this.lines$.forEach(line => {
        this.player.addCuePoint(line.time);
      });
    },

    fullscreen() {
      if (!fscreen.fullscreenElement) {
        fscreen.requestFullscreen(this.$refs.vt);
      } else {
        fscreen.exitFullscreen();
      }
    },
  },
};
</script>

<template>
  <div class="vt-fullscreen print:hidden" ref="vt" :class="[`bg-${colour}-shade`, `vt-${size}`]">
    <div class="vt-contain" :class="[`text-${fg}`]">
      <div class="vt-ratio" :class="{ 'has-lines': lines$.length }">
        <iframe
          :src="`https://player.vimeo.com/video/${vid}?loop=1&${fsParam}share=0&title=0&byline=0&portrait=0`"
          :id="playerId"
          allow="autoplay; encrypted-media; fullscreen"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          frameborder="0"
          height="360"
          width="640"
        ></iframe>
      </div>
      <div class="vt-text-flex" v-if="lines$.length">
        <div class="v-spacer" v-if="canFullscreen"></div>
        <div v-html="line.text || '&nbsp;'" class="vt-text-line"></div>
        <div class="v-spacer" v-if="canFullscreen">
          <button class="vt-fs-btn" @click="fullscreen()">
            <svg :class="`text-${fg}`" class="fill-current"><use xlink:href="#fullscreen" /></svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.vt-fullscreen:fullscreen {
  .vt-contain {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: none;
  }

  .vt-text-flex {
    flex-shrink: 0;
    padding: 1px 15px;
    height: 11vw;
  }

  .vt-text-line {
    font-size: 4vw;
  }

  .vt-ratio {
    flex-grow: 1;
    height: auto;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    border: none;

    & iframe {
      flex-grow: 1;
      width: 100%;
      position: relative;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.vt-fullscreen {
  font-size: 10px;

  @screen lg {
    font-size: 13.3px;
  }
  @screen xl {
    font-size: 15px;
  }
}

.vt-small {
  .vt-text-line {
    font-size: 1.6em;
  }

  .vt-ratio {
    border: none;
    padding-bottom: calc(56.25%); /* 16:9 */
  }
}

.vt-contain {
  margin-left: auto;
  margin-right: auto;
  max-width: 54.5em;
}

.vt-ratio {
  position: relative;
  padding-bottom: calc(56.25% - 11.25px); /* 16:9 -border */
  height: 0;
  overflow: hidden;
  border: 10px solid white;
  border-top-width: 0;
  border-bottom-width: 0;

  & iframe {
    background-color: #fff;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.has-lines {
    border-bottom-width: 10px;
  }
}

.vt-text-flex {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6em;
}

.v-spacer {
  width: 45px;
  flex-shrink: 0;
}

.vt-text-line {
  text-align: center;
  font-size: 2.2em;
  padding: 0 16px;
  line-height: 1.2em;
  flex-grow: 1;
}

.vt-fs-btn {
  width: 45px;
  height: 45px;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  padding: 10px 9px;
  cursor: pointer;
  margin-right: 10px;

  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin: 1px;
  }
}
</style>

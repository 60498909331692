<script>
import routeInfo from '@/mixins/routeInfo';
import { zip } from '@/scripts/filters';
import { mapState } from 'vuex';

export default {
  props: { skill: { default: () => ({}) }, entry: { default: () => ({}) }, modal: Boolean },
  mixins: [routeInfo],

  computed: {
    goals() {
      return zip(this.skill.skillWhat, this.skill.skillHow);
    },
    ...mapState(['view']),
  },
  // TODO: pages of playsteps?
};
</script>

<template>
  <div>
    <div class="text-center">
      <h2 v-if="!modal && skill.title" class="teacher-title pr-1">
        <span v-if="skill.category" class="text-physical mx-1">{{ skill.category }}</span>
        <span class="mx-1">{{ skill.title }}</span>
      </h2>
    </div>

    <slot />

    <div class="text-center">
      <h2 class="font-extrabold">
        Developing my <span class="lowercase">{{ skill.label }}</span> skills and knowledge so&hellip;
      </h2>
    </div>

    <div class="flex fsc-mx fsc-mb font-bold fsc-sx">
      <div v-if="!isPlay" class="w-1/2 fsc-px"><span class="fsc-lg">What</span></div>
      <div class="w-1/2 fsc-px"><span class="fsc-lg">How</span></div>
    </div>

    <div v-for="goal in goals" :key="goal.level" class="border-t border-grey-l2 fsc-sx">
      <div class="flex fsc-mx fsc-mb whitespace-pre-line">
        <div v-if="!isPlay" class="w-1/2 fsc-px">{{ goal.what }}</div>
        <div :class="isPlay ? 'w-full' : 'w-1/2'" class="fsc-px">{{ goal.how }}</div>
      </div>
    </div>
  </div>
</template>

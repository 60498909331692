<script>
import { mapGetters } from 'vuex';
import '@/assets/svgs/icons/star.svg';
import '@/assets/svgs/icons/lead-realpe.svg';
import '@/assets/svgs/icons/lead-bursts.svg';
import '@/assets/svgs/icons/allow-temp.svg';
import '@/assets/svgs/icons/mortar.svg';

export default {
  props: { user: Object, showBulk: Boolean, disabled: Boolean },

  computed: {
    name() {
      const user = this.user;
      return user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : '-';
    },
    detail() {
      return this.user.email || this.user.group || '-';
    },
    active() {
      return this.user.status > 2;
    },
    colour() {
      return this.active ? `text-theme` : 'text-grey';
    },
    lastLogin() {
      return this.$djMedT(this.user.lastLogin) || '-';
    },
    added() {
      return this.$djMed(this.user.dateCreated);
    },
    enabled() {
      return !this.disabled && this.isAdmin;
    },
    isSuper() {
      return this.user.admin === 2;
    },
    hasRuPaths() {
      return this.user.ruPaths && this.user.ruPaths.length;
    },
    tempEndDate() {
      return this.$djMed(this.user.dateEndTemp);
    },
    showWarn() {
      return this.user.dateEndTemp;
    },

    ...mapGetters('user', ['isAdmin']),
  },

  methods: {
    select() {
      if (this.enabled) this.$emit('click', this.user);
    },
    change2() {
      this.$emit('check', this.user);
    },
  },
};
</script>

<template>
  <div class="flex">
    <component
      :is="enabled ? 'button' : 'div'"
      :class="[colour, { outlne: enabled }]"
      class="card flex-1 p-2 pl-4 min-w-0 rounded-r-none"
      @click="select"
    >
      <div class="flex justify-between items-start text-lg">
        <strong v-if="name" class="min-w-0 break-words font-bold truncate">{{ name }}</strong>
        <div class="absolute card-subtitle flex top-0 right-0 p-2">
          <svg v-if="user.allowTemp && !user.wondeId">
            <title>Approved Non-MIS User</title>
            <use xlink:href="#allow-temp" />
          </svg>
          <svg v-if="hasRuPaths">
            <title>Active on My Learning</title>
            <use xlink:href="#mortar" />
          </svg>
          <svg v-else-if="user.riseUpId" class="opacity-50">
            <title>Has accessed My Learning</title>
            <use xlink:href="#mortar" />
          </svg>
          <svg v-if="user.leadPe">
            <title>real PE Lead</title>
            <use xlink:href="#lead-realpe" />
          </svg>
          <svg v-if="user.leadBursts">
            <title>BURSTS Lead</title>
            <use xlink:href="#lead-bursts" />
          </svg>
          <svg v-if="user.admin" :class="isSuper ? 'bg-pe text-white rounded-full border-2 border-pe' : ''">
            <title>Admin</title>
            <use xlink:href="#star" />
          </svg>
        </div>
      </div>
      <div class="text-sm text-black truncate">{{ detail }}</div>
    </component>

    <div class="bg-white rounded-r-lg outlne ml-0.5 shadow-sm">
      <label class="flex items-center h-full px-2 cursor-pointer" v-if="showBulk">
        <input type="checkbox" :checked="user.selected" @change="change2" class="w-5 cursor-pointer" />
      </label>
      <div v-else class="relative flex items-center h-full px-2 info-target">
        <svg v-if="showWarn" class="w-5 h-5 fill-pe"><use xlink:href="#warn" /></svg>
        <svg v-else class="w-5 h-5 fill-blank"><use xlink:href="#info" /></svg>
        <div class="info-popup">
          <div v-if="tempEndDate" class="text-fun-red px-2 py-2 mb-1 text-center bg-fun-red-shade">
            User will be removed on <strong>{{ tempEndDate }}</strong>
          </div>

          <div class="meta-title">Platform</div>
          <div>
            <span class="meta-label">Status</span>
            {{ user.statusLabel }}
          </div>
          <template v-if="active">
            <div>
              <span class="meta-label">Logins</span>
              {{ user.totalLogins }}
              <em v-if="lastLogin">({{ lastLogin }})</em>
            </div>
          </template>
          <div>
            <span class="meta-label">Added</span>
            {{ added }}
          </div>
          <template v-if="hasRuPaths">
            <div class="meta-title">My learning</div>
            <div v-for="path in user.ruPaths">
              <span class="meta-label">{{ path.name }}</span>
              {{ path.progress }}%
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

/* eslint-disable no-param-reassign */
import Vue from 'vue';
import $api from '@/scripts/api';
import { abilityStrings } from '@/scripts/strings';

const baseUser = {
  id: null,
  uid: '',
  kind: '',
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  abilityChoices: { dance: {}, gym: {} },
  lastVisited: {},
  wheelLink: '',
  showJukebox: false,
  access: {},
  leadPe: 0,
  leadBursts: 0,
  wondeId: '',
  status: 0,
  username: '',
  password: '',
  admin: 0,
  groupIds: [],
  roles: [],
  ruPaths: [],
  dateEndTemp: null,
};

export default {
  namespaced: true,
  state: { ...baseUser, showTempModal: false },

  mutations: {
    set(state, user) {
      const base = { ...baseUser };

      Object.keys(base).forEach(field => {
        state[field] = user[field] || base[field];
      });

      if (localStorage.getItem('abilityChoices') !== null) {
        state.abilityChoices = JSON.parse(localStorage.abilityChoices);
      }

      if (user.dateEndTemp) {
        state.showTempModal = true;
      }
    },

    clear(state) {
      const base = { ...baseUser };

      Object.keys(base).forEach(field => {
        state[field] = base[field];
      });
    },

    abilitySelect(state, choices) {
      Vue.set(state, 'abilityChoices', choices);
    },

    toggleJukebox(state) {
      state.showJukebox = !state.showJukebox;
    },

    setTempModal(state, val) {
      state.showTempModal = val;
    },
  },

  getters: {
    userId: state => state.id,
    userUri: state => `users/${state.kind}/${state.id}`,
    isHome: state => state.kind === 'home',
    isCdAdmin: state => state.kind === 'create' && state.admin,
    isCdSuper: state => state.kind === 'create' && state.admin === 2,
    isCreate: state => state.kind === 'create',
    isAdmin: state => state.admin,
    isPartner: state => state.account && state.account.accountType === 'partner',
    isRealLead: state => state.leadPe === 1,
    kind: state => state.kind,
    groupIds: state => state.groupIds,
    debug: state => ['UC3'].includes(state.uid),

    chosenAbilitySlug(state, getters, { route }) {
      return (slug, product = null) => {
        const product2 = product || route.params.product;
        return state.abilityChoices[product2 || {}][(slug || '').substring(0, 5)];
      };
    },

    chosenAbility(state, getters) {
      return ({ ability, abilities, slug, id }) => {
        if (!id) return {};
        const abilitySlug = ability || getters.chosenAbilitySlug(slug);

        return {
          title: abilityStrings[abilitySlug] || '',
          slug: abilitySlug || 'blank',
          ...(abilities && abilities.find(ability => ability.slug === abilitySlug)),
        };
      };
    },
  },

  actions: {
    fetch({ commit, dispatch }) {
      $api
        .get('auth/self')
        .then(response => {
          commit('set', response.data);
        })
        .catch(() => {
          dispatch('logOut', {}, { root: true });
        });
    },

    abilitySelect({ commit, state, rootState }, { unit, ability }) {
      const choices = { ...state.abilityChoices };
      choices[rootState.route.params.product][unit] = ability;

      commit('abilitySelect', choices);
      localStorage.abilityChoices = JSON.stringify(choices);
    },

    last({ state, rootGetters, rootState }, { title, type }) {
      if (state.kind !== 'home') {
        const last = {
          url: rootState.route.path,
          title,
          subtitle: rootGetters['menu/crumbs'].reduce((carry, crumb, index) => {
            return type !== crumb.type ? `${carry}${index ? ' - ' : ''}${crumb.title}` : carry;
          }, ''),
          product: rootState.route.params.product || '',
        };

        $api.post('/bookmarks/last', last).then(res => (state.lastVisited = res.data));
      }
    },

    getLast({ state }) {
      $api.get('/bookmarks/last').then(res => (state.lastVisited = res.data));
    },
  },
};

<script>
import FormControl from '@/admin/components/FormControl';
import AdminModal from '@/admin/components/AdminModal';
import AdminSpinner from '@/admin/components/AdminSpinner';
import { $group } from '@/scripts/models';
import { mapGetters } from 'vuex';
// import { validationMixin } from 'vuelidate';
// import { required } from 'vuelidate/lib/validators';

export default {
  components: { AdminSpinner, AdminModal, FormControl },
  props: ['value'],
  data: () => ({ group: $group.one(), showDelete: false }),
  // mixins: [validationMixin],

  // validations: {
  //   group: { name: { required }, year: {} },
  // },

  computed: {
    ...mapGetters('account', ['uri']),
  },

  methods: {
    // submit() {
    //   this.$v.$touch();
    //   if (this.group.$ready && !this.$v.$invalid) {
    //     this.group.$update().then(() => {
    //       this.$emit('input', this.group);
    //       this.$emit('close');
    //     });
    //   }
    // },

    remove() {
      if (this.group.$ready) {
        this.showDelete = false;
        this.group.$delete().then(() => {
          this.$router.replace(`${this.uri}bursts`);
        });
      }
    },
  },

  mounted() {
    this.group.$load(this.value);
  },

  watch: {
    value(val) {
      this.group = val;
    },
  },
};
</script>

<template>
  <div>
    <AdminSpinner :res="group">
      <form @submit.prevent="submit" class="">
        <!-- <FormControl :v="$v.group" slug="name" label="Class Name">
          <input type="text" id="name" v-model.trim="$v.group.name.$model" />
        </FormControl> -->

        <!-- <FormControl :v="$v.group" slug="name" label="Year Group">
          <input type="text" id="year" v-model.trim="$v.group.year.$model" />
        </FormControl> -->

        <div class="flex flex-wrap items-center justify-between">
          <div>
            <h4 class="font-bold">Remove Class</h4>
            <p class="text-sm">This class will be deleted. Children in this class will also be deleted.</p>
          </div>
          <div>
            <button type="button" class="btn4" @click="showDelete = true">Delete</button>
          </div>
        </div>
        <div class="flex justify-between mt-8">
          <div></div>
          <div class="btns4">
            <button class="btn4-clear" type="button" @click="$emit('close')">Cancel</button>
            <!-- <button class="btn4 bg-theme">Save</button> -->
          </div>
        </div>
      </form>
    </AdminSpinner>

    <AdminModal :active="showDelete" inline title="Confirm Delete?" @close="showDelete = false">
      <div class="btns4">
        <button type="button" class="btn4-clear" @click="showDelete = false">Cancel</button>
        <button type="button" class="btn4 bg-fun-red" @click="remove()">Delete Class</button>
      </div>
    </AdminModal>
  </div>
</template>

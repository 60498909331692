<script>
import VimeoText from '@/content/components/partials/VimeoText';

export default {
  components: { VimeoText },
  props: ['part', 'seconds'],

  computed: {
    watermark() {
      return (this.part.watermark || []).reduce((acc, img) => `background-image: url('${img.url}')`, '');
    },
    activeLine() {
      return [...this.part.lyrics].reverse().find(l => l.time && this.seconds >= l.time) || {};
    },
    slug() {
      return this.$route.params.product;
    },
  },
};
</script>

<template>
  <div class="relative">
    <div class="absolute inset-y-0 right-0 w-2/3 bg-no-repeat opacity-25" :style="watermark"></div>

    <div class="relative pl-8 pr-12 text-3xl leading-snug">
      <div
        v-for="(line, i) in part.lyrics"
        :key="i"
        :class="line.time === activeLine.time ? `font-bold text-${slug}` : ''"
        class="song-line"
      >
        {{ line.text }}&nbsp;
      </div>
    </div>

    <VimeoText v-if="part.video" :vid="part.video" class="mt-12 mb-5" />
  </div>
</template>

<style lang="postcss" scoped>
.song-line {
  min-height: 1em;
}
</style>

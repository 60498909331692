<script>
import Loader from '@/global/components/Loader';
import title from '@/scripts/learningTitles';
import routeInfo from '@/mixins/routeInfo';
import { unitQueries } from '@/scripts/queries';
import { uriSnip, zip } from '@/scripts/filters';
import { mapGetters } from 'vuex';

export default {
  components: { Loader },
  mixins: [routeInfo],
  data: () => ({ unit: {}, danceFocus: null }),

  metaInfo() {
    return { title: title(this.$route.params) };
  },

  computed: {
    ability() {
      const ability = this.chosenAbility(this.unit);

      if (this.isPe) {
        ability.abilityWhat = this.unit.abilityWhat;
        ability.abilityHow = this.unit.abilityHow;
      }

      return ability;
    },
    uri() {
      return uriSnip(this.$route.path, 6);
    },

    ...mapGetters('user', ['chosenAbility']),
  },

  methods: {
    fetch() {
      const variables = { uri: this.uri, funsLevel: this.funsLevel };
      this.$gql(unitQueries[this.productSlug], variables).then(({ unit, danceFocus }) => {
        this.unit = { ...unit, danceFocus };
        this.$store.dispatch('user/last', this.unit);
      });
    },
  },

  watch: {
    $route: 'fetch',
  },

  created() {
    this.fetch();
  },
};
</script>

<template>
  <Loader :res="unit.number">
    <RouterView :unit="unit" :ability="ability" :uri="uri" />
  </Loader>
</template>

<script>
export default {
  // prettier-ignore
  props: {
    v: { default: () => ({}) }, slug: String, label: String, desc: String, icon: String,
    unlabelled: Boolean, theme: String, hasClear: Boolean, active: {},
  },

  computed: {
    val() {
      return this.v[this.slug] || {};
    },
    themeClass() {
      return this.theme ? `control2-${this.theme}` : 'control2-corners';
    },
  },
};
</script>

<template>
  <div :class="[themeClass, { active, icon: !!icon, 'has-error': val.$error }]">
    <label :class="{ 'sr-only': unlabelled }" class="block mb-1 font-bold cursor-pointer" :for="slug">
      <slot name="label">{{ label }}</slot>
      <div class="text-xs font-normal">
        <slot name="test">{{ desc }}</slot>
      </div>
    </label>

    <div class="relative">
      <div class="h-full absolute top-0 right-0 p-1 flex pointer-events-none">
        <button
          v-if="hasClear && active"
          class="w-8 h-full rounded pointer-events-auto"
          type="button"
          @click="$emit('clear')"
        >
          <svg class="w-2 h-2 mx-auto fill-blank"><use xlink:href="#cross" /></svg>
        </button>
        <svg v-if="icon" class="w-10 h-full fill-pe p-1">
          <use :xlink:href="`#${icon}`" />
        </svg>
      </div>
      <slot></slot>
    </div>

    <div class="mt-1 text-sm font-bold text-fun-red" v-if="(val.$dirty || v.$submitted) && val.$error">
      <slot name="errors"></slot>
      <template v-if="val.required === false">Please fill out this field</template>
      <template v-else-if="val.email === false">Invalid email</template>
      <template v-else-if="val.sameAs === false">Doesn't match</template>
      <template v-else-if="val.maxLength === false">Max {{ val.$params.maxLength.max }} Characters</template>
      <template v-else-if="val.isUnique === false">Email not available</template>
      <template v-else-if="val.anyOne === false">Please select at least one</template>
      <template v-else-if="val.validDates === false">End date must be after start date</template>
    </div>
  </div>
</template>

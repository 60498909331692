<script>
import '@/assets/svgs/icons/cross.svg';
import '@/assets/svgs/buttons/success.svg';

export default {
  props: { open: {}, bg: {}, title: {}, icon: {}, wide: Boolean, force: {}, detail: {} },

  metaInfo() {
    return { ...(this.open && { bodyAttrs: { class: 'no-scroll' } }) };
  },

  computed: {
    fg() {
      return this.bg === 'dice' ? '' : 'white';
    },
    bg$() {
      return this.bg || this.$route.params.product;
    },
  },
};
</script>

<template>
  <transition name="fade">
    <div v-if="open" :class="`bg-${bg$}`" class="modal fixed overflow-hidden inset-0 z-30 overscroll-contain">
      <svg v-if="icon" :class="`fill-${bg$}-shade`" class="watermark opacity-25 absolute bottom-0 right-0 m-5">
        <use :xlink:href="`#${icon}`" />
      </svg>

      <div class="h-full pb-24 overflow-auto overscroll-contain relative">
        <div class="container" :class="`text-${fg}`">
          <button
            v-if="!force"
            :class="`border-${bg$}-shade fill-current bg-${bg$}`"
            class="absolute top-0 right-0 z-40 border-4 w-16 h-16 rounded-full m-5"
            @click="$emit('close')"
          >
            <svg class="w-6 h-6 m-auto"><use xlink:href="#cross" /></svg>
          </button>

          <div :class="{ 'w-5/6': !wide }" class="m-auto mt-20">
            <div v-if="title" v-html="title" class="mb-10 font-extrabold text-5xl"></div>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.modal {
  cursor: default;
  margin-top: 60px;
}

.watermark {
  @apply pointer-events-none;
  height: 40vh;
  width: 40vh;
  margin-right: 10vw;
}

.bg-fade {
  background-color: rgba(211, 211, 211, 0.8);
}
</style>

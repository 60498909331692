<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import Pagination from '@/admin/components/Pagination';
import { $student } from '@/scripts/models';

export default {
  props: ['label', 'group', 'uri'],
  components: { AdminSpinner, Pagination },
  data: () => ({ students$: $student.all(), students: [] }),

  computed: {
    students2() {
      return this.students.map(s => ({ ...s, uri: this.uri + s.id }));
    },
    inviteLabel() {
      return this.group?.totalInvitedStudents ? 'Re-invite' : 'Invite';
    },
  },

  methods: {
    async fetch(params = {}) {
      this.students = await this.students$.$get({
        page: params.page || 1,
        groupId: this.$route.params.groupId,
        status: 0,
        sort: 'name',
        perPage: 24,
      });
    },
  },

  created() {
    this.fetch();
  },
};
</script>

<template>
  <AdminSpinner v-if="students2.length" :res="students$" hide>
    <div class="flex items-center mb-2">
      <h2 class="head-2xl capitalize text-theme">{{ label }} Accounts</h2>
      <span class="title-meta">{{ students$.totalItems }}</span>
      <button class="btn4 bg-bursts ml-auto" @click="$emit('invite')">{{ inviteLabel }}</button>
    </div>

    <div v-if="students$.empty" class="empty">There are no {{ label }} children in this class</div>

    <div v-else class="flex flex-wrap -mx-2">
      <div v-for="student in students2" class="w-1/3 lg:w-1/4 px-2">
        <RouterLink :to="student.uri" class="card outlne my-2 text-bursts-shade">
          <div class="text-black">{{ student.name }}</div>
        </RouterLink>
      </div>
    </div>

    <Pagination :collection="students$" @change="fetch" class="mt-2 -mb-2" />
  </AdminSpinner>
</template>

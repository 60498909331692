<script>
import HaloButton from '@/content/components/buttons/HaloButton';
import '@/assets/svgs/buttons/print.svg';

export default {
  components: { HaloButton },

  methods: {
    print: () => window.print(),
  },
};
</script>

<template>
  <HaloButton icon="print" label="Print" @click="print" />
</template>

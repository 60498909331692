<script>
import HaloButton from '@/content/components/buttons/HaloButton';
import '@/assets/svgs/buttons/notes.svg';

export default {
  components: { HaloButton },
  props: { uri: { type: String, default: '' } },

  computed: {
    uri$() {
      return this.uri || `${this.$store.state.s3}static/${this.$route.params.product}-notes.pdf`;
    },
  },
};
</script>

<template>
  <HaloButton icon="notes" label="Notes" :uri="uri$" />
</template>

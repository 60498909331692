<script>
import Loader from '@/global/components/Loader';

export default {
  components: { Loader },
  mounted() {
    this.$api
      .post('auth/discourse', this.$route.query)
      .then(res => {
        window.location.href = res.data;
      })
      .catch(err => {
        this.$store.commit('toast/error', 'Failed to sign you in');
        this.$router.push('/');
      });
  },
};
</script>

<template>
  <Loader class="container"></Loader>
</template>

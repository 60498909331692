<script>
import '@/assets/svgs/icons/spinner.svg';

export default {
  props: { res: {}, hide: Boolean, ready: Boolean },

  computed: {
    show() {
      return this.res ? !(this.hide && !this.res.$fetched) : !(this.hide && !this.ready);
    },
    loading() {
      return this.res ? !this.res.$ready : !this.ready;
    },
  },
};
</script>

<template>
  <div class="relative loader-sm">
    <transition name="fade">
      <div v-if="show" :class="{ loading }" class="loader-blur"><slot /></div>
    </transition>
    <div v-if="loading" class="p-4 bg-white rounded-lg shadow abs-center">
      <div class="w-8 h-8 spin08">
        <svg class="fill-theme w-8 h-8"><use xlink:href="#spinner" /></svg>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.abs-center {
  @apply absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2;
}
.loader-sm {
  min-height: 100px;
}
.loader-lg {
  min-height: 400px;
}

.loader-blur {
  /* transition: opacity 0.1s, filter 0.1s; */
  &.loading {
    @apply opacity-50 pointer-events-none;
    filter: blur(2px);
  }
}
</style>

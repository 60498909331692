<script>
import '@/assets/svgs/buttons/home-challenge.svg';
import '@/assets/svgs/buttons/review-cloud.svg';
import '@/assets/svgs/buttons/more-active.svg';
import '@/assets/svgs/buttons/think-about.svg';
import '@/assets/svgs/buttons/equipment.svg';
import '@/assets/svgs/buttons/success.svg';
import '@/assets/svgs/buttons/trickiest.svg';
import '@/assets/svgs/buttons/trickier.svg';
import '@/assets/svgs/buttons/tricky.svg';
// import '@/assets/svgs/cog-border.svg';
import '@/assets/svgs/cog.svg';

export default {
  props: {
    icon: { type: String, default: '' },
    colour: { type: String, default: 'grey-l2' },
  },
};
</script>

<template>
  <div class="pb-2 t-col">
    <div :class="[`border-${colour} p-0`]" class="txt-block2 bg-white relative break-inside-avoid">
      <div v-if="icon" class="absolute top-0 right-0 m-3">
        <svg :class="`fill-${colour}`" class="w-8 h-8 lg:w-10 lg:h-10 print:h-6 print:w-6">
          <use :xlink:href="`#${icon}`" />
        </svg>
      </div>

      <slot />
    </div>
  </div>
  <!-- TW: border-black -->
</template>

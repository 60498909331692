<script>
import VideoButton from '@/content/components/buttons/VideoButton';
import NotesButton from '@/content/components/buttons/NotesButton';
import VimeoText from '@/content/components/partials/VimeoText';
import query from '@/queries/home/intro.gql';

const textLines = [
  { text: 'Welcome to real PE at home', time: 0 },
  { text: 'Active, healthy children are happier and achieve more', time: 9 },
  { text: 'Active play is essential for children’s physical, emotional and cognitive development', time: 13 },
  { text: 'As with reading, playing with your child, to support their physical development is essential', time: 20 },
  { text: 'real PE at home has been created to support you to play and be active together', time: 33 },
  { text: 'It’s packed full of games, challenges and adventures to explore, even in a small space', time: 37 },
  { text: 'Accessible on computers, tablets and smart phones', time: 44 },
  { text: 'If you can, try joining in with your child', time: 48 },
  { text: 'What’s more important and fun than playing together with your child?', time: 53 },
  { text: 'To access real PE at home, enter your username and password', time: 56 },
  { text: 'These will have been provided by your school', time: 60 },
  { text: 'Choose from a programme for EYFS/Key Stage 1 or Key Stage 2', time: 63 },
  { text: 'Access supporting documents via the icon in the top right', time: 72 },
  { text: 'The EYFS/Key Stage 1 programme has a choice of 12 fun themes', time: 78 },
  { text: 'Each theme focuses on a specific skill', time: 84 },
  { text: 'The Space theme for example is Jumping and Landing', time: 88 },
  { text: 'There are 6 ways to explore each theme - An adventure, active story, games…', time: 92 },
  { text: '…skill challenges, moving to music and wider curriculum activities', time: 97 },
  { text: 'Choose what you want to do and explore and play', time: 102 },
  { text: 'Each page has several icons to help or to make the activity more fun', time: 107 },
  { text: 'These include a Jasmine icon to give you tips or ideas', time: 113 },
  { text: 'A music icon', time: 118 },
  { text: 'A voiceover icon to read out the instructions', time: 123 },
  { text: 'A dice icon to support some activities', time: 130 },
  { text: 'A video icon to see the activity in action', time: 138 },
  { text: 'Use the icons at the bottom to explore the theme in a different way', time: 155 },
  { text: 'To return to the 12 themes, click on KS1 in the breadcrumb at the top', time: 166 },
  { text: 'To return to the home page, click on real PE at home in the breadcrumb', time: 173 },
  { text: 'In Key Stage 2, you have 12 skills to explore', time: 179 },
  { text: 'Choose the skill you want to work on', time: 187 },
  { text: 'Work through the skill challenges from yellow to black', time: 192 },
  { text: 'Use the videos as a guide', time: 196 },
  { text: 'You have a choice of 5 games to apply and practise the skills', time: 210 },
  { text: 'Each game has a different focus, for example, a Personal best challenge', time: 215 },
  { text: 'Each activity has a number of icons to support you', time: 220 },
  { text: 'The Jasmine icon gives you tips or ideas', time: 224 },
  { text: 'The How to play icon explains the activity', time: 229 },
  { text: 'Either on your own or with a partner', time: 233 },
  { text: 'The Have you thought about icon gives ideas on adapting the activity', time: 237 },
  { text: 'The video icon shows you the activity in action', time: 244 },
  { text: 'We hope you have fun playing and exploring together', time: 254 },
  { text: 'We’d love to see and hear how you are getting on', time: 260 },
  { text: 'Follow us on social media and join our real PE at home Facebook group', time: 264 },
];

export default {
  components: { VideoButton, VimeoText, NotesButton },
  data: () => ({ stages: [], showVideo: false, textLines }),

  metaInfo() {
    return { title: this.title };
  },

  computed: {
    title() {
      return this.$store.getters['user/isHome'] ? 'real PE at home' : 'real PE daily';
    },
  },

  created() {
    this.$gql(query).then(res => {
      this.stages = res.stages;
      this.$store.dispatch('user/last', { title: 'Home Intro', type: 'intro' });
    });
  },
};
</script>

<template>
  <div class="container text-center">
    <div class="pb-12 bg-white curves">
      <div class="flex items-center justify-between p-5 mb-5">
        <VideoButton @click="showVideo = !showVideo" />
        <h1 class="text-5xl">{{ title }}</h1>
        <NotesButton uri="/support/daily" />
      </div>

      <div class="flex my-3 justify-evenly" v-if="!showVideo">
        <div v-for="stage in stages" :key="stage.id">
          <RouterLink :to="stage.uri">
            <img
              v-for="img in stage.previewImage"
              :key="img.id"
              :src="img.url"
              class="mb-5 border-4 rounded-full large border-pe"
              alt=""
              draggable="false"
            />
            <h2 class="text-4xl font-bold leading-none text-pe">
              {{ stage.slug === 'themes' ? 'EYFS / KS 1' : 'KS2' }}
            </h2>
            <h2 class="text-xl font-bold">{{ stage.title }}</h2>
          </RouterLink>
        </div>
      </div>
      <VimeoText vid="469765887" :lines="textLines" v-else colour="pe" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.large {
  @apply w-64 h-64;

  @screen lg {
    width: 350px;
    height: 350px;
  }
}
</style>

<script>
import AbilitySelectCogs from '@/content/components/partials/AbilitySelectCogs';
import HaloButton from '@/content/components/buttons/HaloButton';
import Modal from '@/content/components/partials/Modal';
import routeInfo from '@/mixins/routeInfo';
import { mapGetters } from 'vuex';
import '@/assets/svgs/buttons/ability-select.svg';

export default {
  components: { Modal, AbilitySelectCogs, HaloButton },
  props: { lesson: {}, modalOnly: Boolean },
  data: () => ({ modal: false, force: false }),
  mixins: [routeInfo],

  computed: {
    ...mapGetters('user', ['chosenAbilitySlug']),

    isRequired() {
      return !this.chosenAbilitySlug(this.lesson.slug);
    },
    show() {
      return this.productInfo.abilitySelect;
    },
  },
};
</script>

<template>
  <div v-if="show">
    <Modal :open="isRequired || modal" :force="isRequired" bg="fade" wide @close="modal = false">
      <div class="mx-20 text-3xl text-blank">
        <AbilitySelectCogs :unit="lesson.unit" class="px-5 py-4 bg-white curves" @select="modal = false" />
      </div>
    </Modal>

    <HaloButton v-if="!modalOnly" colour="blank" label="Ability Select" icon="ability-select" @click="modal = true" />
  </div>
</template>

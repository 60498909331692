<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import AdminModal from '@/admin/components/AdminModal';
import DebugLine from '@/admin/components/DebugLine';
import StudentForm from '@/bursts/components/StudentForm';
import ContactCard from '@/bursts/components/ContactCard';
import ActiveMins from '@/bursts/components/ActiveMins';
import Events from '@/bursts/components/Events';
import Bolts from '@/bursts/components/Bolts';
import { $student } from '@/scripts/models';
import { mapGetters } from 'vuex';
import { adventureStrings, behaviourStrings } from '@/scripts/strings';

export default {
  components: { AdminSpinner, AdminModal, StudentForm, Events, ActiveMins, ContactCard, DebugLine, Bolts },
  data: () => ({ student: $student.one(), showEdit: false, contact: '' }),

  metaInfo() {
    return { title: this.student.name || 'Child' };
  },

  computed: {
    studentId() {
      return this.$route.params.studentId;
    },
    sen() {
      if (this.student.sen === 'K') return 'SEN support';
      if (this.student.sen === 'E') return 'EHCP';
      return 'None';
    },

    adventures() {
      const levels = this.student.adventureLevels || [];
      return adventureStrings.map(a => ({ ...a, level: levels[a.id] || 0 }));
    },
    behaviours() {
      return behaviourStrings.map(b => ({ ...b, value: this.student[b.slug] }));
    },

    totalXp() {
      return this.student.brave + this.student.wise + this.student.helpful + this.student.imaginative;
    },
    nextXp() {
      return Math.round((this.totalXp % 10000) / 100) + '%';
    },

    ...mapGetters('account', ['burstsUri']),
  },

  methods: {
    fetch() {
      if (this.studentId) this.student.$get({ id: this.studentId });
    },

    editContact(contact) {
      this.showEdit = true;
      this.contact = contact;
    },

    invite() {
      this.student.$invite({}, { email: this.contact.email }).then(res => {
        this.fetch();
        this.showEdit = false;
        this.$store.commit('toast/info', 'Invite sent');
      });
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<template>
  <div class="container">
    <AdminSpinner :res="student">
      <div class="title-flex mb-6">
        <h2 class="head-4xl text-theme">{{ student.firstName }} {{ student.lastName }}</h2>
        <!-- <div><button class="btn4 bg-pe" @click="showEdit = true">Edit</button></div> -->
        <RouterLink :to="`${burstsUri}groups/${$route.params.groupId}`" class="btn4-clear">Back to class</RouterLink>
      </div>

      <div class="flex -mx-3">
        <div class="w-2/5 lg:w-1/2 px-3">
          <div class="flex flex-col items-start">
            <div class="bg-white rounded-xl mb-3 px-5 py-2">
              <strong class="mr-2">Unique code:</strong>
              <code class="text-lg">{{ student.code }}</code>
            </div>
            <div class="bg-white px-5 py-4 rounded-xl mb-3">
              <div class="mb-1">
                <strong>Year:</strong>
                {{ student.year }}
              </div>
              <div class="mb-1">
                <strong>DOB:</strong>
                {{ student.dob }}
              </div>
              <div class="mb-1">
                <strong>Gender:</strong>
                {{ student.gender }}
              </div>
              <div class="mb-1">
                <strong>Ethnicity:</strong>
                {{ student.ethnicity }}
              </div>
              <div class="mb-1">
                <strong>Nationality:</strong>
                {{ student.nationality || '-' }}
              </div>
              <div class="mb-1">
                <strong>SEN Status:</strong>
                {{ sen }}
              </div>
              <div class="mb-1">
                <strong>Pupil Premium Indicator:</strong>
                {{ student.ppIndicator ? 'Yes' : 'No' }}
              </div>
              <div class="mb-1">
                <strong>Pupil Premium Eligible:</strong>
                {{ student.ppEligible ? 'Yes' : 'No' }}
              </div>
              <div>
                <strong>English as Another Language:</strong>
                {{ student.eal ? 'Yes' : 'No' }}
              </div>
            </div>
          </div>
        </div>

        <div class="w-3/5 lg:w-1/2 px-3">
          <div class="flex -mx-1 mb-2">
            <div class="w-1/2 px-1">
              <div class="bg-white p-5 rounded-xl h-full">
                <div class="text-bursts font-semibold leading-none mb-3 text-xl">Active minutes</div>
                <ActiveMins :record="student" class="text-3xl" />
              </div>
            </div>
            <div class="w-1/2 px-1">
              <div class="bg-white p-5 pr-8 rounded-xl h-full relative">
                <div v-if="student.level" class="ribbon absolute -top-1 -right-1 text-lg">{{ student.level }}</div>
                <div class="text-bursts font-semibold leading-none mb-2 text-xl">XP level award</div>
                <div class="flex justify-between">
                  <div class="font-bold">{{ totalXp }} XP</div>
                  <div class="text-grey font-semibold">{{ nextXp }}</div>
                </div>

                <div class="flex overflow-hidden rounded h-2 border bg-bursts-shade">
                  <div class="bar2 px-1 bg-bursts" :style="{ width: nextXp }"></div>
                  <!-- <div></div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white p-5 rounded-xl mb-2">
            <h3 class="head-2xl text-bursts mb-4">Fundamental movement skills' progress</h3>

            <div class="flex flex-wrap -mx-3 -my-2">
              <div v-for="badge in adventures" class="w-1/2 px-3 my-2 flex items-center">
                <img :src="badge.img" alt="" class="h-14" />
                <div class="ml-4">
                  <Bolts :level="badge.level" class="my-1" />
                  <div class="font-bold">{{ badge.name }}</div>
                  <div class="text-sm">{{ badge.fms }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white p-5 rounded-xl">
            <h3 class="head-2xl text-bursts mb-4">Behaviour points</h3>

            <div class="flex flex-wrap -mx-3 -my-2">
              <div v-for="badge in behaviours" class="w-1/2 px-3 my-2 flex items-center">
                <img :src="badge.img" alt="" class="h-14 -mx-1" />
                <div class="ml-4">
                  <div class="font-bold">{{ badge.value }} XP</div>
                  <div class="text-sm">{{ badge.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h2 class="head-2xl text-theme mb-3">Contacts</h2>
      <div class="flex flex-wrap -mx-3">
        <div v-for="(contact, i) in student.fullContacts" :key="i" class="w-1/2 px-3 my-2 lg:w-1/3">
          <ContactCard v-bind="contact" @click="editContact" />
        </div>
      </div>
      <div v-if="student.fullContacts && !student.fullContacts.length" class="empty">
        This child does not have any contact data
      </div>
    </AdminSpinner>

    <Events :studentId="studentId" />

    <AdminModal :active="showEdit" title="Contact" @close="showEdit = false">
      <div class="mb-4">
        <div class="text-2xl font-semibold">{{ contact.name || '-' }}</div>
        <div v-if="contact.email">{{ contact.email }}</div>
      </div>

      <div v-if="contact.kind === 'parent'">This contact is successfully connected to {{ student.name }}</div>

      <p class="err-list" v-else-if="!contact.email">This contact does not have an email address assigned</p>

      <div v-else class="flex justify-between items-center">
        Send {{ contact.invited ? 'another' : '' }} invite email
        <button class="btn4 bg-bursts" type="button" @click="invite">{{ contact.invited ? 'Re-' : '' }}Invite</button>
      </div>

      <DebugLine type="parent" :object="contact" />
    </AdminModal>
  </div>
</template>

<script>
import Pagination from '@/admin/components/Pagination2';
import AdminSpinner from '@/admin/components/AdminSpinner';
import WondeEmployee from '@/admin/components/users/WondeEmployee';
import { mapState } from 'vuex';

export default {
  components: { Pagination, AdminSpinner, WondeEmployee },
  data: () => ({ users: [], ready: false, page: 1, count: 24 }),

  computed: {
    slice() {
      const end = this.page * this.count;
      return this.users.slice(end - this.count, end);
    },
    fixHeight() {
      return this.users.length > 24 ? 'min-height: 560px' : '';
    },

    ...mapState('account', ['wondeId']),
  },

  methods: {
    fetch() {
      this.$api.get(`wonde/${this.wondeId}/employees`).then(({ data }) => {
        this.users = data;
        this.ready = true;
      });
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<template>
  <AdminSpinner :ready="ready" hide>
    <div class="px-4 py-3 -m-3 rounded-br-md bg-grey-l3 shadow-inner">
      <div class="note note-warn mb-3">
        <svg class="note-icon"><use xlink:href="#info" /></svg>
        <p>
          This employee information is generated by your school's Management Information System (MIS). <br />Any
          ammendments should be made by your MIS administrator.
        </p>
      </div>

      <div v-if="ready && !users.length" class="empty mb-4">No employees can be found</div>
      <div v-else :style="fixHeight">
        <div class="flex flex-wrap -mx-3">
          <div v-for="user in slice" :key="user.wondeId" class="w-1/2 px-3 my-2 lg:w-1/3">
            <WondeEmployee :user="user" @update="$emit('update')" />
          </div>
        </div>
      </div>

      <Pagination :total="users.length" :limit="count" :page="page" class="mt-3" @change="page = $event" />
    </div>
  </AdminSpinner>
</template>

<script>
export default {
  props: { asset: Array, colour: String },

  computed: {
    img() {
      return (this.asset || [])[0] || {};
    },
  },
};
</script>

<template>
  <img
    :src="img.url"
    :class="`border-${colour}`"
    class="inline border-4 bg-white rounded-full aspect-square print:border-2"
    draggable="false"
    @mouseenter="$emit('over')"
  />
</template>

<script>
import routeInfo from '@/mixins/routeInfo';
import { mapState, mapMutations } from 'vuex';

export default {
  mixins: [routeInfo],

  computed: {
    labels() {
      if (this.isPlay) return ['Teacher', 'Family'];
      return ['Planning', 'Teaching'];
    },

    ...mapState(['view']),
  },

  methods: mapMutations(['viewToggle']),
};
</script>

<template>
  <button class="toggle relative h-16 lg:h-20 bg-grey-l3 border-2 border-grey-l2 w-20 lg:w-24" @click="viewToggle()">
    <div
      :class="{ 'translate-y-full': !view }"
      class="toggle-slider absolute top-0 inset-x-0 border-2 border-pe bg-white h-1/2 rounded-4xl"
    ></div>

    <div class="relative text-grey uppercase font-bold text-xs lg:text-sm flex flex-col h-full justify-around">
      <div class="trns-color" :class="{ 'text-pe': view }">{{ labels[0] }}</div>
      <div class="trns-color" :class="{ 'text-pe': !view }">{{ labels[1] }}</div>
    </div>
  </button>
</template>

<style lang="postcss" scoped>
.toggle {
  border-radius: 16px;

  @screen lg {
    border-radius: 20px;
  }
}

.toggle-slider {
  transition: transform 0.4s ease;
}
</style>

<script>
import WarmupStep from '@/content/components/activities/WarmupStep';
import DiceModal from '@/content/components/modal/DiceModal';
import '@/assets/svgs/icons/lock.svg';

export default {
  components: { WarmupStep, DiceModal },
  props: { lesson: {}, steps: { default: () => [] }, partDice: { default: () => [] } },
  data: () => ({ current: 0 }),

  computed: {
    spine() {
      return this.steps.reduce((carry, step) => {
        return (carry.text + carry.subtitle).length > (step.text + step.subtitle).length ? carry : step;
      });
    },

    dice() {
      return (this.steps[this.current] || {}).dice || [];
    },
  },
};
</script>

<template>
  <div class="relative py-10 mx-10" :class="$route.params.product">
    <DiceModal :dice="dice" big class="absolute top-0 right-0 -mt-10" />

    <div
      v-if="lesson.warmUpIntro"
      :class="{ 'mr-20 lg:mr-40': dice && dice.length }"
      class="rdct rdct-pupil mb-14 -mt-4"
      v-html="lesson.warmUpIntro"
    ></div>

    <div class="relative w-1/2 m-auto">
      <WarmupStep :step="spine" spine />
      <WarmupStep
        v-for="(step, i) in steps"
        :key="step.id"
        :current="current"
        :step="step"
        :index="i"
        @setCurrent="current = i"
      />
    </div>

    <div class="flex justify-center mt-16">
      <button
        v-for="(step, i) in steps"
        :key="i"
        :class="[`step${-(current - i)}`, { end: step.end }]"
        class="pip rounded-full text-white flex items-center justify-center mx-0.5 bg-grey-l1 text-lg leading-none"
        @click="current = i"
      >
        <div class="number">{{ step.end ? '?' : i + 1 }}</div>
        <svg v-if="!step.end" class="hidden w-full h-full p-2 lock"><use xlink:href="#lock" /></svg>
      </button>
    </div>
  </div>
</template>

<style lang="postcss">
.pip {
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 35px;
  height: 35px;
  transform: scale(1);

  &:focus {
    @apply outline-none;
  }

  &.step0 {
    @apply bg-pe;
    transform: scale(1.5);
    z-index: 1;
  }
  &.step-1,
  &.step1 {
    @apply bg-blank;
  }
  &.step2:not(.end) {
    background-color: #ffc629;

    & .lock {
      @apply block;
    }
    & .number {
      @apply hidden;
    }
  }
  &.end {
    @apply bg-white border-2 border-grey-l1 text-grey-l1;
  }
}

.dance .pip.step0 {
  @apply bg-dance;
}
</style>

<script>
import Brand from '@/global/components/Brand';
import Button from '@/global/components/Button';
import { mapGetters } from 'vuex';

export default {
  components: { Brand, Button },

  props: {
    current: { default: () => ({}) },
    links: Boolean,
    enhanced: Boolean,
  },

  computed: {
    products() {
      const access = this.$store.state.account.access;
      const products = ['pe', 'gym', 'dance', 'foundations', 'play', 'home'];
      return products.map(slug => ({ slug, active: this.isCreate || !!(access[slug] && access[slug].length) }));
    },

    ...mapGetters('user', ['isCreate']),
  },

  methods: {
    select(product) {
      this.$store.dispatch('menu/select', product);
    },
  },
};
</script>

<template>
  <div class="flex -mx-1 overflow-hidden text-clear">
    <Button
      v-for="product in products"
      :key="product.slug"
      :class="[
        product.slug === current.slug ? `bg-blank-shade border-${product.slug}` : '',
        product.active ? '' : 'opacity-25',
        product.active && links ? 'hover:bg-blank-shade cursor-pointer' : '',
        enhanced ? 'border-grey-l2' : '',
      ]"
      :active="product.active"
      class="flex items-end p-4 py-5 m-1 border-b-4 curves-sm colour-3s"
      @click="select(product)"
    >
      <Brand :slug="product.slug" alt="popup" class="h-16" />
    </Button>
  </div>
</template>

<script>
import Cog from '@/content/components/partials/Cog';
import { abilityStrings } from '@/scripts/strings';
import { mapActions } from 'vuex';

export default {
  components: { Cog },
  props: ['unit', 'mode'],
  data: () => ({ modal: false }),

  computed: {
    abilities() {
      return Object.keys(abilityStrings).map(slug => ({ slug, title: abilityStrings[slug] }));
    },
  },

  methods: {
    select(ability) {
      this.modal = false;
      this.abilitySelect({ unit: this.unit.slug, ability: ability.slug });
      this.$emit('select');
    },

    ...mapActions('user', ['abilitySelect']),
  },
};
</script>

<template>
  <div :class="`mode-${mode}`">
    <h3 class="text-center">Choose a multi-ability</h3>
    <div class="flex flex-wrap justify-center cog-flex">
      <div v-for="ability in abilities" :key="ability.slug" @click="select(ability)">
        <div class="p-5 cog-button curves">
          <Cog :ability="ability" :size="mode === 'year' ? '100' : '200'" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.cog-flex {
  .cog-button {
    padding: 20px;
    cursor: pointer;
    margin: 10px;

    &:hover {
      background: #f4f4f4;
    }
  }
}

.mode-year {
  .cog-button {
    padding: 10px;
    margin: 4px;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;

    &:hover {
      background: #a3a3a3;
    }
  }
}
</style>

<script>
import Link from '@/global/components/Link2';

export default {
  components: { Link },
  props: ['uri', 'linkClass', 'colour', 'exact'],

  computed: {
    colour$() {
      return this.colour || 'pe';
    },
  },
};
</script>

<template>
  <Link
    :uri="uri"
    :active-class="`bg-${colour$}-shade`"
    :class="`hover:text-${colour$} hover:bg-${colour$}-shade`"
    :exact="exact"
    class="nm2"
  >
    <slot />
  </Link>
</template>

import review from '@/queries/shared/review.gql';
import story from '@/queries/shared/story.gql';
import song from '@/queries/shared/song.gql';

import homewarmup from '@/queries/home/warmup.gql';
import homegame from '@/queries/home/ks1-game.gql';
import funshomegame from '@/queries/home/ks2-game.gql';
import homeskill from '@/queries/home/ks1-skill.gql';
import funshomeskill from '@/queries/home/ks2-skill.gql';
import homewider from '@/queries/home/wider.gql';

import playreview from '@/queries/play/review.gql';

export default {
  song,
  story,
  review,
  homewarmup,
  homegame,
  homeskill,
  homewider,
  funshomegame,
  funshomeskill,
  playwarmup: homewarmup,
  playgame: homegame,
  playreview,
};

<script>
import ProductIntro from '@/admin/views/ProductIntro';
import { mapGetters } from 'vuex';

export default {
  components: { ProductIntro },
  computed: mapGetters(['hasRealPe']),
};
</script>

<template>
  <RouterView v-if="hasRealPe" />
  <ProductIntro v-else slug="intro-real" />
</template>

<script>
import Dots from '@/admin/components/Dots';
import { status } from '@/scripts/strings';
import { address } from '@/scripts/filters';
import '@/assets/svgs/icons/wonde.svg';

export default {
  components: { Dots },
  props: ['account'],

  computed: {
    status() {
      return status[this.account.status];
    },
    side() {
      if (this.account.status === 30) return 'text-pe';
      if (this.account.status === 20) return 'text-pe-shade';
      if (this.account.status === 10) return 'text-blank';
      return 'text-blank-shade';
    },

    address() {
      return address(this.account);
    },
  },
};
</script>

<template>
  <RouterLink :to="`/accounts/${account.id}`" :class="side" class="flex flex-col p-4 text-sm card outlne h-full">
    <div
      :class="account.status > 10 ? 'text-pe' : 'text-blank'"
      class="flex justify-between mb-1 text-lg font-bold account-title"
    >
      {{ account.name }}
      <div class="shrink-0 flex -mx-0.5">
        <img v-if="account.wondeRealPe" src="/images/wonde-realpe.svg" title="Wonde: real PE" class="h-3 ml-2" />
        <img v-if="account.wondeBursts" src="/images/wonde-bursts.svg" title="Wonde: BURSTS" class="h-3 ml-2" />
      </div>
    </div>
    <div :class="!account.active ? 'text-blank' : 'text-black'" class="mb-2">
      <div>{{ address }}</div>
      <div>
        <span class="mr-1" v-if="account.county">{{ account.county.name }},</span>
        <span class="font-bold" v-if="account.region">{{ account.region.name }}</span>
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="flex justify-between items-end">
      <Dots :access="account.access" :bursts="account.hasBursts" class="mt-2" />
      <div class="uppercase text-xs font-bold tracking-widest leading-none text-blank">
        {{ account.frozen ? 'Frozen' : status }} • {{ account.accountType }}
      </div>
    </div>
  </RouterLink>
</template>

<script>
export default {
  props: { vid: {}, contain: Boolean },
};
</script>

<template>
  <div :class="{ contain }" v-if="vid">
    <div class="video-ratio">
      <iframe
        :src="`https://player.vimeo.com/video/${vid}?title=0&byline=0&portrait=0&loop=1`"
        allow="autoplay; encrypted-media; fullscreen"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.contain {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.video-ratio {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>

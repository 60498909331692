<script>
const brands = { pe: 'core', full: 'pe', home: 'daily' };

export default {
  props: { slug: String, alt: String },

  computed: {
    slug$() {
      if (this.$store.getters['user/isHome'] && this.slug === 'home') return 'home';
      return brands[this.slug] || this.slug;
    },
    alt$() {
      return `${this.slug$} brand ${this.alt || ''}`.trim();
    },
  },
};
</script>

<template>
  <div class="relative brand" :class="slug">
    <img :src="`/brands/${slug$}.svg`" class="brand-img" :alt="alt$" draggable="false" />
  </div>
</template>

<style lang="postcss" scoped>
.brand {
  aspect-ratio: 500/380;
}

.brand-img {
  @apply max-w-none w-full h-full block;

  .dance > & {
    width: 106%; /*  530 / 500 */
    height: 119.5%; /* 454 / 380 */
    transform: translateY(-16%);
  }
  .legacy > & {
    width: 127%; /* 634 / 500 */
    height: 111%; /* 420 / 380 */
    transform: translateY(-9%);
  }
}
</style>

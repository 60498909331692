<script>
import routeInfo from '@/mixins/routeInfo';
import AudioPlayer from '@/content/components/partials/AudioPlayer';
import VimeoText from '@/content/components/partials/VimeoText';
import VideoButton from '@/content/components/buttons/VideoButton';
import { VueperSlides, VueperSlide } from 'vueperslides';

export default {
  props: ['lesson', 'part'],
  components: { VimeoText, VideoButton, AudioPlayer, VueperSlides, VueperSlide },
  mixins: [routeInfo],
  data: () => ({ seen: 0, current: 0, toggle: false }),

  methods: {
    navigate({ currentSlide }) {
      const index = currentSlide.index;

      this.current = index;
      this.seen = Math.max(index, this.seen);

      this.$emit('change', index);
    },
  },

  computed: {
    steps() {
      return this.part.steps?.map((step, i) => {
        const funs = step.funs[0] || {};
        const colour = `fun-${funs.funsLevel}`;

        return {
          ...step,
          index: i,
          image: step.image?.[0]?.url,
          funsLevel: funs.funsLevel,
          video: funs.video,
          textLines: step.textLines || funs.textLines || [],
          btnClass: this.skillOrFuns ? `bg-${colour}` : `bg-${this.productSlug}`,
          label: this.skillOrFuns ? funs.funsLevel : i + 1,
          colour,
          funs,
        };
      });
    },

    currentStep() {
      return this.steps[this.current];
    },
    skillOrFuns() {
      return this.part.type === 'skill' || this.part.type === 'funs';
    },
    showVideo() {
      return this.part.type === 'funs' ? !this.toggle : this.toggle;
    },
  },
};
</script>

<template>
  <div v-if="steps" class="play-steps" :class="part.type">
    <vueper-slides
      :slideRatio="0.5"
      :dragging-distance="70"
      :arrows="false"
      bullets-outside
      class="no-shadow"
      @slide="navigate"
    >
      <vueper-slide v-for="step in steps" :key="step.id" :image="step.image">
        <template v-if="showVideo && step.video" v-slot:content>
          <div :class="`bg-${step.colour}-shade`" class="h-full">
            <VimeoText :vid="step.video" :lines="step.textLines" :colour="step.colour" autoplay />
          </div>
        </template>
      </vueper-slide>

      <template v-slot:bullets="{ goToSlide, currentSlide: current }">
        <div class="flex justify-between w-full mt-3 mx-2">
          <div class="nav-flex-buttons"></div>

          <div class="flex items-start mt-3">
            <div v-for="(step, i) in steps" :key="i" class="bullets-item" @click="goToSlide(i)">
              <button :class="[{ active: current === i }, step.btnClass]" class="bullets-btn" @click="toggle = false">
                {{ step.label }}
              </button>

              <div class="relative h-20 mt-3" v-if="step.funs.id && part.type !== 'funs'">
                <VideoButton
                  v-if="seen >= i || part.type === 'skill'"
                  :colour="step.colour"
                  :class="{ active: toggle && current === i }"
                  class="absolute border-2 border-clear left-1/2 -translate-x-1/2"
                  @click="toggle = true"
                />
              </div>
            </div>
          </div>

          <div class="nav-flex-buttons">
            <AudioPlayer :audio="currentStep.audio" autoplay icon="voice" />
          </div>
        </div>
      </template>
    </vueper-slides>
  </div>
</template>

<style lang="postcss" scoped>
.bullets-item {
  @apply flex flex-col;
}

.bullets-btn {
  @apply font-bold text-white rounded-full leading-tight text-xl w-8 h-8 opacity-50 m-2 capitalize;
  @apply lg:w-10 lg:h-10;
  transition: transform 0.5s, background 0.5s;

  &:focus {
    @apply outline-none;
  }

  &.active {
    @apply opacity-100;
    transform: scale(1.4);
  }
  .story .bullets-item:nth-child(odd) & {
    @apply opacity-100;
  }

  .skill &,
  .funs & {
    @apply w-20 h-20 opacity-100 -mt-1;

    &.active {
      transform: scale(1.2);
    }
  }
}

.vueperslides__bullet,
.vueperslides__bullets button {
  z-index: auto;
}
</style>

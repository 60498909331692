<script>
import SupportBlocks from '@/admin/components/SupportBlocks';
import Vimeo from '@/content/components/partials/Vimeo';
import NavLink from '@/global/components/NavLink';
import Loader from '@/global/components/Loader';
import Brand from '@/global/components/Brand';
import '@/assets/svgs/buttons/file.svg';

export default {
  components: { Loader, Vimeo, Brand, NavLink, SupportBlocks },
  props: ['parent', 'page'],
};
</script>

<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="parent" :key="parent.slug" class="mb-4 bg-white curves-sm">
        <div class="flex items-center h-20 px-5">
          <span class="font-bold text-3xl lg:text-4xl" :class="`text-${parent.product}`">{{ parent.title }}</span>
          <div class="ml-auto">
            <img v-if="parent.product === 'bursts'" src="/brands/bursts.svg" alt="BURSTS" class="h-10" />
            <Brand v-else-if="parent.product" :slug="parent.product" class="h-12" />
          </div>
        </div>

        <div v-if="parent.subnav" class="p-2 border-t border-grey-l3">
          <NavLink
            v-for="child in parent.children"
            :key="child.id"
            :uri="child.uri"
            :colour="parent.colour"
            exact
            class="inline-block"
          >
            {{ child.title }}
          </NavLink>
        </div>
      </div>
    </transition>

    <Loader :res="page.id">
      <transition name="fade" mode="out-in">
        <div :key="page.id" class="curves-sm bg-white pb-1">
          <div v-if="parent && parent.subnav" class="pt-5 pb-1 px-5">
            <span class="font-bold text-3xl lg:text-2xl">
              {{ $route.params.slug2 ? page.displayTitle || page.title : 'General Support' }}
            </span>
          </div>

          <SupportBlocks :blocks="page.folder" :colour="parent.colour" />
        </div>
      </transition>
    </Loader>
  </div>
</template>

<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import { $bookmark } from '@/scripts/models';
import { mapState } from 'vuex';
import '@/assets/svgs/icons/bookmark.svg';
import '@/assets/svgs/icons/bookmark-fill.svg';
import '@/assets/svgs/icons/trash.svg';
import '@/assets/svgs/icons/star.svg';

export default {
  components: { AdminSpinner },
  props: ['btnClass'],
  data: () => ({ page: 0, bookmarks: [], marks$: $bookmark.all(), show: false }),

  computed: {
    allow() {
      return this.$route.params.product && !this.$route.params.slug;
    },
    current() {
      return this.lastVisited && this.lastVisited.match;
    },

    prev() {
      return this.marks$.link && this.marks$.link.prev;
    },
    next() {
      return this.marks$.link && this.marks$.link.next;
    },

    ...mapState('user', ['lastVisited']),
  },

  methods: {
    markButton({ target }) {
      this.show = true;
      this.$nextTick(() => target.blur());
    },
    outer(event) {
      if (event.target.id !== 'openMarks') this.show = false;
    },

    async fetch(page) {
      this.page = page;
      this.bookmarks = await this.marks$.$get({ page });
    },

    toggle() {
      this.current ? this.deleteMark(this.current) : this.createMark();
    },

    createMark() {
      const { id, match, ...mark } = this.lastVisited;
      this.$api.post(`bookmarks`, mark).then(() => {
        this.fetch(this.page);
        this.$store.dispatch('user/getLast');
      });
    },

    deleteMark(id) {
      this.$api.delete(`bookmarks/${id}`).then(() => {
        this.fetch(this.page);
        this.$store.dispatch('user/getLast');
      });
    },
  },

  created() {
    this.$store.dispatch('user/getLast');
    this.fetch();
  },
};
</script>

<template>
  <div class="relative">
    <button id="openMarks" :class="[{ active: show }, btnClass]" class="nm1" @click="markButton">
      <svg class="mark-flag" v-if="current && allow"><use xlink:href="#bookmark-fill" /></svg>
      <svg class="mark-flag" v-else><use xlink:href="#bookmark" /></svg>
      Bookmarks
    </button>

    <nav
      v-if="show"
      v-outer="outer"
      class="bookmarks-plinth bg-white rounded-bl-none shadow border rounded-xl p-3 border-blank-shade"
    >
      <div class="bg-grey-l3 p-3 rounded-lg rounded-bl-none mb-3">
        <div class="lastvisited" v-if="lastVisited.id">
          <div class="mark-heading">
            <svg class="mark-flag text-blank"><use xlink:href="#bookmark" /></svg>
            Last Visited
          </div>

          <div v-if="lastVisited.title" :key="lastVisited.id" class="flex">
            <RouterLink :to="lastVisited.url" class="mark-body outlne curves-sm shadow-sm">
              <svg :class="`fill-${lastVisited.product}`" class="mark-star">
                <use xlink:href="#star" />
              </svg>
              <div>
                <div class="mark-subtitle">{{ lastVisited.subtitle }}</div>
                <div>{{ lastVisited.title }}</div>
              </div>
            </RouterLink>
          </div>
        </div>
      </div>

      <div class="bg-grey-l3 rounded-lg rounded-bl-none p-3">
        <div class="mark-heading">
          <svg class="mark-flag text-blank"><use xlink:href="#bookmark-fill" /></svg>
          Bookmarks
        </div>

        <div class="mb-4 mark-body outlne curves-sm shadow-sm" v-if="allow" @click="toggle">
          <div class="w-full p-2 text-center">
            {{ current ? 'Unbookmark this page' : 'Bookmark this page' }}
          </div>
        </div>

        <AdminSpinner :res="marks$">
          <div :class="{ 'opacity-50 pointer-events-none': !marks$.$ready }" style="min-height: 320px">
            <div v-for="mark in bookmarks" :key="mark.id" class="flex mb-3">
              <RouterLink :to="mark.url" class="mark-body outlne curves-sm shadow-sm">
                <svg :class="`fill-${mark.product}`" class="mark-star"><use xlink:href="#star" /></svg>
                <div>
                  <div class="mark-subtitle">{{ mark.subtitle }}</div>
                  <div>{{ mark.title }}</div>
                </div>
              </RouterLink>

              <button class="p-4 -mr-4" @click="deleteMark(mark.id)">
                <svg class="w-4 h-5 fill-blank"><use xlink:href="#trash" /></svg>
              </button>
            </div>
            <div v-if="marks$.$fetched && !marks$.totalItems" class="empty">
              You don't currently have any bookmarks.
            </div>
          </div>
        </AdminSpinner>

        <div class="flex justify-between mt-3">
          <button @click="fetch(prev.page)" :disabled="!prev" class="mark-pagination outlne shadow-sm">Newer</button>
          <button @click="fetch(next.page)" :disabled="!next" class="mark-pagination outlne shadow-sm">Older</button>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="postcss">
.bookmarks-plinth {
  @apply absolute right-0 p-4 transition-all shadow overflow-auto;
  /* @apply absolute right-0 hidden p-4 transition-all shadow opacity-0 overflow-auto; */
  min-width: 400px;
  top: 100%;
  max-height: calc(100vh - 120px);
}

.mark-flag {
  @apply w-6 h-6 inline fill-current mr-1 pointer-events-none -my-1;
}
.mark-heading {
  @apply text-center text-2xl mb-3;
}

.mark-body {
  @apply bg-white flex items-center flex-grow p-2 px-3 text-lg leading-none;
}
.mark-star {
  @apply w-4 h-4 mr-3;
}
.mark-subtitle {
  @apply text-xs mb-1 text-blank;
}

.mark-pagination {
  @apply bg-white p-2 px-3 select-none rounded;

  &:disabled {
    @apply pointer-events-none opacity-25;
  }
}
</style>

<script>
import FixedHeader from '@/global/components/FixedHeader';
import PeMenu from '@/global/components/menu/PeMenu';
import Loader from '@/global/components/Loader';
import { mapState } from 'vuex';

export default {
  components: { FixedHeader, PeMenu, Loader },
  computed: mapState(['ready']),

  created() {
    if (!this.$store.state.loggedIn) this.$router.push('/login');
  },
};
</script>

<template>
  <Loader :res="ready" class="min-w">
    <div class="fixed inset-x-0 z-50">
      <FixedHeader />
      <PeMenu />
    </div>
    <div :class="`theme-${$route.meta.theme}`" class="inner">
      <RouterView />
    </div>
  </Loader>
</template>

<style lang="postcss" scoped>
.inner {
  padding-top: 60px;

  @media print {
    padding-top: 0;
  }
}
</style>

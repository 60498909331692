<script>
import AbilitySelectModal from '@/content/components/modal/AbilitySelectModal';
import OrganisationModal from '@/content/components/modal/OrganisationModal';
import AssessmentButton from '@/content/components/buttons/AssessmentButton';
import LessonPlanning from '@/content/components/lesson/LessonPlanning';
import PrintButton from '@/content/components/buttons/PrintButton';
import LessonPupil from '@/content/components/lesson/LessonPupil';
import PageTitle from '@/content/components/partials/PageTitle';
import DocButton from '@/content/components/buttons/DocButton';
import routeInfo from '@/mixins/routeInfo';
import { mapState, mapGetters } from 'vuex';

export default {
  props: ['lesson', 'ability', 'skill'],
  mixins: [routeInfo],
  // prettier-ignore
  components: {
    PageTitle, LessonPlanning, LessonPupil, AbilitySelectModal, OrganisationModal,
    AssessmentButton, PrintButton, DocButton
  },

  computed: {
    currentView() {
      return this.view ? 'LessonPlanning' : 'LessonPupil';
    },

    title() {
      return this.crumbsTitle.slice(this.isPlay ? 2 : 1).join(', ');
    },

    ...mapState(['view']),
    ...mapGetters('menu', ['crumbsTitle']),
  },
};
</script>

<template>
  <div>
    <div class="container">
      <PageTitle :title="title" toggle>
        <template v-if="view">
          <AssessmentButton mode="overview" />
          <DocButton :entry="lesson" />
          <PrintButton />
        </template>
        <OrganisationModal :entry="lesson" v-else />
        <AbilitySelectModal :lesson="lesson" />
      </PageTitle>
    </div>

    <component :is="currentView" :lesson="lesson" :ability="ability" :skill="skill" />
  </div>
</template>

<script>
import HaloButton from '@/content/components/buttons/HaloButton';
import Modal from '@/content/components/partials/Modal';
import '@/assets/svgs/buttons/home-challenge.svg';

export default {
  components: { Modal, HaloButton },
  props: ['lesson'],
  data: () => ({ modal: false }),
};
</script>

<template>
  <div>
    <Modal :open="modal" icon="home-challenge" title="Home Challenge" @close="modal = false">
      <div v-html="lesson.homeChallenge" class="rdct rdct-modal"></div>
    </Modal>

    <HaloButton icon="home-challenge" label="Home<br>Challenge" @click="modal = true" />
  </div>
</template>

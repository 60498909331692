<script>
import routeInfo from '@/mixins/routeInfo';
import ViewToggle from '@/content/components/buttons/ViewToggle';

export default {
  components: { ViewToggle },
  mixins: [routeInfo],
  props: { title: String, subtitle: String, toggle: Boolean },
};
</script>

<template>
  <div class="flex items-center p-3 pl-8 mb-2 bg-white title-box rounded-tl-3xl rounded-br-3xl print:p-0">
    <div class="leading-normal">
      <span class="mr-8 text-3xl font-bold lg:text-4xl" :class="`text-${productSlug}`">{{ title }}</span>
      <span class="text-2xl font-light lg:text-3xl subtitle text-blank">{{ subtitle }}</span>
    </div>

    <div class="flex justify-end flex-grow space-x-3 print:hidden">
      <slot></slot>
      <ViewToggle v-if="toggle" class="button" />
    </div>
  </div>
</template>

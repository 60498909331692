<script>
export default {
  props: ['items'],

  computed: {
    filtered() {
      return this.items
        .filter(item => item.value)
        .map(item => ({ ...item, ...(item.email && { mailto: item.mailto || `mailto:${item.email}` }) }));
    },
  },
};
</script>

<template>
  <div v-if="filtered.length">
    <div class="flex flex-wrap -m-3">
      <div v-for="item in filtered" :key="item.label" class="p-3">
        <label class="font-bold block">{{ item.label }}</label>
        <div>{{ item.value }}</div>
        <div class="text-sm italic">{{ item.value2 }}</div>
        <!-- <a v-if="item.url" :href="item.url">{{ item.linkText || item.url }}</a> -->
        <a v-if="item.email" :href="item.mailto" class="text-sm undr">{{ item.email }}</a>
      </div>
    </div>
    <hr />
  </div>
</template>

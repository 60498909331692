<script>
export default {
  props: { items: { default: () => [] }, value: { default: () => [] }, label: String, any: Boolean },

  computed: {
    filteredItems() {
      return (this.items || []).map(item => ({ ...item, checked: this.value.includes(item.value) }));
    },
    active() {
      return !!this.filteredItems.find(item => item.checked);
    },

    val: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<template>
  <div :class="{ active }" class="control2-ring">
    <label class="">{{ label }}</label>
    <div class="flex flex-wrap -mx-1 py-1 px-2">
      <label v-if="any" class="multicheck-item">Any</label>
      <label v-for="item in filteredItems" :key="item.id" :class="{ active: item.checked }" class="multicheck-item">
        <input type="checkbox" v-model="val" :value="item.value" />
        {{ item.label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: { slug: String, value: Object, asc: Boolean },

  computed: {
    active() {
      return this.slug && this.value?.by === this.slug;
    },
    sortClass() {
      // TW: atb-asc atb-dsc
      return this.active ? `atb-${this.value?.dir ? 'asc' : 'dsc'}` : '';
    },
  },

  methods: {
    sort() {
      this.$emit('input', { by: this.slug, dir: this.active ? !this.value.dir : this.asc });
    },
  },
};
</script>

<template>
  <button v-if="true" :class="sortClass" class="atb-head" @click="sort"><slot /></button>
  <div v-else class="atb-head"><slot /></div>
</template>

<script>
import AccountCard from '@/admin/components/accounts/AccountCard';
import Pagination from '@/admin/components/Pagination';
import AdminSpinner from '@/admin/components/AdminSpinner';
import { $account } from '@/scripts/models';

export default {
  components: { AccountCard, Pagination, AdminSpinner },
  props: { query: { default: () => ({}) } },
  data: () => ({ accounts$: $account.all(), accounts: [], sortBy: '', sortDir: false }),

  computed: {
    params() {
      return {
        ...this.query,
        ...(this.sortBy && { sort: `${this.sortDir ? '-' : ''}${this.sortBy}` }),
      };
    },
  },

  methods: {
    nav(link) {
      this.fetch(link.page);
    },

    async fetch(page) {
      this.accounts = await this.accounts$.$get({ page, ...this.params });
    },

    order(slug) {
      if (this.sortBy === slug) this.sortDir = !this.sortDir;
      this.sortBy = slug;
      this.fetch();
    },
  },

  watch: {
    query() {
      this.fetch();
    },
  },
};
</script>

<template>
  <AdminSpinner :res="accounts$" class="loader-lg">
    <div class="flex items-center justify-end mb-3 -mt-6 -mx-1">
      <button class="sort-btn outlne mx-1" :class="{ active: sortBy === 'name' }" @click="order('name')">
        Name
        <svg :class="{ flip: sortBy === 'name' && sortDir }"><use xlink:href="#triangle" /></svg>
      </button>
      <button class="sort-btn outlne mx-1" :class="{ active: sortBy === 'status' }" @click="order('status')">
        Status
        <svg :class="{ flip: sortBy === 'status' && sortDir }"><use xlink:href="#triangle" /></svg>
      </button>
    </div>

    <div class="flex flex-wrap -mx-3">
      <div v-for="account in accounts" :key="account.id" class="w-1/2 px-3 mb-5">
        <AccountCard :account="account" />
      </div>
    </div>

    <div v-if="accounts$.empty" class="empty">No Accounts Found.</div>

    <Pagination :collection="accounts$" @change="nav" />
  </AdminSpinner>
</template>

<style lang="postcss">
.sort-btn {
  @apply leading-none bg-blank rounded text-sm flex items-center text-white p-1 px-2;

  & svg {
    @apply fill-current w-2 h-2 ml-2;
  }
}
</style>

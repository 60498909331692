const activityTypes = [
  { slug: 'warmUp', label: 'Warm-Up' },
  { slug: 'skill', label: 'Skill' },
  { slug: 'application', label: 'Skill Application' },
  { slug: 'review', label: 'Review Method' },
];
const peActivityOverwrite = {
  personalBest: { 1: 'PB Challenge', 2: 'Skill' },
  personalBest2: { 2: 'PB Challenge' },
  repeatGame: { 1: 'Game', 2: 'Skill' },
  competition: { 1: 'Game', 2: 'Competition' },
};

const abilityStrings = {
  personal: 'Personal',
  cognitive: 'Cognitive',
  social: 'Social',
  fitness: 'Health and Fitness',
  creative: 'Creative',
  physical: 'Physical',
};

const status = { 0: 'Prospective', 10: 'Inactive', 30: 'Active' };

const trickyLevels = ['tricky', 'trickier', 'trickiest'];

const adventureStrings = [
  { id: 2, name: 'Pirate Ventures', fms: 'One leg balance', img: '/images/bursts/adventure-pirate.svg' },
  { id: 4, name: 'Clown Capers', fms: 'Ball skills', img: '/images/bursts/adventure-clown.svg' },
  { id: 3, name: 'Jungle Expeditions', fms: 'Seated balance', img: '/images/bursts/adventure-jungle.svg' },
  { id: 1, name: 'Space Missions', fms: 'Jumping and landing', img: '/images/bursts/adventure-space.svg' },
  { id: 5, name: 'Bike Quests', fms: 'Footwork', img: '/images/bursts/adventure-bike.svg' },
  { id: 6, name: 'Juggling Journeys', fms: 'Sending and receiving', img: '/images/bursts/adventure-juggling.svg' },
  { id: 7, name: 'Cat Escapades', fms: 'Floor work balance', img: '/images/bursts/adventure-cat.svg' },
];

const behaviourStrings = [
  { id: 1, slug: 'brave', name: 'Brilliantly brave', img: '/images/bursts/behaviour-brave.svg' },
  { id: 2, slug: 'helpful', name: 'Hugely helpful', img: '/images/bursts/behaviour-helpful.svg' },
  { id: 3, slug: 'imaginative', name: 'Incredibly imaginative', img: '/images/bursts/behaviour-imaginative.svg' },
  { id: 4, slug: 'wise', name: 'Wonderfully wise', img: '/images/bursts/behaviour-wise.svg' },
];

export { activityTypes, peActivityOverwrite, abilityStrings, status, trickyLevels, adventureStrings, behaviourStrings };

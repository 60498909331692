<script>
import ProductIntro from '@/admin/views/ProductIntro';
import { mapGetters } from 'vuex';

export default {
  components: { ProductIntro },
  metaInfo: { titleTemplate: t => (t ? `${t} | BURSTS` : 'BURSTS') },

  computed: {
    ...mapGetters('user', ['isAdmin', 'isCreate']),
    ...mapGetters('account', ['wondeBursts']),
    ...mapGetters(['hasBursts']),
  },

  methods: {
    checkUrl() {
      if (this.$route.path !== '/bursts') return;
      if (this.isCreate) this.$router.replace('/bursts/stats/year');
      else if (this.hasBursts && this.wondeBursts) this.$router.replace('/bursts/groups');
    },
  },

  watch: { $route: 'checkUrl' },

  mounted() {
    this.checkUrl();
  },
};
</script>

<template>
  <div>
    <template v-if="wondeBursts && hasBursts">
      <div class="container">
        <div class="flex justify-between items-center my-8">
          <img src="/brands/bursts.svg" alt="BURSTS" class="h-16 -my-3 -ml-1" />

          <div v-if="isAdmin" class="items-center justify-center px-2 text-center bg-white rounded flex">
            <RouterLink to="/bursts/groups" class="ma-link2">BURSTS Classes</RouterLink>
            <RouterLink to="/bursts/year" class="ma-link2">BURSTS Data</RouterLink>
            <RouterLink to="/support/bursts" class="ma-link2">Support</RouterLink>
          </div>
        </div>
      </div>

      <RouterView />
    </template>

    <ProductIntro v-else-if="hasBursts" slug="wonde-guide" />
    <ProductIntro v-else slug="intro-bursts" />
  </div>
</template>

<script>
import AdminSpinner from '@/admin/components/AdminSpinner';
import TrainingCard from '@/admin/components/TrainingCard';
import AdminModal from '@/admin/components/AdminModal';
import MetaTitle from '@/admin/components/MetaTitle';
import Vimeo from '@/global/components/Vimeo2';

import { $training } from '@/scripts/models';
import { mapState } from 'vuex';

const groups = [
  { title: 'My Learning', slug: 'learning' },
  { title: 'Scheduled', slug: 'scheduled' },
  { title: 'Pending', slug: 'pending' },
  { title: 'Completed', slug: 'completed' },
];

export default {
  components: { AdminSpinner, TrainingCard, AdminModal, Vimeo, MetaTitle },
  data: () => ({ training$: $training.all(), training: [], showVideo: false }),

  computed: {
    grouped() {
      return groups
        .map(({ title, slug }) => ({ title, slug, items: this.training.filter(i => i.group === slug) }))
        .filter(group => group.items.length);
    },

    ...mapState(['account']),
  },

  methods: {
    async fetch(page) {
      this.training = await this.training$.$get({ page, accountId: this.account.id });
    },
  },

  watch: {
    account() {
      this.fetch();
    },
  },

  mounted() {
    if (this.account.id) this.fetch();
  },
};
</script>

<template>
  <div class="container">
    <MetaTitle title="Training">
      <button class="btn4 bg-gymcard" @click="showVideo = true">
        Training Guide
        <svg class="btn4-icon"><use xlink:href="#video" /></svg>
      </button>
    </MetaTitle>

    <AdminSpinner :res="training$" class="loader-lg">
      <div v-for="group in grouped" :key="group.title" class="my-8">
        <div class="flex items-center mb-2">
          <h2 class="head-2xl">{{ group.title }}</h2>
          <span class="title-meta">{{ group.items.length }}</span>
          <!-- <button v-if="group.slug === 'learning'" class="btn4 bg-gymcard ml-auto" @click="showVideo = true">
            Training Guide
            <svg class="btn4-icon"><use xlink:href="#video" /></svg>
          </button> -->
        </div>

        <div class="lg:flex flex-wrap -mx-2">
          <div v-for="activity in group.items" :key="activity.id" class="px-2 my-2 lg:w-1/2">
            <TrainingCard v-bind="activity" :group="group.title" />
          </div>
        </div>
      </div>

      <div v-if="training$.empty" class="empty my-4">There is currently no training available</div>
    </AdminSpinner>

    <AdminModal :active="showVideo" title="Training Guide" wide @close="showVideo = false">
      <Vimeo vid="899829514?h=c07d5cbb52" class="-m-2 rounded-br-xl" />
    </AdminModal>
  </div>
</template>

import Index from '@/content/views/Index';
// import Year from '@/content/views/Year';
// import Wrapper from '@/content/views/Wrapper';

import Unit from '@/content/views/Unit';
import UnitOverview from '@/content/views/UnitOverview';
import UnitOrganiser from '@/content/views/UnitOrganiser';

import Lesson from '@/content/views/Lesson';
import LessonOverview from '@/content/views/LessonOverview';
import LessonActivity from '@/content/views/LessonActivity';

import HomeStage from '@/content/views/HomeStage';
import HomeItem from '@/content/views/HomeItem';
import HomeItemMenu from '@/content/views/HomeItemMenu';
import HomePart from '@/content/views/HomePart';
import Home from '@/content/views/Home';

import Leaders from '@/content/views/Leaders';

export default [
  {
    path: '/',
    // component: Wrapper,
    component: () => import(/* webpackChunkName: "pe-content" */ '@/content/views/Wrapper'),
    children: [
      { path: '/:product(dance)/', component: Index },
      {
        path: '/:product(pe|gym|dance)/year/:year([0-6])',
        // component: Year,
        component: () => import(/* webpackChunkName: "pe-content" */ '@/content/views/Year'),
      },
      {
        path: '/:product(pe|gym|dance)/year/:year([0-6])/unit/:unit([1-6])',
        component: Unit,
        children: [
          { path: '', component: UnitOverview },
          { path: 'organiser', component: UnitOrganiser },
        ],
      },
      {
        path: '/:product(pe|gym|dance)/year/:year([0-6])/unit/:unit([1-6])/lesson/:lesson([1-6])',
        component: Lesson,
        children: [
          { path: '', component: LessonOverview },
          { path: ':type/:part', component: LessonActivity },
        ],
      },

      // real play home / foundations eyfs
      { path: '/:product(home)', component: Home },
      { path: '/:product(home|foundations)/:stage(themes|funs)', component: HomeStage },
      {
        path: '/:product(home|foundations)/:stage(themes|funs)/:item',
        component: HomeItem,
        children: [
          { path: ':type(wider|game)?', component: HomeItemMenu },
          { path: ':type(wider|game|song|warmup|story|skill)/:part', component: HomePart },
        ],
      },

      // real play club
      {
        path: '/:product(play)/:stage(club)',
        component: () => import(/* webpackChunkName: "pe-content" */ '@/content/views/Year'),
      },
      {
        path: '/:product(play)/:stage(club)/unit/:unit([1-2])',
        component: Unit,
        children: [{ path: '', component: UnitOverview }],
      },
      {
        path: '/:product(play)/:stage(club)/unit/:unit([1-2])/lesson/:lesson([1-6])',
        component: Lesson,
        children: [
          { path: '', component: LessonOverview },
          { path: ':type/:part', component: LessonActivity },
        ],
      },
    ],
  },

  { path: '/:product(leaders)', component: Leaders },
];

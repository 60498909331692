<script>
import '@/assets/svgs/cog.svg';
import '@/assets/svgs/play-island.svg';

export default {
  props: { ability: Object, size: String, shade: Boolean, play: String },
};
</script>

<template>
  <div :class="`size-${size}`" class="relative flex items-center justify-center text-white cog z-10">
    <svg
      :class="[`fill-${ability.slug}${shade ? '-shade' : ''}`]"
      class="absolute inset-0 w-full h-full print:text-white print:rounded-full"
    >
      <use :xlink:href="play ? '#play-island' : '#cog'" />
    </svg>
    <div
      v-html="play ? `${play} Island` : ability.title || 'Select'"
      :class="[`text-${shade ? ability.slug : 'white'}`, { play }]"
      class="relative font-semibold tracking-tighter text-center select-none print:text-black"
    ></div>
  </div>
</template>

<style lang="postcss" scoped>
.cog {
  font-size: 11px;
  line-height: 0.85;
  padding: 1em;
  height: 6em;
  width: 6em;

  @screen lg {
    font-size: 15px;
  }

  & .play {
    line-height: 1.1;
  }
}

.size-100 {
  @screen lg {
    font-size: 17px;
  }
}
.size-115 {
  font-size: 15px;

  @screen lg {
    font-size: 20px;
  }
}
.size-160 {
  font-size: 28px;
}
.size-200 {
  font-size: 28px;

  @screen lg {
    font-size: 35px;
  }
}
</style>

<script>
export default {
  data: () => ({ create: process.env.VUE_APP_CREATE }),
  computed: {
    year() {
      return this.$dayjs().format('YYYY');
    },

    env() {
      // if (location.hostname === 'localhost') return 'development';
      if (location.hostname === 'staging.app.realpe.co.uk') return 'staging';
    },
  },
};
</script>

<template>
  <div class="print:hidden pt-20 relative">
    <template v-if="$route.path === '/'">
      <div class="absolute bottom-0 inset-x-0 h-36" style="background: #76bc21"></div>
      <img src="/images/lawn_kids.svg" class="w-5/6 max-w-2xl lg:max-w-3xl mx-auto -my-10 lg:-my-16 relative z-10" />
    </template>

    <div class="container footer flex justify-between items-end pb-5 text-sm relative">
      <div class="text-black opacity-75">&copy;{{ year }} Create Development Limited - All Rights Reserved</div>
      <a class="z-0 brand ml-2 w-36" :href="create" target="_blank">
        <img src="/brands/create.svg" class="-mt-3" />
      </a>
    </div>

    <div v-if="env" class="fixed bottom-0 left-0 p-2 flex bg-white border-2 border-fun-red rounded m-2 items-center">
      <svg class="w-6 h-6 fill-fun-red mr-2"><use xlink:href="#warn" /></svg>
      <div class="text-fun-red font-bold leading-none capitalize">{{ env }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdminSpinner from '@/admin/components/AdminSpinner';

export default {
  components: { AdminSpinner },

  data: () => ({
    accounts: '000',
    accountsActive: '00',
    groups: '000',
    parents: '000',
    students: '0000',
    studentsActive: '000',
    minutes: '0000',
    minutesPrev: 0,
    ready: false,
  }),

  computed: {
    ...mapGetters('user', ['isCreate']),
    ...mapGetters('account', ['hasBursts']),

    activeAccounts() {
      return `${Math.floor((this.accountsActive / this.accounts) * 100)}%`;
    },
    activeStudents() {
      return `${Math.floor((this.studentsActive / this.students) * 100)}%`;
    },
    almChange() {
      return this.minutes - this.minutesPrev;
    },
  },

  mounted() {
    if (!this.isCreate) this.$router.replace('/bursts');

    this.$api.get('/bursts/headline').then(res => {
      this.ready = true;
      Object.keys(res.data).forEach(key => {
        this[key] = res.data[key];
      });
    });
  },
};
</script>

<template>
  <div>
    <div class="container pt-8 mb-8">
      <div class="flex justify-between">
        <div class="mr-4">
          <img src="/brands/bursts.svg" alt="BURSTS" class="h-16 -ml-2" />
        </div>
        <div class="items-center justify-center px-2 mb-4 text-center bg-white rounded flex">
          <RouterLink to="/bursts/stats/year" class="ma-link2">BURSTS Data</RouterLink>
          <RouterLink to="/bursts/stats/accounts" class="ma-link2 exact">Accounts</RouterLink>
        </div>
      </div>

      <AdminSpinner :ready="ready">
        <div class="flex -mx-3">
          <div class="p-3">
            <div class="lbl4">Accounts</div>
            <div>
              <strong></strong>
            </div>
            <div class="text-2xl">
              <strong class="text-bursts">{{ accountsActive }}</strong>
              <span class="text-grey ml-1">/ {{ accounts }}</span>
            </div>
            <div class="text-sm">
              <span class="text-grex">{{ activeAccounts }}</span>
              Active
            </div>

            <div class="flex overflow-hidden rounded h-2 border mt-1 bg-bursts-shade">
              <div class="bar2 px-1 bg-bursts" :style="{ width: activeAccounts }"></div>
              <div></div>
            </div>
          </div>

          <div class="p-3">
            <div class="lbl4">Children</div>

            <div class="text-2xl">
              <strong class="text-bursts">{{ studentsActive }}</strong>
              <span class="text-grey ml-1">/ {{ students }}</span>
            </div>
            <div class="text-sm">
              <span class="text-greyx">{{ activeStudents }}</span>
              Active
            </div>
            <div class="flex overflow-hidden rounded h-2 border mt-1 bg-bursts-shade">
              <div class="bar2 px-1 bg-bursts" :style="{ width: activeStudents }"></div>
              <div></div>
            </div>
          </div>

          <div class="p-3">
            <div class="lbl4">Active Mins</div>
            <div class="flex">
              <img src="/images/bursts/minutes-icon.svg" alt="Active Minutes" class="w-5 mr-2 inline-block" />
              <strong class="text-2xl text-bursts">{{ minutes }}</strong>
            </div>
            <div>
              <div class="text-sm text-fun-green font-bold text-right">+ {{ almChange }} mins</div>
            </div>
          </div>

          <div class="p-3">
            <div class="lbl4">Groups</div>
            <strong class="text-2xl text-bursts">{{ groups }}</strong>
          </div>

          <!-- <div class="p-3">
            <div class="lbl4">Parents</div>
            <strong class="text-2xl text-bursts">{{ parents }}</strong>
          </div> -->
        </div>
      </AdminSpinner>
    </div>

    <RouterView />
  </div>
</template>

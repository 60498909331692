<script>
export default {
  props: { step: Object, index: Number, current: Number, spine: Boolean },

  computed: {
    side() {
      if (this.index === this.current) return 'current';
      return this.index < this.current ? 'left' : 'right';
    },
    hidden() {
      return this.index < this.current - 1 || this.index > this.current + 1 ? 'hide' : '';
    },
  },
};
</script>

<template>
  <div class="absolute inset-0 step curves" :class="[{ spine }, side, hidden]" @click="$emit('setCurrent', index)">
    <div class="absolute inset-0 flex flex-col items-center justify-center p-10 mini">
      <div class="leading-none mini-num">{{ step.end ? '?' : index + 1 }}</div>
      <div class="hidden text-6xl leading-none capitalize lg:block">{{ step.subtitle }}</div>
    </div>

    <div class="absolute inset-0 flex flex-col items-center justify-center p-10 opacity-0 big">
      <div class="absolute top-0 left-0 p-2 px-3 text-5xl leading-none">{{ !step.end ? index + 1 : '' }}</div>
      <div class="mb-4 text-3xl leading-none capitalize lg:text-5xl">{{ step.end ? '?' : step.subtitle }}</div>
      <div v-html="step.text" class="text-xl lg:text-3xl"></div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.mini-num {
  font-size: 136px;
}
.mini,
.big {
  transition: opacity 0.3s ease-in-out;
}

.spine {
  @apply relative z-0 pointer-events-none select-none;
  visibility: hidden;

  & .big {
    @apply relative;
  }
}

.step {
  @apply bg-grey-l1 text-center text-white select-none;
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
  mix-blend-mode: multiply;
  z-index: 1;

  &.current {
    @apply bg-pe;

    & .mini {
      opacity: 0;
    }
    & .big {
      opacity: 1;
    }
  }
  &.left {
    transform: scale(0.5) translateX(-150%);
  }
  &.right {
    transform: scale(0.5) translateX(150%);
  }

  &.hide {
    @apply pointer-events-none opacity-0 z-0;
    visibility: hidden;
  }
}

.dance .current.step {
  @apply bg-dance;
}
</style>

import Vue from 'vue';
import Router from 'vue-router';
import adminRoutes from './adminRoutes';
import contentRoutes from './contentRoutes';

import LoginPage from '@/global/views/LoginPage';
import Inner from '@/global/views/Inner';
import DiscourseConnect from '@/global/views/DiscourseConnect';
import OAuthConnect from '@/global/views/OAuthConnect';
import BurstsTools from '@/global/views/BurstsTools';

import LoginForm from '@/global/components/auth/LoginForm';
import RequestForm from '@/global/components/auth/RequestForm';
import Register from '@/global/components/Register';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    { path: '*', redirect: '/' },
    {
      path: '/login',
      component: LoginPage,
      children: [
        { path: '', component: LoginForm },
        { path: 'reset', component: RequestForm, meta: { auth: false } },
        { path: 'invite/:invite', component: Register, meta: { auth: false, mode: 'register' } },
        { path: 'reset/:reset', component: Register, meta: { auth: false, mode: 'reset' } },
      ],
    },
    {
      path: '/',
      component: Inner,
      children: [
        ...adminRoutes,
        ...contentRoutes,
        { path: '/sso', component: DiscourseConnect },
        { path: '/oauth', component: OAuthConnect },
      ],
    },
    { path: '/bursts-tools', component: BurstsTools, meta: { auth: false } },
  ],

  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return { selector: to.hash, behavior: 'smooth' };
    if (to.path === from.path) return false;
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

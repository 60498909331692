<script>
import AbilitySelectCogs from '@/content/components/partials/AbilitySelectCogs';
import HaloButton from '@/content/components/buttons/HaloButton';
import TextBlock from '@/content/components/partials/TextBlock';
import Cog from '@/content/components/partials/Cog';
import Link from '@/global/components/Link';
import routeInfo from '@/mixins/routeInfo';
import '@/assets/svgs/buttons/ability-select.svg';

export default {
  components: { Cog, AbilitySelectCogs, TextBlock, Link, HaloButton },
  mixins: [routeInfo],
  props: ['unit', 'ability'],
  data: () => ({ showSelect: false }),

  computed: {
    hasSelect() {
      return this.productInfo.abilitySelect;
    },
    showSelect$() {
      return this.hasSelect && (this.ability.slug === 'blank' || this.showSelect);
    },
    colour() {
      return this.showSelect$ ? 'blank' : this.ability.slug;
    },
    uri() {
      return this.showSelect$ ? '' : this.unit.uri;
    },

    levels() {
      const abilityWhat = this.ability.abilityWhat || [];
      if (this.isPlay) return [{ whats: this.unit.abilityWhat, label: this.unit.strapline }];

      return ['Emerging', 'Expected', 'Exceeding']
        .map(label => {
          const slug = label.toLowerCase();
          return { slug, label, whats: abilityWhat.filter(g => g.level === slug) };
        })
        .filter(level => level.whats.length);
    },
  },
};
</script>

<template>
  <div class="pb-2 t-col relative">
    <template v-if="hasSelect && !showSelect$">
      <HaloButton
        colour="blank"
        icon="ability-select"
        label="Ability Select"
        class="absolute top-0 right-0 m-3"
        @click="showSelect = true"
      />
    </template>
    <Link
      :uri="uri"
      :class="`bg-${colour}  border-${colour || 'clear'}`"
      class="txt-block2 text-white flex flex-col justify-center print:block"
    >
      <!-- style="min-height: 330px" -->
      <AbilitySelectCogs v-if="showSelect$" :unit="unit" mode="year" @select="showSelect = false" />
      <template v-else>
        <Cog :ability="ability" size="115" :play="unit.island" class="mx-auto mb-5" />

        <h2 class="text-center text-2xl font-bold mb-2">{{ ability.cogLabel || unit.cogLabel }}</h2>
        <div v-html="ability.cogText || unit.cogText" class="text-lg px-4 text-center"></div>

        <div v-for="level in levels" :key="level.slug" class="rdct rdct-teacher">
          <div class="mt-3 mb-2 font-extrabold">{{ level.label }}</div>
          <ul>
            <li v-for="row in level.whats" v-html="row.what"></li>
          </ul>
        </div>
      </template>
    </Link>
  </div>
</template>
